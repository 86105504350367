import  { useEffect, useState } from 'react';
import {useDispatch} from 'react-redux'
import { getLanguages} from '../../../features/video_team/languagesfeat/languageSlice';
import { toast } from 'react-toastify';



function SelectLanguages({onHandleLanguage, submitting, selectText, language_codes, us, translation_type}) {

    const [data, setData] = useState();
    const [selectedOption, setSelectedOption] = useState();
    const [usLanguage, setUsLanguage] = useState(false);

    // if us === true add us lnaguage as an option
    useEffect(() => {
      if(us && us === true){
        setUsLanguage(true)
      }
    }, [us])

    const dispatch = useDispatch();

    // get all languages once component is rendered
    useEffect(() => {
        dispatch(getLanguages())
        .unwrap()
           .then((language) => {
            console.log(language)
             setData(language.data)
           })
           .catch(toast.error)
       }, [dispatch]);


    // get users selected language
    const handleChange = (e) => {
      e.preventDefault();
      setSelectedOption(e.target.value);
      onHandleLanguage(e.target.value, e.target.options[e.target.selectedIndex].dataset.code);
    };

       if(!data){
        return null
       } else {
        return (
            <>
             <select onChange={submitting ? null : handleChange} value={selectedOption} placeholder={selectText} required>
                <option value="" disabled selected>{selectText}</option>
                {translation_type==='single' && (<option value="-1">All Languages 🌎</option>)}
                {data.map(option => (
                  <option key={option.id} value={option.id} data-code={option.code} >
                    {option.title} {(language_codes && language_codes.includes(option.code)) && (<> ↺</>)}
                  </option>
                ))}
                {usLanguage && (
                  <option value={611} data-code={'en-US'}>
                    US English
                  </option>
                )}
            </select>

            </>  
         )
       }
}

export default SelectLanguages