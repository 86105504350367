import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import trailerService from './trailerService'



const initialState = {
    content: '',
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}


// get all data

export const getScript = createAsyncThunk('script/get', async (id, thunkAPI) => {
  try{
    const token = thunkAPI.getState().auth.user.token;
      return await trailerService.getScript(id, token)
  } catch(error){
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message)
  }
})


// download all clips

export const downloadClips = createAsyncThunk('script/download/get', async (data, thunkAPI) => {
  try{
    const token = thunkAPI.getState().auth.user.token;
      return await trailerService.downloadClips(data, token)
  } catch(error){
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message)
  }
})
// get all data

export const deleteScript = createAsyncThunk('script/delete', async (id, thunkAPI) => {
  try{
    const token = thunkAPI.getState().auth.user.token;
      return await trailerService.deleteScript(id, token)
  } catch(error){
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message)
  }
})

// create new script

export const createScript = createAsyncThunk('script/create', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await trailerService.createScript(data, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


// update script

export const updateScript = createAsyncThunk('script/update', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await trailerService.updateScript(data, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});





export const trailerSlice = createSlice({
    name: 'trailer',
    initialState,
    reducers: {
      reset: (state) =>{
        state.isLoading = false
        state.isError = false
        state.message = ''
        state.isSuccess = false
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getScript.pending, (state) => {
          state.isLoading = false
        })
        .addCase(getScript.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.content = action.payload
        })
        .addCase(getScript.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.content = null
        })  
        .addCase(createScript.pending, (state) => {
          state.isLoading = false
        })
        .addCase(createScript.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.content = action.payload
        })
        .addCase(createScript.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.content = null
        })
        .addCase(updateScript.pending, (state) => {
          state.isLoading = false
        })
        .addCase(updateScript.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.content = action.payload
        })
        .addCase(updateScript.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.content = null
        })
        .addCase(deleteScript.pending, (state) => {
          state.isLoading = false
        })
        .addCase(deleteScript.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.content = null
        })
        .addCase(deleteScript.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
    },
  })
  

  export const {reset} = trailerSlice.actions
  export default trailerSlice.reducer