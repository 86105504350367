import {useDispatch} from 'react-redux';
import { displayFile } from "../features/video_team/files/fileSlice";
import { useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';

// the objective of this hook is to open the selected srt file
// note that originalFileName parameter is optional and will only be used to display the original srtfile and its translation

const useOpenResume = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openResume = (filename, originalFileName = null, id = null) => {
    if (filename === null) {
      alert('No files yet');
    } else {
      dispatch(displayFile(filename))
        .unwrap()
        .then(() => {
          navigate('/marketing/resume-editor', { state: { filename, originalFileName, id } });
        })
        .catch(toast.error);
    }
  };

  return { openResume };
};

export default useOpenResume;
