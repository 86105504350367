import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Navigate,useNavigate} from 'react-router-dom'
import { toast } from 'react-toastify';
import { getSingleVideoCopy, uploadManyVideoFiles, reset} from "../../../features/video_team/videosUpload/videosSlice";
import CustomToast from "../../../components/shared/CustomToast";
import Downdloader from '../../shared/Loaders/Downloader'







function VideoFileUploader({videoId, language_code, prompt, output, transcriber}) {

    const [files, setFiles] = useState([]);
    const [id, setId] = useState(null);
    const [fileError, setFileError] = useState(false);
    const { isLoading, isError, message } = useSelector((state) => state.videos);
    const dispatch = useDispatch();
    const [loadingMessage, setLoadingMessage] = useState('Inicializando...'); // Inicializa el estado


    useEffect(() => {
            setId(videoId);  
    },[videoId])
     
    const handleFileChange = (event) => {
        const newFiles = event.target.files;
        let allFilesValid = true;
        setFiles((prevFiles) => {
            const updatedFiles = prevFiles ? [...prevFiles] : [];
            for (let i = 0; i < newFiles.length; i++) {
                if(transcriber === 'google'){
                    if(newFiles[i].type !== "audio/wav" && newFiles[i].type !== "audio/flac"){
                        allFilesValid = false;
                    }
                   
                } else if (transcriber === 'whisper') {
                    // if ((newFiles[i].type !== 'audio/wav' && newFiles[i].type !== 'audio/mpeg') || newFiles[i].size > 25000000){
                    //     allFilesValid = false;
                    // }

                }

                console.log(allFilesValid)
                updatedFiles.push(newFiles[i]);
            }
            setFileError(!allFilesValid);
            return updatedFiles;
        });
    };
      

    const handleRemoveFile = (indexToRemove) => {
        setFileError(false)
        setFiles(prevFiles => {
            const newFiles = [...prevFiles];
            newFiles.splice(indexToRemove, 1);
            return newFiles;
        });
    };
    

    const handleUpload = ()=> {
        let allFilesValid = true;
        let confirm = true;

        if(transcriber === 'whisper'){
            confirm = false;
            confirm = window.confirm('Please make sure the prompt is in the same language than the audio files');
        }

        if(confirm){
            const chapterArray = [];
            for (let i = 0; i < files.length; i++) {
                
              const chapter = {
                title: files[i].name.replace(/['"]/g, ""),
                video_file: new File([files[i]], files[i].name.replace(/['"]/g, "")),
              };
              chapterArray.push(chapter);
            }

            if(!allFilesValid){
                setFileError(!allFilesValid);
                return false
            } else {

                const data = {
                    prompt: prompt,
                    transcriber: transcriber,
                    id_video: id,
                    output: output,
                    chaptersArray: chapterArray,
                    language_code: language_code,
                    is_blog: 0,
                  };
                  dispatch(uploadManyVideoFiles(data))
                      .unwrap()
                      .then((response) => {
                        //   dispatch(getSingleVideoCopy(id))
                          dispatch(reset())
                          // Show the custom toast with the link
                          toast.success(
                              <CustomToast
                                  message={response.message}
                                  linkTo={`/marketing/social-media/copy/${id}`}
                              />,
                              { autoClose: 7000 }
                          )
                        
                          window.location.href =(`/marketing/social-media/copy/${id}`)

        
                      })
                      .catch((error) => {
                          toast.error(error)
                          dispatch(reset())
                      })
                      .then(() => {
                          dispatch(reset());
                      }); 
            }  
            
            setFileError(false)

        }
    };


    if(isLoading){
        return (
            <Downdloader text={loadingMessage} />
        )
    }

    
    return (
        <div className='uploader-container'>
                {transcriber === 'google' ? (
                    <span style={{color: fileError ? 'red' : 'black', marginTop: '10px'}}>Files must be flac or wav</span>
                ) : transcriber === 'whisper'? (
                    <span style={{color: fileError ? 'red' : 'black', marginTop: '10px'}}></span>
                ): null}
                <input type="file" accept="video/*" onChange={handleFileChange} />
            <div>
            {files &&
                Array.from(files).map((file, index) => (
                <div className='file-uploader-actions' key={index}>
                    <p>{file.name}</p>
                    <button className='btn' onClick={() => handleRemoveFile(index)}>Remove</button>
                </div>
                ))}
            </div>
            <button className='btn' onClick={handleUpload} disabled={fileError}>SAVE Video</button>
            {fileError && (
                <span style={{color: 'red', marginTop: '10px'}}>Some files are not wav/flac. Please check again</span>
            )}
            {isLoading && <div>Uploading...</div>}
            {isError && <div>Error: {message}</div>}
        </div>
    );
}

export default VideoFileUploader