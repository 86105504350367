import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import audioService from './audioService'

const initialState = {
    audioUpload: '',
    audioPlayerTime: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    loadMessage: '',
    message: '',
}

// fileslogic
export const uploadManyAudioFiles = createAsyncThunk('videos/uploadManyAudioFiles', async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await audioService.uploadManyAudioFiles(data, token);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });
  
  export const getChaptersFromPlaylistDacast = createAsyncThunk('videos/getChaptersFromPlaylistDacast', async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await audioService.getChaptersFromPlaylistDacast(data, token);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });

  export const uploadDacastVideos = createAsyncThunk('videos/uploadDacastVideos', async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await audioService.uploadDacastVideos(data, token);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });
  


// transcribeAudios
export const transcribeAudios = createAsyncThunk('videos/transcribeAudios', async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await audioService.transcribeAudios(data, token);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });
  
  
  
  
  
  export const audioSlice = createSlice({
    name: 'audioSlice',
    initialState,
    reducers: {
      setAudioTime: (state, action) =>{
        state.audioPlayerTime = action.payload;
      },
      setLoadMessage: (state, action) =>{
        state.loadMessage = action.payload;
      },
      reset: (state) => {
        state.isLoading = false;
        state.isError = false;
        state.message = '';
        state.isSuccess = false;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(uploadManyAudioFiles.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(uploadManyAudioFiles.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.audioUpload = action.payload;
        })
        .addCase(uploadManyAudioFiles.rejected, (state, action) => {
          state.isLoading = false;
          state.isSuccess = false;
          state.isError = true;
          state.message = action.payload;
        })
        .addCase(uploadDacastVideos.rejected, (state, action) => {
          state.isLoading = false;
          state.isSuccess = false;
          state.isError = true;
          state.message = action.payload;
        })
        .addCase(uploadDacastVideos.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getChaptersFromPlaylistDacast.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.audioUpload = action.payload;
        })
        
        .addCase(getChaptersFromPlaylistDacast.rejected, (state, action) => {
          state.isLoading = false;
          state.isSuccess = false;
          state.isError = true;
          state.message = action.payload;
        
      })
        .addCase(getChaptersFromPlaylistDacast.pending, (state) => {
        
        state.isLoading = true;
        })
        .addCase(uploadDacastVideos.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.audioUpload = action.payload;
        })

        



        .addCase(transcribeAudios.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(transcribeAudios.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
        })
        .addCase(transcribeAudios.rejected, (state, action) => {
          state.isLoading = false;
          state.isSuccess = false;
          state.isError = true;
          state.message = action.payload;
        })
    },
  });
  
  export const {reset, setAudioTime, setLoadMessage} = audioSlice.actions
  export default audioSlice.reducer;
  


