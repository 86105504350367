
import  { useEffect, useState, useRef } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import { toast } from 'react-toastify';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import {generateLandingContent} from '../../../features/marketing_team/resumesSlice'
import Downloader from "../../../components/shared/Loaders/Downloader";

function GenerateLandingContentButton({resumes_available}) {
    const [displayPopUp, setDisplayPopUp] = useState(false);
    const [viewLanding, setViewLanding] = useState(false);
    const [keywords, setKeywords] = useState('');
    const { video } = useSelector((state) => state.videos);
    const [isLoading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Generating landing...'); // Inicializa el estado

    const navigate = useNavigate();


    const dispatch = useDispatch();


    const onChange = (e) => {
        setKeywords(e.target.value)
    }

    useEffect(() => {
        setKeywords(video.keywords?video.keywords:'');
    },video);
    

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {          
            keywords,
            id_video: video.id,
        }

        toast.info('Generating landing')
          setDisplayPopUp(false)
          dispatch(generateLandingContent(data))
          .unwrap()
              .then((response) => {
                  toast.success('Landing Generated')
                  setViewLanding(true)
                  navigate(`/marketing/masterclass/landing/${video.id}`)
                  setLoading(false)
              })
              .catch((error) => {
                  console.log(error)
                  toast.error(error)
                  setLoading(false)
              })       
    }

    if (isLoading){
        return (
            <Downloader text={loadingMessage} />
          )
      }

    return (
        <div className="create-resume-button-container">
            {video.is_content_generated === 0 || viewLanding ? 
                <button style={{marginTop: '20px', width: '100%'}} className="btn btn-reverse" onClick={() => setDisplayPopUp(true)}>GENERATE LANDING</button> 
                    : 
                <Link style={{marginTop: '20px', width: '100%'}} className="btn btn-reverse" to={`/marketing/masterclass/landing/${video.id}`}>VIEW LANDING</Link>
            }
            
           
            {displayPopUp && (
              <>
              {resumes_available ? (
                 <form className="resume-all-form" onSubmit={onSubmit}>
                        <AiOutlineCloseCircle onClick={() => setDisplayPopUp(false)} size={23} className='close-form'/>
                        <div className='form-group'>
                
                        <div className='form-group'>
                        <input
                            type='text'
                            className='form-control'
                            id='keywords'
                            name='keywords'
                            value={keywords}
                            onChange={onChange}
                            placeholder='Add Keywords'
                            required
                        />
                        </div>
                        </div>                           
                        <div className='form-group center-element'>
                        <button  className='btn btn-block center-element'>Create</button>
                        </div>
                </form>
              ) : (
                <div className="resume-all-form">
                    <AiOutlineCloseCircle onClick={() => setDisplayPopUp(false)} size={23} className='close-form'/>
                    <h2>Please be aware that in order to create landing content you need to generate all resumes first</h2>
                </div>
              )}
              
             
        </>
            )}
        </div>
    )
}

export default GenerateLandingContentButton