import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { uploadManyVideoFiles, reset} from "../../../features/video_team/videosUpload/videosSlice";
import { getSignedUrl} from "../../../features/video_team/files/fileSlice";
import { getSingleVideo, setChapters} from "../../../features/video_team/videosUpload/videosSlice";

import CustomToast from "../../shared/CustomToast";
import Downdloader from '../../shared/Loaders/Downloader'







function GlobalVideoFileUploader({videoId, language_code, prompt, output, transcriber}) {

    const [files, setFiles] = useState([]);
    const [id, setId] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [fileError, setFileError] = useState(false);
    const {isError, message } = useSelector((state) => state.videos);
    const dispatch = useDispatch();
    const [loadingMessage, setLoadingMessage] = useState('Generating summary from video...'); // Inicializa el estado


    useEffect(() => {
        setId(videoId);  
    },[videoId])
     
    const handleFileChange = (event) => {
        const newFiles = event.target.files;
        let allFilesValid = true;
        setFiles((prevFiles) => {
            // const updatedFiles = prevFiles ? [...prevFiles] : [];
            const updatedFiles = [];
            
            for (let i = 0; i < newFiles.length; i++) {
                // if (newFiles[i].size > 25000000){
                //     allFilesValid = false;
                // }
                console.log(allFilesValid)
                updatedFiles.push(newFiles[i]);
            }
            setFileError(!allFilesValid);
            return updatedFiles;
        });
    };
      

    const handleRemoveFile = (indexToRemove) => {
        setFileError(false)
        setFiles(prevFiles => {
            const newFiles = [...prevFiles];
            newFiles.splice(indexToRemove, 1);
            return newFiles;
        });
    };
    

    const handleUpload = async()=> {
        let allFilesValid = true;
        let confirm = true;

        // if(transcriber === 'whisper'){
        //     confirm = false;
        //     confirm = window.confirm('Please make sure the prompt is in the same language than the audio files');
        // }

        if(confirm){
            const chapterArray = [];
            for (let i = 0; i < files.length; i++) {
                
              const chapter = {
                title: files[i].name.replace(/['"]/g, ""),
                video_file: new File([files[i]], files[i].name.replace(/['"]/g, "")),
              };
              chapterArray.push(chapter);
            }

            if(!allFilesValid){
                setFileError(!allFilesValid);
                return false
            } else {
                const file = files[0];
                setLoading(true);
                await sendBrowserGCS(file,chapterArray);
                
                
            }  
            
            setFileError(false)

        }
    };

    const sendBrowserGCS = async(file, chapterArray)=>{

        const filename = file.name.replace(/['"]/g, "").replace (/^/,id+'_');
        const data_sign= {
            filename : filename,
            filetype : file.type,
            folder_name : 'videos',
        }

        dispatch(getSignedUrl(data_sign))
        .unwrap()
        .then((response) => {
            console.log(response)
            if(!response.success){alert('error')};

            const xhr = new XMLHttpRequest();
            xhr.open("PUT", response.url, true);
            xhr.onload = () => {
            const status = xhr.status;
            console.log(xhr);
            if (status === 200) {
                // alert("File is uploaded");
                setLoadingMessage(`File uploaded`);

                const data = {
                    data_sign: data_sign,
                    prompt: prompt,
                    transcriber: transcriber,
                    id_video: id,
                    output: output,
                    chaptersArray: chapterArray,
                    language_code: language_code,
                    is_global: true,
                  };
                  setLoadingMessage(`Trascribing ...`);
                  dispatch(uploadManyVideoFiles(data))
                      .unwrap()
                      .then((response) => {
                        //   dispatch(getSingleVideoCopy(id))
                         setLoading(false);
                         dispatch(getSingleVideo(id))
                         dispatch(reset())
                         // Show the custom toast with the link
                         toast.success(
                             <CustomToast
                                 message="Your files are ready. Click to view!"
                                 linkTo={`/videos/video/${id}`}
                             />,
                             { autoClose: 7000 }
                         )
                         window.location.href =(`/videos/video/${id}`)

        
                      })
                      .catch((error) => {
                          toast.error(error)
                          dispatch(reset())
                          setLoading(false);
                      })
                      .then(() => {
                          dispatch(reset());
                          setLoading(false);
                      }); 
            } else {
                alert(`Error status: ${status}`);
                setLoading(false);
            }
            };

            xhr.upload.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    const percentage = Math.round((event.loaded * 100) / event.total);
                    console.log(`Subida: ${percentage}%`);
                    setLoadingMessage(`Uploading file... ${percentage}%`);
                }
            });

            xhr.onerror = (error) => {
                alert(error);
                setLoading(false);
            };
            xhr.setRequestHeader('Content-Type', file.type);
            xhr.send(file)
        });
    }


    if(isLoading){
        return (
            <Downdloader text={loadingMessage} />
        )
    }

    
    return (
        <div className='uploader-container'>
                {transcriber === 'google' ? (
                    <span style={{color: fileError ? 'red' : 'black', marginTop: '10px'}}>Files must be flac or wav</span>
                ) : transcriber === 'whisper'? (
                    <span style={{color: fileError ? 'red' : 'black', marginTop: '10px'}}></span>
                ): null}
                <input type="file" accept="video/mp4" onChange={handleFileChange} />
            <div>
            {files &&
                Array.from(files).map((file, index) => (
                <div className='file-uploader-actions' key={index}>
                    <p>{file.name}</p>
                    <button className='btn' onClick={() => handleRemoveFile(index)}>Remove</button>
                </div>
                ))}
            </div>
            <button className='btn' onClick={handleUpload} disabled={fileError}>SAVE Video</button>
            {fileError && (
                <span style={{color: 'red', marginTop: '10px'}}>Some files are not wav/flac. Please check again</span>
            )}
            {isLoading && <div>Uploading...</div>}
            {isError && <div>Error: {message}</div>}
        </div>
    );
}

export default GlobalVideoFileUploader