function CustomToast ({ message, linkTo }) {

  const handleClick = () => {
    window.location.href = linkTo;
  }

  return (
    <div>
      <span style={{fontSize: '1.1rem'}} onClick={handleClick}>
        {message}
      </span>
    </div>
  )
}

export default CustomToast

