import CopiesTable from '../../../components/marketing/shared/CopiesTable'



function CopiesHome() {
 

    return (
     <CopiesTable/>
    )
}

export default CopiesHome 