import axios from 'axios'
import { HOST_URL } from '../../config';


const API_URL = `${HOST_URL}trailers/`


// get all data
const getScript = async (id, token) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const url = `${API_URL}/${id}`; // Assuming API_URL is the base URL for your API
  const response = await axios.get(url, config);
  return response.data;
}


// get all data
const downloadClips = async (data, token) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const url = `${API_URL}/download/${data.id}`; // Assuming API_URL is the base URL for your API
  const response = await axios.get(url, config);
  return response.data;
}


// create link

const createScript = async (scriptData, token) => {
  const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  const data = {
      id: scriptData.id,
      masterclass: scriptData.masterclass,
      regenerate: scriptData.regenerate ? scriptData.regenerate : null
  }

  const response = await axios.post(API_URL, data, config);
  console.log("response", response.data)
  return response.data;
};


// update link

const deleteScript = async (id, token) => {
  const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
console.log('deleting')

  const url = `${API_URL}/${id}`; // Assuming API_URL is the base URL for your API
  const response = await axios.delete(url, config);
  return response.data;
};


const updateScript = async (data, token) => {
  const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

  const response = await axios.put(API_URL, data, config);
  return response.data;
};






const trailerService = {
    getScript,
    createScript,
    deleteScript,
    updateScript,
    downloadClips
  }
  
  export default trailerService