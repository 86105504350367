import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import linkTreeService from './linkTreeService'



const initialState = {
    linkTreeEng: [],
    linkTreeEsp: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}


// get all data

export const getLinkTreeData = createAsyncThunk('linktree/getLinkTreeData', async (_, thunkAPI) => {
  try{
      return await linkTreeService.getLinkTreeData()
  } catch(error){
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message)
  }
})

// create new link

export const createLink = createAsyncThunk('linktree/createLink', async (linkData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await linkTreeService.createLink(linkData, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// create new link

export const updateLinkTree = createAsyncThunk('linktree/updateLinkTree', async (linkData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await linkTreeService.updateLinkTree(linkData, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


// delete a linkdata

export const deleteLinkTreeData = createAsyncThunk('linktree/deleteLinkTreeData', async (id, thunkAPI) => {
  try{
      const token = thunkAPI.getState().auth.user.token;
      return await linkTreeService.deleteLinkTreeData(id, token)
  } catch(error){
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message)
  }
})



export const linktreeSlice = createSlice({
    name: 'linktree',
    initialState,
    reducers: {
      reset: (state) =>{
        state.isLoading = false
        state.isError = false
        state.message = ''
        state.isSuccess = false
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getLinkTreeData.pending, (state) => {
          state.isLoading = false
        })
        .addCase(getLinkTreeData.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.linkTreeEng = action.payload.eng 
            state.linkTreeEsp = action.payload.esp 
        })
        .addCase(getLinkTreeData.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.linkTreeEng = null
            state.linkTreeEsp = null
        })  
        .addCase(createLink.pending, (state) => {
          state.isLoading = false
        })
        .addCase(createLink.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
        })
        .addCase(createLink.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(deleteLinkTreeData.pending, (state) => {
          state.isLoading = false
        })
        .addCase(deleteLinkTreeData.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.linkTreeEng = state.linkTreeEng.filter((link) => link.id !== action.payload.id)
            state.linkTreeEsp = state.linkTreeEsp.filter((link) => link.id !== action.payload.id)
        })
        .addCase(deleteLinkTreeData.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(updateLinkTree.pending, (state) => {
          state.isLoading = false
        })
        .addCase(updateLinkTree.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
        })
        .addCase(updateLinkTree.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
    },
  })
  

  export const {reset} = linktreeSlice.actions
  export default linktreeSlice.reducer