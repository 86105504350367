import { useNavigate } from 'react-router-dom'
import { IoIosSkipBackward} from 'react-icons/io'
import { useSelector } from 'react-redux'





function BackButton() {

const navigate = useNavigate();
const { user} = useSelector((state) => state.auth);

if(user){
    return (
    <button className='btn btn-reverse btn-back' onClick={() => navigate(-1)}>
        <IoIosSkipBackward/>  Go Back
    </button>
    )
}


}

export default BackButton