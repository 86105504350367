import { useState, useEffect, useRef } from "react"
import {useSelector} from 'react-redux'
import useOpenResume from "../../../hooks/useOpenResume";
import '../../../pages/videoTeam/SingleVideo.css'
import FileUploader from "../../video/files/FileUploader";
import {createResume, getResumeByChapterId, deleteResume, resumeAllOriginalChapters} from '../../../features/marketing_team/resumesSlice'
import { getChaptersByVideoId } from "../../../features/video_team/chapters/chaptersSlice";
import {AiOutlineCloudDownload, AiOutlineCloseCircle} from 'react-icons/ai';


import {useDispatch} from 'react-redux'
import { toast } from 'react-toastify';



function SingleChapterMaskerting({codes}) {

    const { openResume } = useOpenResume();
    const dispatch = useDispatch();

    const [languageCodes,  setLanguageCodes] = useState(codes);
    const [searchTerm, setSearchTerm] = useState();
    const [filteredChapters, setFilteredChapters] = useState([]);
    const [chapterData, setChapterData] = useState([]);
    const [createResumeForm, setCreateResume] = useState(false);
    const [id_chapter, setChapter] = useState(null);
    const [filename, setFilename] = useState('');
    const [displayedFormChapterId, setDisplayedFormChapterId] = useState(null);


    const {video_chapters, isLoading } = useSelector((state) => state.chapters);
    const {video} = useSelector((state) => state.videos);

    // FORM TO CREEATE RESUME
    const [formData, setFormData] = useState({

        instructor: video.instructor?video.instructor:'',
        title: video.title_r?video.title_r:'',
        prompt: video.prompt_r?video.prompt_r:'',
        keywords: video.keywords?video.keywords:''

    },video)

    const { instructor, title, prompt, keywords} = formData;

    const onChange = (e) => {
        setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }))
    }

 

    const onSubmit = (e) => {
        e.preventDefault()
    
        const data = {
            instructor,
            title,
            language_id: video.id_language,
            id_chapter,
            filename,
            prompt,
            keywords,
        }

        toast.info('Resuming file')
        setCreateResume(false)
        setFilename('')
        setFilename(null)
        dispatch(createResume(data))
        .unwrap()
            .then((response) => {
                toast.success('File resumed')
                openResume(response.finalName)
            })
            .catch((error) => {
                console.log(error)
                toast.error(error)
            })
    }


    const formRef = useRef(null);

    const onClickOutsideHandler = (event) => {
        if (formRef.current && !formRef.current.contains(event.target)) {
            setDisplayedFormChapterId(null);
        }
    };
    

    useEffect(() => {
        if (displayedFormChapterId) {
            document.addEventListener('mousedown', onClickOutsideHandler);
        }

        return () => {
            document.removeEventListener('mousedown', onClickOutsideHandler);
        };
    }, [displayedFormChapterId]); // dependency array, re-run useEffect when createResume state changes


    // FORM CREATE RESUME

    




    useEffect(() => {
        if(video_chapters && video_chapters.length > 0){
            setFilteredChapters(video_chapters)
            setChapterData(video_chapters)
        } else {
            setFilteredChapters(null)
            setChapterData(null)
        }
        return () => {
            setFilteredChapters(null); // clear state when unmounting
            setChapterData(null); // clear state when unmounting
          };
    }, [video_chapters])


    useEffect(() => {
        const codesArray = [];
        
        if(codes){
            for(let i = 0; i < codes.length; i ++){
                codesArray.push(codes[i]);
            }
        }
        if(video){
            codesArray.push(video.original_language_code);
        }
        setLanguageCodes(codesArray)
    }, [codes, video])

    // search for chapters

  const handleChange = (e) => {
    const search = e.target.value.toLowerCase();

    const filteredData = chapterData.filter((chapter) => {
      return chapter.chapter_title.toLowerCase().includes(search);
    });

    setFilteredChapters(filteredData);
    setSearchTerm(search);
};



// get filename to open resume in editor

const getFilename = (id) => {
    dispatch(getResumeByChapterId(id))
    .unwrap()
        .then((response) => {
            openResume(response[0].Filename, null, id)
        })
        .catch((error) => {
            toast.error(error)
    })
}

// delete resume by chapter id

const deleteResumeById = (id) => {
    const confirm = window.confirm('Are you sure you want to delete this resume?')
    if(confirm){
        dispatch(deleteResume(id))
        .unwrap()
            .then(() => {
                dispatch(getChaptersByVideoId(video.id))
                toast.success('Resume deleted')
            })
            .catch((error) => {
                toast.error('Cant delete file')
            })
    }
}


    if(filteredChapters && filteredChapters.length > 0){
        return (
            <div>
            <input type="text" className="search-box" value={searchTerm} placeholder="Search by filename" onChange={handleChange} />
            {filteredChapters.map((chapter) => (
                
                <div key={chapter.id} className="chapter-container">
                    
                    {chapter.transcribed === 0 ? (
                        <h3>File {chapter.audio_name} is not transcribed</h3>
                    ) : (
                        <>
                        <h3 style={{ marginBottom: '30px' }}>
             
                            Chapter: {chapter && chapter.title ? chapter.title.replace(/_\d+$/, '') : ''}
                        </h3>
                        <div className="original-file-container">
                                                    {chapter.filename ? (
                                <div  className="single-translation">
                                <span className="flex-center"><p className="language-title">{video.video_language}</p></span>
                                <div className="translations-buttons-container">
                                    <div className="btn-actions modified">{new Date(chapter.updated_at).toLocaleDateString('en-GB')}</div>
                                    <button className='btn-actions' onClick={() => openResume(chapter.filename)}>
                                        SRT 
                                    </button> 
                                    {!chapter.chapter_generated ? (
                                        <button disabled={chapter.chapter_generated} className='btn-actions' onClick={() => {setDisplayedFormChapterId(chapter.id); setChapter(chapter.id); setFilename(chapter.filename);}}>
                                            CREATE RESUME
                                        </button> 
                                        

                                    ) : (
                                        <>
                                            <button className='btn-actions' onClick={() => getFilename(chapter.id)}>
                                                VIEW RESUME
                                            </button> 
                                            <button className='btn-actions' onClick={() => deleteResumeById(chapter.id)}>
                                                DELETE RESUME
                                            </button>
                                        </>    
                                    )}        
                                            
                                </div>
                                {displayedFormChapterId === chapter.id && (
                                    <form className="resume-form" onSubmit={onSubmit} ref={formRef}>
                                    <AiOutlineCloseCircle onClick={() => setDisplayedFormChapterId(9999)} size={23} className='close-form'/>
                                    <div className='form-group'>
                                      <input
                                        type='text'
                                        className='form-control'
                                        id='title'
                                        name='title'
                                        value={title}
                                        onChange={onChange}
                                        placeholder='Enter masterclass title'
                                        required
                                      />
                                    </div>
                                    <div className='form-group'>
                                      <input
                                        type='text'
                                        className='form-control'
                                        id='instructor'
                                        name='instructor'
                                        value={instructor}
                                        onChange={onChange}
                                        placeholder='Enter instructor name'
                                        required
                                      />
                                    </div>
                                    <div className='form-group'>
                                      <input
                                        type='text'
                                        className='form-control'
                                        id='keywords'
                                        name='keywords'
                                        value={keywords}
                                        onChange={onChange}
                                        placeholder='Add Keywords'
                                        required
                                      />
                                    </div>
                                    <div className='form-group'>
                                      <textarea
                                        type='text'
                                        className='form-control'
                                        id='prompt'
                                        name='prompt'
                                        value={prompt}
                                        onChange={onChange}
                                        placeholder='Add your own prompt'
                                      />
                                    </div>
                                    <input
                                        type='hidden'
                                        id='id_chapter'
                                        name='id_chapter'
                                        value={chapter.id} 
                                    />
                                    <input
                                        type='hidden'
                                        id='filename'
                                        name='filename'
                                        value={chapter.filename}
                                    />

                                   
                                    
                                    <div className='form-group center-element'>
                                      <button className='btn btn-block center-element'>Create</button>
                                    </div>
                              </form>
                      
                                )}
                            </div>
                            ): (
                                <>
                                <p>Please upload a file:</p>
                                <FileUploader id_chapter={chapter.id} upload={'file'} videoId={video.id}/>
                                </>
                            )}
                        </div>
                        </>
                    )}
                </div>
                
            ))}
            </div>
        )
    } else if (!video_chapters || video_chapters.length < 1) {
        return (
            <h1 style={{margin: '40px 0'}}>This Masterclass has no chapters yet.</h1>
        )
    }

}

export default SingleChapterMaskerting