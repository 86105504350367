import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getAllLanguages, displayNewLanguage, getLanguages, hideLanguage} from '../../features/video_team/languagesfeat/languageSlice';
import { toast } from 'react-toastify';
import {MdDeleteForever} from 'react-icons/md'
import {AiOutlineArrowRight} from 'react-icons/ai'




function SettingsLanguages() {

  const dispatch = useDispatch();

  const [data, setData] = useState();
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [selectedOption, setSelectedOption] = useState();

    // get all languages once component is rendered
    useEffect(() => {
      dispatch(getAllLanguages())
      .unwrap()
         .then((language) => {
           setData(language.data)
         })
         .catch(toast.error)
     }, [dispatch]);

    // get current available languages
    useEffect(() => {
      dispatch(getLanguages())
      .unwrap()
         .then((language) => {
           setAvailableLanguages(language.data)
         })
         .catch(toast.error)
     }, [dispatch]);


    //  delete language from available list
    const handleDelete = (id) => {
      const result = window.confirm('Are u sure you want to delete this language?');
        if (result) {
          dispatch(hideLanguage(id))
          .then(() => {
            dispatch(getLanguages())
            .unwrap()
                .then((language) => {
                  setAvailableLanguages(language.data)
                })
          })
          .catch(toast.error) 
        }
    }

    //  handle e¡state of aselect button

    const handleChange = (e) => {
      setSelectedOption(e.target.value);
    };

    const onSubmit = (e) => {
      e.preventDefault();
      dispatch(displayNewLanguage(selectedOption))
      .unwrap()
        .then(() => {
          toast.success(`Language added`)
          dispatch(getLanguages())
            .unwrap()
                .then((language) => {
                  setAvailableLanguages(language.data)
                })
        })
        .catch(toast.error)
    }


     if(!data){
      return null
     } else {
      return (
        <>
        <h1>Language Settings</h1>
          <div className='settings-container'>
          <div className="settings-container-right">
              <h2>Add language:</h2>
              <form style={{marginTop: '20px'}} onSubmit={onSubmit}>
              <select onChange={handleChange} placeholder={'Select language'} required>
                  {data.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.title}
                      </option>
                  ))}
              </select>
              <button className='btn' style={{marginTop: '10px'}}>ADD</button>
              </form>
            </div>
            <div className="settings-container-left">
            <h2>AVAILABLE LANGUAGES:</h2>
              {availableLanguages.map((language, index) => (
                <div className="available-language-container">
                  <AiOutlineArrowRight/>
                   <img
                        key={index}
                        src={`https://flagcdn.com/${language.code.split('-')[0] === 'en' ? 'gb' : language.code.split('-')[0]}.svg`}
                        height="12"
                        alt={language.title}
                  />
                  <span>{language.title}</span>
                  <button onClick={() => handleDelete(language.id)} className="btn-languages"><MdDeleteForever size={25}/></button>
                </div>
             ))}
            </div>
            
          </div> 
          </> 
       )
     } 
    
}

export default SettingsLanguages