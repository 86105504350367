import { useEffect, useRef, useState } from "react";
import {useSelector, useDispatch} from 'react-redux'
import AceEditor from "react-ace";
import { useLocation, useNavigate} from "react-router-dom";
import { toast } from 'react-toastify';
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools"
import {getScript, deleteScript, createScript, updateScript, downloadClips} from '../../features/trailer/trailerSlice';
import Spinner from "../shared/Loaders/Spinner";
import { FaRegTrashAlt, FaRecycle, FaCloudDownloadAlt } from "react-icons/fa";
import { CiSaveDown1 } from "react-icons/ci";


function ScriptEditor() {

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const editorRef = useRef();



  const { content, isLoading} = useSelector((state) => state.trailer);
  const { video} = useSelector((state) => state.videos);

  const masterclassId = location.state?.masterclassId;
  const masterclass = location.state?.masterclass;
  const [file, setFile] = useState('');
  

  useEffect(() => {
    if (content && content !== file) {
      console.log(typeof content, "content", content)
      setFile(content);
    } else if (masterclassId && !content) {
      dispatch(getScript(masterclassId))
        .unwrap()
        .then((response) => {
            setFile(response);
        })
        .catch((error) => {
            toast.error(error);
        });
    } else if (video && !content) {
      dispatch(getScript(video.id))
        .unwrap()
        .then((response) => {
            setFile(response);
        })
        .catch((error) => {
            toast.error(error);
        });
    }
  }, [video, masterclassId, content, dispatch]);



  


  
// save the changes on the srt file
  const handleSave = () => {
    toast.info('Saving script')
    // const newFile = new File([editorRef.current.editor.getValue()], filename, { type: "application/x-subrip" });
    const data = {
      id: masterclassId,
      updatedScript: editorRef.current.editor.getValue(),
    }

    console.log(typeof data.updatedScript)
    dispatch(updateScript(data))
    .unwrap()
    .then(() => {
      toast.success('File Changed Successfully')
      // dispatch(reset())
    })
    .catch((error) => {
      toast.error(error)
    })
    .then(() => {
      // dispatch(reset());
    });
  }

  const handleDeleteScript = () => {
    const confirm = window.confirm('Are you sure you want to delete?')
    if(confirm){
      toast.info('Deleting...')
      dispatch(deleteScript(masterclassId))
        .unwrap()
          .then((response) => {
              toast.success('Successfully deleted')
              navigate('/trailers/')
          })
          .catch((error) => {
              toast.error(error)
          })
    }
  }


  // regenerate script
  const handleRegenerate = () => {
      toast.info('Regenrating...')
      
      const data= {
        id: masterclassId,
        masterclass: masterclass,
        regenerate: true,
      }
      console.log(data)
      dispatch(createScript(data))
        .unwrap()
          .then((response) => {
              toast.success('New script generated')
          })
          .catch((error) => {
              toast.error(error)
          })
    
  }

  // regenerate script
  const handleDownloadClips = () => {
      toast.info('Downloading...')
      
      const data= {
        id: masterclassId,
      }
      dispatch(downloadClips(data))
        .unwrap()
          .then((response) => {
              toast.success('Clips are ready')
          })
          .catch((error) => {
              toast.error(error)
          })
    
  }




  // if(isLoading){
  //   return (
  //     <Spinner/>
  //   )
  // }

  return(

      <div>
        {file && (
          <AceEditor
            key={file}
            mode="text"
            theme="github"
            value={file}
            onChange={(value) => setFile(value)}
            ref={editorRef}       
            name="ace-editor"
            wrapEnabled={true}
            setOptions={{
              showLineNumbers: true,   // Habilita el ajuste de línea
              indentedSoftWrap: false,
              behavioursEnabled: false,
              showPrintMargin: false,
          }}
            width="100%"
            height="500px"
            editorProps={{$blockScrolling: Infinity}}
          />
        )}
        
      <div className="script-editor-buttons-container">
        <button className='btn btn-primary margin-top' onClick={handleSave}><CiSaveDown1 /> Save Changes</button>
        <button className='btn btn-reverse btn-primary margin-top' onClick={handleRegenerate}><FaRecycle />Regenerate</button>
        <button className='btn btn-reverse btn-download btn-primary margin-top' onClick={handleDownloadClips}><FaCloudDownloadAlt />Download Clips</button>
        <button className='btn btn-trash margin-top' onClick={handleDeleteScript}><FaRegTrashAlt /> Delete Script</button>
      </div>
      </div>
      
    )
  }

export default ScriptEditor;