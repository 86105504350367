import { useState, useRef } from "react"
import { useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { Link, useParams, useNavigate, useLocation} from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from "../../components/shared/Loaders/Spinner";
import { getSingleVideoBlog, reset } from "../../features/video_team/videosUpload/videosSlice";
import './SingleVideo.css';
import AudioPlayer from "../../components/video/files/AudioPlayer";
import { displayFileUrl } from "../../features/video_team/files/fileSlice";
import AceEditor, {highlight} from "react-ace";




function SingleBlog() {
  const { id } = useParams();
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const editorRef = useRef();
  const editorRefSummary = useRef();
  const [showVisual, setShowVisual] = useState(true);
  const [file, setFile] = useState('');



  // Set codes of languages trhat are already translated, plus code of masterclass original language and pass them to child component SelectLanguages

  const [video, setVideo] = useState(null);
  const { isLoading, isSuccess, isError, message } = useSelector((state) => state.videos);



  

  // get all data from this on masterclass
  useEffect(() => {
    if(id){
        dispatch(getSingleVideoBlog(id))
        .unwrap()
        .then((result_copy) => {
          setVideo(result_copy);
          if(result_copy.filename){
            dispatch(displayFileUrl(result_copy.filename))
            .unwrap()
            .then((result_file)=>{
              setFile(result_file)
            })
          }
        })
        .catch((error) => {
          toast.error(error)
          navigate('/marketing/social-media/blogs')
          // dispatch(reset());
      });
    }
    return () => {
      setVideo(null); // clear state when unmounting
    };


  }, [dispatch, id, navigate])


 



  // if theres an error display message from the error
useEffect(() =>{
    if(isError){
        toast.error(message)
    }
    dispatch(reset());

}, [isError, isSuccess, dispatch, message])


  if(isLoading || !id ) {
    return (
      <Spinner/>
    )
  }
  
    
  if(video){
    return (
    <>
      <h1>Caption: {video?.masterclass}</h1>
        <div className="audio-player-container " style={{display:'flex', alignItems:'center',gap:'20px'}}>
          {video?.path && (
                <AudioPlayer  link={video.path}/>
            )
          }
          {video?.caption  && (
            <button style={{width:'fit-content'}} className="btn btn-reverse btn-back" onClick={() => setShowVisual(!showVisual)}>
            {showVisual ? 'Show code' : 'Show visual content'}
            </button>
          )}
         
        </div>
        <div className="editor-container">
          <div className="editor-block">
              {file && (
                <>
                <AceEditor
                    mode="text"
                    theme="github"
                    value={file}
                    ref={editorRef}       
                    name="ace-editor"
                    wrapEnabled={true}
                    setOptions={{
                      showLineNumbers: false,   // Habilita el ajuste de línea
                      indentedSoftWrap: false,
                      behavioursEnabled: false,
                      showPrintMargin: false,
                    }}
                    width="100%"
                    style={{marginBottom: '20px'}}
                    height="500px"
                    editorProps={{$blockScrolling: Infinity}}
                  />
                </>
              )}
          </div>
          <div className="editor-block">
          
            {video?.caption  && showVisual && (
                <>
                  <iframe
                  srcDoc={video.caption}
                  title="Visual Preview"
                  style={{ width: '100%', height: '500px', border: '0',fontFamily: 'Nunito', marginBottom:'20px'}}
                ></iframe>
                </>
              )}

            {video?.caption &&  ! showVisual &&(
                <>
                  <AceEditor
                    mode="html"
                    theme="github"
                    value={video.caption}
                    ref={editorRefSummary}       
                    name="ace-editor"
                    wrapEnabled={true}
                    setOptions={{
                      showPrintMargin: false,
                    //   showLineNumbers: false,   // Habilita el ajuste de línea
                    //   indentedSoftWrap: false,
                    //   behavioursEnabled: false,
                    }}
                    width="100%"
                    height="500px"
                    style={{marginBottom: '20px'}}
                    editorProps={{$blockScrolling: Infinity}}
                  />
                </>
              )}
              
              
          </div>
        </div>
    </>  
    )
  }

}
    
export default SingleBlog