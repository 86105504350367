import {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'


export const useRoleMarketing = () => {
    const [access, setAccess] = useState(false);
    const [checkingStatus, setCheckingStatus] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);

    const {user} = useSelector ((state) => state.auth);

    useEffect(() => {
        if(user && (user.role === 'marketing' || user.role === 'admin')){
            setAccess(true)
            setLoggedIn(true)
        } else if (user && user.role === 'video') {
            setLoggedIn(true)
            setAccess(false)
        } else {
            setAccess(false)
            setLoggedIn(false)
        }

        setCheckingStatus(false)

    }, [user])

    return {access, loggedIn, checkingStatus}
}