import { FaSignInAlt, FaSignOutAlt, FaUser } from 'react-icons/fa'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {logout, reset, setDarkModeRedux} from '../../../features/auth/authSlice'
import { useEffect, useState} from 'react'
import {AiOutlineHome} from 'react-icons/ai'


function Header() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [darkMode, setDarkMode] = useState(false);
  const [themeColor, setThemeColor] = useState('');
  const [colorPicker, setColorPicker] = useState(false);
  const [userRole, setUserRole] = useState(null);


  const { user} = useSelector((state) => state.auth);

  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/login')
  }

  // depending on location show marketing or video name
  useEffect(() => {
    if(location.pathname.includes('video')){
      setUserRole('video')
    }else if (location.pathname.includes('marketing')){
      setUserRole('marketing')
    } else {
      setUserRole(null)
    }
  }, [location])


  // update darkmode and theme color from local storage
  useEffect(() => {
    const mode = JSON.parse(localStorage.getItem('darkMode'))
    setDarkMode(mode);
    dispatch(setDarkModeRedux(mode))
    const color = (localStorage.getItem('themeColor'))
    setThemeColor(color);
     // eslint-disable-line react-hooks/exhaustive-deps
  }, [])

  // change dark mode
  useEffect(() => {
    if(darkMode){
      document.documentElement.style.setProperty('--black', '#ffffff');
      document.documentElement.style.setProperty('--white', '#28292c');
      document.documentElement.style.setProperty('--black2', '255, 255, 255');
      document.documentElement.style.setProperty('--white2', '40, 41, 44');
    } else {
      document.documentElement.style.setProperty('--black', '#28292c');
      document.documentElement.style.setProperty('--white', '#ffffff');
      document.documentElement.style.setProperty('--black2', '40, 41, 4');
      document.documentElement.style.setProperty('--white2', '255, 255, 255');
    }
  }, [darkMode])

  const changeDarkMode = () => {
    const newMode = !darkMode;
    setDarkMode(newMode);
    dispatch(setDarkModeRedux(newMode))
    localStorage.setItem('darkMode', JSON.stringify(newMode))
  }

  // change theme color picker
  useEffect(() => {
    document.documentElement.style.setProperty("--theme-color", `var(--${themeColor})`);
  }, [themeColor])

  const changeColorTheme = (e) => {
    let color = e.target.value;
    setThemeColor(color);
    localStorage.setItem('themeColor', color)
  }




return (
    <header className='header'>
  <div className='left-nav'>
    {user && user.role === 'video' ? (
      <Link to='/videos/home'><AiOutlineHome size={30}/></Link>
      ) : (
    user && user.role === 'marketing'? <Link to='/marketing/home'><AiOutlineHome size={30}/></Link> 
    : user && user.role === 'admin'? <Link to='/'><AiOutlineHome size={30}/></Link>  
    : null
     )}
    {user ? (
      <>
        <button className='btn btn-reverse btn-bloc theme-picker-sectionk' onClick={() => setColorPicker(!colorPicker)}>
           color
        </button>
        {colorPicker && (
          <div className="theme-picker-section">
          <input type="radio" name="theme" value="red" onChange={changeColorTheme} />
          <input type="radio" name="theme" value="orange" onChange={changeColorTheme} />
          <input type="radio" name="theme" value="green" onChange={changeColorTheme} />
          <input type="radio" name="theme" value="blue" onChange={changeColorTheme} />
          <input type="radio" name="theme" value="indigo" onChange={changeColorTheme} />
          <input type="radio" name="theme" value="yellow" onChange={changeColorTheme} />
          <input type="radio" name="theme" value="black2" onChange={changeColorTheme} />
        </div>
        )}
     </>
    ) : null }
  </div>
  {userRole ? <p className='nav-role'>- {userRole} -</p> : <p className='nav-role'>Aulart Tools</p>}
  
  <ul>
    {user ? (
      <>
    <li className='day-night-container'>
    <div className= 'toggle-switch'>
       <label className='label-switch'>
           <input className='input-switch' type='checkbox' checked={darkMode} onChange={changeDarkMode}/>
           <span className='slider'></span>
       </label>
   </div>
    </li>
      <li>
        <button className='btn' onClick={onLogout}>
          <FaSignOutAlt /> Logout
        </button>
      </li>
      </>
 ) : ( 
    <> 
        <li>
          <Link to='/login'>
            <FaSignInAlt /> Login
          </Link>
        </li>
        <li>
          <Link to='/register'>
            <FaUser /> Register
          </Link>
        </li>
       </>
    )} 
  </ul>
</header>
    
    )
}

export default Header