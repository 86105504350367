import SelectLanguages from "../../components/video/shared/SelectLanguages"
import FileUploader from '../../components/video/files/FileUploader'
import AudioFileUploader from '../../components/video/files/AudioFileUploader'
import DacastVideoUploader from '../../components/video/files/DacastVideoUploader'
import { useState } from "react"
import { useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { createVideo } from "../../features/video_team/videosUpload/videosSlice";
import { toast } from "react-toastify";
import SkipButton from "../../components/shared/buttons/SkipButton";
import Downloader from "../../components/shared/Loaders/Downloader";
import GlobalVideoFileUploader from "../../components/video/files/GlobalVideoFileUploader"

function CreateVideos() {

  const dispatch = useDispatch();

  const [title, setTitle] = useState('');
  const [language, setLanguage] = useState('');
  const [language_code, setLanguageCode] = useState('');
  const [native_language, setNative_language] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [videoId, setVideoId] = useState(false);
  const [srtFile, setSrtFile] = useState(false);
  const [audioFile, setAudioFile] = useState(false);
  const [videoFile, setVideoFile] = useState(true);
  const [dacastFile, setDacastFile] = useState(false);
  const [transcriber, setTranscriber] = useState('whisper');
  const [promptValue, setPrompt] = useState('This is a music production masterclass for Aulart made by _______, a producer from _____ most known for producing music for the band ____. Time-based sentence division: Limit each sentence to 10 words or less.');
  const [radioButtonValue, setRadioButtonValue] = useState('srt');

  const { user } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.transcriptions);
  const [dacastID, setDacastID] = useState('');


  const onChange = (e) => {
      setTitle(e.target.value)
  }
  const onChange2 = (e) => {
      setNative_language(e.target.value)
  }

  // callback options to retrieve data from child components (url and language id)

  const handleLanguage = (option, option2) => {
    setLanguage(option);
    setLanguageCode(option2)
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      setSubmitting(true);
  };

  useEffect(() => {
      if (submitting) {
          const videoData = {
              title,
              native_language,
              id_language: language,
              user_id: user._id,
          };

          dispatch(createVideo(videoData))
              .unwrap()
              .then((video) => {
                  setVideoId(video.data.id)
                  setSubmitting(false)
              })
              .catch((error) => {
                  toast.error(error)
              });
      }
  }, [submitting, title, language, user, dispatch]);

  const handleChangeSelect = (event) => {
    setTranscriber(event.target.value)
  }
  const handleRadioButtonChange = (event) => {
    setRadioButtonValue(event.target.value);
  };
  const onChangePrompt = (event) => {
    setPrompt(event.target.value)
  }

  const handleChangeDacast = (e) => {
    e.preventDefault();
    const dacast_id = e.target.value;
    setDacastID(dacast_id);


  }



  if (isLoading && !((dacastFile && dacastID))){
    return (
        <Downloader text='Transcribing audio files...'/>
    )
  }


  if(videoId){
    return (
        <div className="upload-file-container">
            <div className="create-buttons-container">
            <h2>CHOSE AN OPTION:</h2>
                {/* <button className={audioFile ? 'btn' : 'btn btn-reverse'} onClick={() => { setAudioFile(!audioFile); setVideoFile(false); setSrtFile(false); setDacastFile(false); }}>UPLOAD AUDIO FILES</button> */}
                <button className={videoFile ? 'btn' : 'btn btn-reverse'} onClick={() => { setVideoFile(!videoFile); setAudioFile(false); setSrtFile(false); setDacastFile(false); }}>UPLOAD VIDEO FILE</button>
                <button className={srtFile ? 'btn' : 'btn btn-reverse'} onClick={() => { setSrtFile(!srtFile);setVideoFile(false); setAudioFile(false); setDacastFile(false); }}>UPLOAD SRT FILES</button>
                <button className={dacastFile ? 'btn' : 'btn btn-reverse'} onClick={() => { setDacastFile(!dacastFile); setSrtFile(false); setVideoFile(false); setAudioFile(false);  }}>UPLOAD FROM DACAST PLAYLIST ID</button>
                {(dacastFile) && (
                <input type="text" className="dacast-box" 
                            value={dacastID} 
                            placeholder="Dacast playlist id"
                            onChange={(e) => handleChangeDacast(e)} /> 
                )}
            

                            
            </div>
            
            {(audioFile || videoFile || (dacastFile && dacastID) ) && (
                <>
                    <div className="chose-transcriber-container">
                        <h2>SELECT METHOD:</h2>
                        <select onChange={handleChangeSelect}>
                            <option value="" disabled >Choose transcriber</option>
                            <option value='google'>
                                GOOGLE
                            </option>
                            <option value='whisper' selected>
                                WHISPER
                            </option>
                            <option value='no-transcriber'>
                                NO TRANSCRIPTION
                            </option>
                        </select>
                    </div>
                    {transcriber === 'whisper' && (
                        <>
                            <div className="prompt-container">
                                <h2>Add a prompt:</h2>
                                <textarea
                                type='text'
                                className='input-form textarea'
                                id='text'
                                name='text'
                                value={promptValue}
                                onChange={onChangePrompt}
                                placeholder='Enter prompt'
                                required
                                />
                            </div>
                            <div className="prompt-container">
                            <h2>Select output:</h2>
                                <label>
                                    <input
                                    type="radio"
                                    value="srt"
                                    checked={radioButtonValue === 'srt'}
                                    onChange={handleRadioButtonChange}
                                    />
                                    <span style={{ marginLeft: '10px' }}>srt</span>
                                </label>
                                <label>
                                    <input
                                    type="radio"
                                    value="text"
                                    checked={radioButtonValue === 'text'}
                                    onChange={handleRadioButtonChange}
                                    />
                                    <span style={{ marginLeft: '10px' }}>text</span>
                                </label>
                            </div>                          
                        </>
                    )}
                     {(dacastFile && dacastID) && (
                        <DacastVideoUploader output={radioButtonValue} prompt={promptValue} transcriber={transcriber} videoId={videoId} language_code={language_code} dacast_id={dacastID}/>
                     )}
                    {(audioFile) && (
                        <AudioFileUploader output={radioButtonValue} prompt={promptValue} transcriber={transcriber} videoId={videoId} language_code={language_code} />
                    )}
                    {(videoFile) && (
                        <GlobalVideoFileUploader output={radioButtonValue} prompt={promptValue} transcriber={transcriber} videoId={videoId} language_code={language_code} />
                    )}
                    <SkipButton navigateTo={`/videos/video/${videoId}`}/>
                </>
            )}

            {srtFile && (
                <>
                    <h1 className="upload-titles">UPLOAD SRT FILES</h1>
                    <FileUploader videoId={videoId} upload={'files'}/>
                    <SkipButton navigateTo={`/videos/video/${videoId}`}/>
                </>
            )}
            
        </div>
    )
  }

  return (
    <div>
        <h1>Create new project</h1>
        <section className='form'>
            <form onSubmit={handleSubmit}>
                <div className='form-group'>
                    <input
                        type='text'
                        className='form-control'
                        id='title'
                        name='title'
                        value={title}
                        onChange={onChange}
                        placeholder='Enter masterclass name'
                        required
                    />
                </div>
                <div className='form-group'>
                    <input
                        type='text'
                        className='form-control'
                        id='native_language'
                        name='native_language'
                        value={native_language}
                        onChange={onChange2}
                        placeholder='Enter teacher native language'
                        required
                    />
                </div>
                <div className='form-group'>
                    <SelectLanguages onHandleLanguage={handleLanguage} submitting={submitting} us={true} selectText={'Choose file original language'} />
                </div>
                <div className='form-group'>
                    <button className='btn btn-block'>TRANSCRIPT</button>
                </div>
            </form>
        </section>
  </div>
  );
}

export default CreateVideos;