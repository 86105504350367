import {Navigate, Outlet} from 'react-router-dom'
import {useRoleMarketing} from '../../../hooks/useRoleMarketing'
import Spinner from '../Loaders/Spinner'

  
const MarketingTeamRoutes = () => {

    const {access, loggedIn, checkingStatus} = useRoleMarketing();
    const state = {data: 'Marketing Team'}; // your state object

    if(checkingStatus){
        return <Spinner/>
    }

    if(!loggedIn){
        return <Navigate to='/login'/>
    }

    return access && loggedIn ? <Outlet/> :  <Navigate to='/videos/home'/>
}

export default MarketingTeamRoutes