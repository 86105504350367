import axios from 'axios'
import { HOST_URL } from '../../config';


const API_URL = `${HOST_URL}video/users/`

// Register user
const register = async (userData) => {
  const response = await axios.post(API_URL + 'register', userData)

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
  }
  return response.data
}

// Login user
const login = async (userData) => {
    const response = await axios.post(API_URL + 'login', userData)
  
    if (response.data) {
      localStorage.setItem('user', JSON.stringify(response.data))
    }
    return response.data
  }

 // update user data (password or email or username)
const updateUser = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
    const response = await axios.put(API_URL + 'update-profile', data, config)
  
    if (response.data) {
      localStorage.setItem('user', JSON.stringify(response.data))
    }
    return response.data
  }

// reset password 
const resetPassword = async (data) => {
  const response = await axios.post(API_URL + 'change-password', data)
  return response.data
}


// reset password with token
const resetPasswordWithToken = async (data) => {
  const response = await axios.post(API_URL + 'reset-password', data)
  return response.data
}



  // Logout user
const logout = () => {
  localStorage.removeItem('user')
  localStorage.removeItem('darkMode')
  localStorage.removeItem('themeColor')
}

// get all users
const getUsers = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + 'all', config)

  return response.data
}


// delete one user
const deleteUser = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.delete(API_URL + 'delete/' + userId, config)

  return response.data
}






const authService = {
    register,
    logout,
    login,
    getUsers,
    deleteUser,
    resetPasswordWithToken,
    resetPassword,
    updateUser,
  }
  
  export default authService