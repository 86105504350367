import { useState } from 'react';
import { toast } from 'react-toastify';
import {RiLockPasswordLine} from 'react-icons/ri'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetPasswordWithToken, reset} from '../../features/auth/authSlice';

function ForgotPassword() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { token } = useParams();


  const [formData, setFormData] = useState({
      password: '',
      password2: '',
    })
  
    const {password, password2 } = formData
  
  
    const onChange = (e) => {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }))
    }

    const onSubmit = (e) => {
      e.preventDefault();
        if (password !== password2) {
            toast.error('Passwords do not match')
        }
  
      const data = {
        token,
        password,
      }
      dispatch(resetPasswordWithToken(data))
      .unwrap()
      .then((user) => {
        toast.success(`Password succesfully changed, please log in`)
        navigate('/login')
      })
      .catch((error) => {
        toast.error(error)
      })
      .then(() => {
          dispatch(reset());
      });
      
    }
        
      
      return (
        <>
        <section className='heading'>
          <h1>
            <RiLockPasswordLine /> Change Password
          </h1>
        </section>
  
        <section className='form'>
          <form onSubmit={onSubmit}>
            <div className='form-group'>
              <input
                type='password'
                className='form-control'
                id='password'
                name='password'
                value={password}
                onChange={onChange}
                placeholder='Enter password'
                required
              />
            </div>
            <div className='form-group'>
              <input
                type='password'
                className='form-control'
                id='password2'
                name='password2'
                value={password2}
                onChange={onChange}
                placeholder='Confirm password'
                required
              />
            </div>
            <div className='form-group'>
              <button className='btn btn-block'>Submit</button>
            </div>
          </form>
        </section>
      </>
      )
    }
    
    export default ForgotPassword