import SelectLanguages from './SelectLanguages'
import { useState } from "react"
import { useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { getSingleVideo } from "../../../features/video_team/videosUpload/videosSlice";
import {updateMasterclassLangue} from '../../../features/video_team/languagesfeat/languageSlice';
import { toast } from "react-toastify";




function ChangeLnaguage() {

    const dispatch = useDispatch();
    const { video } = useSelector((state) => state.videos);
    const [language, setLanguage] = useState('');
    const [submitting, setSubmitting] = useState(false);



    // callback options to retrieve data from child components (url and language id)

  const handleLanguage = (option, option2) => {
    setLanguage(option);
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      setSubmitting(true);
  };

  useEffect(() => {
      if (submitting) {
          const data = {
              id_language: language,
              id_video: video.id,
          };

          console.log(data)

          dispatch(updateMasterclassLangue(data))
              .unwrap()
              .then((result) => {
                  setSubmitting(false)
                  dispatch(getSingleVideo(video.id))
              })
              .catch((error) => {
                  toast.error(error)
              });
      }
  }, [submitting, language, dispatch]);


    return (
        <form style={{marginTop: '10px'}} onSubmit={handleSubmit}>
            <SelectLanguages onHandleLanguage={handleLanguage} submitting={submitting} selectText={'Change original language'} />
            <button className='btn btn-block'>CHANGE</button>
        </form>
        )
    }
    
export default ChangeLnaguage