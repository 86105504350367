import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { useSelector, useDispatch } from 'react-redux';
import { getUsers, reset, deleteUser } from '../../features/auth/authSlice';
import { useEffect} from 'react';
import Spinner from '../../components/shared/Loaders/Spinner';
import { toast } from 'react-toastify';
import {MdDeleteForever} from 'react-icons/md'




const Users = () => {

    const { users, user, isLoading } = useSelector((state) => state.auth);


    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getUsers())
            .unwrap()
            .then(() => {
                // 
            })
            .catch((error) => {
                toast.error(error)
            })
            .then(() => {
                dispatch(reset());
            });


    }, [dispatch])


    // handleUserDelete
    const handleDelete = (userId) => {
        const result = window.confirm('This will delete all user data and assign all user videos to the SuperAdmin?');
        if(result){
            dispatch(deleteUser(userId))
            .unwrap()
            .then(() => {
                toast.success('User deleted')
                dispatch(getUsers());
            })
            .catch((error) => {
                toast.error(error)
            })
            .then(() => {
                dispatch(reset());
            });
        }
    }


    // define datatables columns

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
        },
        {
            name: 'NAME',
            selector: row => row.username,
        },
        {
            name: 'EMAIL',
            selector: row => row.email,
        },
        { 
          name: 'DELETE',
          cell: (row) => (
                  <MdDeleteForever className='hover' style={{cursor: 'pointer'}} size={28} onClick={() => handleDelete(row.id)}/>          ),
        },
        {
          name: 'VIDEOS',
          cell: (row) => (
                <Link className='btn btn-reverse' to={`/videos/user/${row.id}`}>
                  View Videos
                </Link>
          ),
        },
              
    ];

   if(isLoading){
    return (
        <Spinner/>
    )
   }

    return (
        <div>
      <h1>Users</h1>
      <DataTable
            columns={columns}
            data={users}
            theme='custom'
            pagination
        />
    </div>
       
    )
}

export default Users