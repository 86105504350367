import { useParams} from 'react-router-dom';
import Spinner from '../../components/shared/Loaders/Spinner';
import VideosTable from '../../components/video/shared/VideosTable'




function UserVideos() {

    const { id } = useParams();

    if(!id){
        return (
            <>
                <Spinner/>
                <h1>And Error Occured Please Go Back and Try Again</h1>
            </>
        )
    }

    return (

      <VideosTable id={id} />
       
    )
}

export default UserVideos