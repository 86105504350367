import { useEffect, useRef, useState } from "react";
import { Link, useParams, useNavigate, useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux'
import {AiFillEdit, AiOutlineCloudDownload} from 'react-icons/ai';
import {MdDeleteForever} from 'react-icons/md';
import FileUploader from "../files/FileUploader";
import ViewTranslations from "../translations/ViewTranslations"
import useOpenSrt from "../../../hooks/useOpenSrt";
import useDelete from '../../../hooks/useDelete';
import { getChaptersByVideoId } from "../../../features/video_team/chapters/chaptersSlice";
import {getChapterTranslations} from "../../../features/video_team/translations/translationsSlice";
import '../../../pages/videoTeam/SingleVideo.css'
import Spinner from "../../shared/Loaders/Spinner";
import AudioPlayer from "../files/AudioPlayer";
import { HOST_URL } from '../../../config';
import {updateDacastID, updateStrToDacast, getDacastSubtitles, setSubtitles} from "../../../features/video_team/videosUpload/videosSlice";
import {uploadDacastVideos} from '../../../features/video_team/audioFeatures/audioSlice';
import Downdloader from '../../shared/Loaders/Downloader'

import { toast } from 'react-toastify';



function SingleChapter({codes}) {

    const { openSrt } = useOpenSrt();
    const {handleDelete} = useDelete();
    const [languageCodes,  setLanguageCodes] = useState(codes);
    const [searchTerm, setSearchTerm] = useState();
    const [filteredChapters, setFilteredChapters] = useState([]);
    const [chapterData, setChapterData] = useState([]);
    const [dacastID, setDacastID] = useState([]);
    const dispatch = useDispatch();
    const [loadingMessage, setLoadingMessage] = useState(false); // Inicializa el estado
    const [loadingButton, setLoadingButton] = useState(false);

    const {video_chapters, isLoading } = useSelector((state) => state.chapters);
    const {video, dacast_subtitles} = useSelector((state) => state.videos);

    useEffect(() => {

        if(video_chapters && video_chapters.length > 0){

            const videooriginals = video_chapters.filter((chapter)=>chapter.transcribed);
            let dacasts_id = [];
            
            if(videooriginals.length){
                videooriginals.forEach((item, index) => {
                    dacasts_id.push(
                        {
                        'id':item.id,
                        'dacast_id': item.dacast_id==undefined?'':item.dacast_id
                        }
                    )
                });
                setDacastID(dacasts_id);

            }
           
            

            setFilteredChapters(video_chapters)
            setChapterData(video_chapters)

           

        } else {
            setFilteredChapters(null)
            setChapterData(null)
        }
        return () => {
            setFilteredChapters(null); // clear state when unmounting
            setChapterData(null); // clear state when unmounting
          };
    }, [video_chapters])

    useEffect(() => {
        const codesArray = [];
        
        if(codes){
            for(let i = 0; i < codes.length; i ++){
                codesArray.push(codes[i]);
            }
        }
        if(video){
            codesArray.push(video.original_language_code);
        }
        setLanguageCodes(codesArray)
    }, [codes, video])


    useEffect(() => {

        if(!video){return}
        if(!video_chapters){return}
        if(!dacastID){return}
        if(!codes){return;}

        dispatch(getDacastSubtitles({
            dacast_id: dacastID
        }))
        .unwrap()
        .then((response)=>{
            console.log('getDacastSubtitles',response)
        }) 
        .catch((error) => {
            toast.error(error)
        });
        

    }, [dacastID])


    // search for chapters

  const handleChange = (e) => {
    if(!e.target.value){
        return;
    }
    const search = e.target.value.toLowerCase();

    const filteredData = chapterData.filter((chapter) => {
      return chapter.chapter_title.toLowerCase().includes(search);
    });

    setFilteredChapters(filteredData);
    setSearchTerm(search);
    };


    const handleChangeDacast = (e, chapter_id) => {
        e.preventDefault();
    
      const dacast_id = e.target.value;


      const newArray = dacastID.map((item) => {
        if (item.id === chapter_id) {
            return { ...item, dacast_id: dacast_id };
        }
        return item;
     }); 

     setDacastID(newArray);

       
       const data = {
        id: chapter_id,
        dacast_id: dacast_id,
      }
      dispatch(updateDacastID(data))
          .unwrap()
          .then(() => {
            //nothing to do
            // dispatch(getChaptersByVideoId(video.id))
            //     .unwrap()
            //     .then((chapter_restult) => {
            //         // dispatch(getChapterTranslations(video.id))
            //     })
            //     .catch((error) => {
            //         toast.error(error)
            //     });
          })
          .catch((error) => {
            toast.error(error)
        });
       
    }

    const handleSyncToDacast = (e, dacast_id, chapter_id, title_chapter) => {
        // console.log(dacast_id);
        if(!dacast_id.length){return;}
        //send files to dacast
        setLoadingButton(true);
        if(video_chapters && video_chapters.length > 0){
            const data = {
                id: chapter_id,
                dacast_id: dacast_id,
            }
            dispatch(updateStrToDacast(data))
            .unwrap()
            .then(() => {
                setLoadingButton(false);
                toast.success('Files uploaded in "'+title_chapter+'"')
            })
            .catch((error) => {
                toast.error(error)
            });
        }
    }

   const handleSyncToDacastRegenerate= async(e, dacast_id, chapter_id, chapter_title) => {

        let confirm = window.confirm('Are you sure? You need to check subtitles after regenerate');
        if(!confirm){return;}



        setLoadingMessage("Generating videos");
        const chapterArray = [];
        const chapter = {
            dacast_id: dacast_id,
        };
        chapterArray.push(chapter);

        const video_dacast_id = {
            id: dacast_id,
            title: chapter_title
        }
        const data = {
            prompt:  video.prompt?video.prompt:'',
            transcriber: video.transcriber?video.transcriber:'whisper',
            id_video: video.id,
            output: video.transcriber?video.output:'srt',
            chaptersArray: chapterArray,
            language_code: video.original_language_code,
            regenrated: true,
            video_dacast_id : video_dacast_id,
            chapter_id: chapter_id,
            single_chapter: false,
        };

        
        try {
            const response = await dispatch(uploadDacastVideos(data)).unwrap();
            if(response.success){
                let message = `Created ( ${response.title})`;
                setLoadingMessage(false);
                toast.success(message);
            }
        } catch (error) {
            toast.error(error);
            setLoadingMessage(false);
        }
        
   }


   
    if(filteredChapters && filteredChapters.length > 0){
        return (
            <>
            {loadingMessage && ( <Downdloader text={loadingMessage} />)}
            <input type="text" className="search-box" value={searchTerm} placeholder="Search by filename" onChange={handleChange} />
            {filteredChapters.map((chapter) => (
                <div key={chapter.id} className="chapter-container">
                    {chapter.transcribed === 0 ? (
                        <h3>File {chapter.audio_name} is not transcribed</h3>
                    ) : (
                        <>
                        <h3 style={{ marginBottom: '20px' }}>
                            Chapter: {chapter && chapter.title ? chapter.title.replace(/_\d+$/, '') : ''} 
                        </h3>
                        <div className="sync-dacast-wrapp">
                            <input type="text" className="dacast-box" 
                            value={dacastID.filter(item => item.id === chapter.id)[0].dacast_id} 
                            data-id={chapter.id} 
                            placeholder="Dacast id" 
                            onChange={(e) => handleChangeDacast(e, chapter.id)} />

                            {dacastID.filter(item => item.id === chapter.id)[0].dacast_id && (
                                <a 
                                    style={{marginLeft:'10px', marginTop:'5px'}} 
                                    target={`_blank`} 
                                    href={`https://app.dacast.com/videos/${dacastID.filter(item => item.id === chapter.id)[0].dacast_id}/general`} 
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-external-link" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6"></path>
                                    <path d="M11 13l9 -9"></path>
                                    <path d="M15 4h5v5"></path>
                                    </svg>
                                </a>
                            )}
                           

                            
                        </div>
                        <div className="original-file-container">
                            <h3 className="actions-title underline">Original file</h3>
                            {chapter.filename ? (
                                <div  className="single-translation">
                                <span className="flex-center">
                                    <p className="language-title">
                                        {video.video_language} 
                                      
                                    </p>
                                </span>
                                <div className="translations-buttons-container">
                                    <div className="btn-actions ">

                                    {chapter.dacast_id?(dacast_subtitles?.filter(
                                        ds => 
                                            ds.id === (chapter.dacast_id )
                                            && 
                                            ( ds.subtitles?.filter(
                                                    sub => (sub['subtitle-language-short-name']?.toLowerCase()) == (video.original_language_code?.toLowerCase()=='es-es'?'es':video.original_language_code?.toLowerCase() )
                                                ).length 
                                            )
                                        ).length?' 🟢 dacast':'❌'):'🟠 '}

                                    </div>
                                    <div className="btn-actions modified">{new Date(chapter.updated_at).toLocaleDateString('en-GB')}</div>
                                    <button className='btn-actions' onClick={() => openSrt(chapter.filename, chapter.audio_link)}>
                                        <AiFillEdit style={{marginRight: '15px'}} size={20}/>EDIT / VIEW 
                                    </button>            
                                    <a className='btn-actions' rel="noreferrer" href={`${HOST_URL}video/file/download/${chapter.filename}`} ><AiOutlineCloudDownload style={{marginRight: '15px'}} size={20}/> DOWNLOAD</a>
                                    <button className='btn-actions' onClick={() => handleDelete(video.id, 'file', null, chapter.id, )}>
                                    <MdDeleteForever style={{marginRight: '15px'}} size={20}/>DELETE
                                    </button> 
                                </div>
                            </div>
                            ): (
                                <>
                                <p>Please upload a file:</p>
                                <FileUploader id_chapter={chapter.id} upload={'file'} videoId={video.id}/>
                                </>
                            )}
                        </div>
                        <div className="actions-container">
                            <div className="translation-file-container" >
                                <ViewTranslations originalFileName={chapter.filename} id={chapter.id}/>
                            </div>
                            <div className="translation-file-buttons-ext">
                                <Link
                                    to='/videos/translate' state={{translation_type: 'single', id_chapter: chapter.id, filename: chapter.filename, masterclass: video.masterclass, original_language_code: video.original_language_code, language_codes: languageCodes, audio: chapter.audio_link}} className='btn btn-reverse create-translation'
                                    >
                                    ADD TRANSLATION
                                </Link>
                                {dacastID.filter(item => item.id === chapter.id)[0].dacast_id && (
                                    <>
                                     <button  className='btn btn-reverse create-translation' onClick={(e) => 
                                        handleSyncToDacast(e, 
                                            dacastID.filter(item => item.id === chapter.id)[0].dacast_id,
                                            chapter.id,
                                            chapter.title
                                            )} >
                                            Upload subtitles to Dacast
                                        </button>
                                    </>
                                )}
                               
                                <button  className='btn btn-danger create-translation' onClick={(e) => 
                                handleSyncToDacastRegenerate(e, 
                                    dacastID.filter(item => item.id === chapter.id)[0].dacast_id,
                                    chapter.id,
                                    chapter.title
                                    )} >
                                    Regenerate subtitles
                                </button>

                            </div>
                        </div>
                        </>
                    )}
                    <MdDeleteForever onClick={() => handleDelete(video.id, 'chapter', null, chapter.id)} className="btn-delete" size={30}/>
                </div>
                
            ))}
            </>
        )
    } else if (!video_chapters || video_chapters.length < 1) {
        return (
            <h1 style={{margin: '40px 0'}}>This Masterclass has no chapters yet.</h1>
        )
    }

}

export default SingleChapter
