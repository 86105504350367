import  { useEffect, useState, useRef } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import {setTranslatedLanguages} from '../../../features/video_team/languagesfeat/languageSlice';
import {getChapterTranslations} from "../../../features/video_team/translations/translationsSlice";
import Downloader from "../../../components/shared/Loaders/Downloader";
import {sendChaptersToWordpress} from '../../../features/marketing_team/resumesSlice'


function SendChaptersToWordpress({video_chapters}) {


    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Sending chapters...'); // Inicializa el estado

    const { video } = useSelector((state) => state.videos);
    const onSubmitChapters = (e) => {
      e.preventDefault()
      console.log(e);
      
      if(video_chapters){
        setLoading(true);
        const ids = video_chapters.map((chapter) => chapter.id)

        const data = {
          ids,
          id_language: video.id_language,
          video_id: video.id,
        }

        console.log(data);

      
        dispatch(sendChaptersToWordpress(data))
        .unwrap()
            .then((response) => {
                setLoading(false);
            })
            .catch((error) => {
                console.log(error)
                setLoading(false);
            })


      }

    }
    if(!video.dacast_id){
      return false
    }

    if (isLoading){
      return (
          <Downloader text={loadingMessage} />
        )
    }

    

    return (
        <div className="create-resume-button-container">
            <button style={{marginTop: '20px', width: '100%'}} className="btn btn-reverse" onClick={(e) =>onSubmitChapters(e) }>SEND CHAPTERS TO AULART</button>
        </div>
    )
}

export default SendChaptersToWordpress