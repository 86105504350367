import TrailersTable from '../../components/trailer/TrailersTable'



function TrailerHome() {
 

    return (
      <TrailersTable />
    )
}

export default TrailerHome