import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import languageService from './languageService'

const initialState = {
    languages: {},
    settingLanguages: [],
    codeLanguages: [],
    translatedLanguages: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}


// get selected video languages

export const getLanguages = createAsyncThunk('languages/getLanguages', async (_, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await languageService.getLanguages(token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})


// get all video languages

export const getAllLanguages = createAsyncThunk('languages/getAllLanguages', async (_, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await languageService.getAllLanguages(token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})
// get languages by code

export const getLanguageByCode = createAsyncThunk('languages/getLanguageByCode', async (data, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await languageService.getLanguageByCode(data, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})
// get languages by id

export const getLanguageById = createAsyncThunk('languages/getLanguageById', async (data, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await languageService.getLanguageById(data, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})

// display new language

export const displayNewLanguage = createAsyncThunk('languages/displayNewLanguage', async (id, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await languageService.displayNewLanguage(id, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})


// hide language

export const hideLanguage = createAsyncThunk('languages/hideLanguage', async (id, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await languageService.hideLanguage(id, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})

// hide language

export const updateMasterclassLangue = createAsyncThunk('languages/updateMasterclassLangue', async (data, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await languageService.updateMasterclassLangue(data, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})




export const languageSlice = createSlice ({
    name: 'languages',
    initialState,
    reducers: {
        setTranslatedLanguages: (state, action) => {
            state.translatedLanguages = action.payload;
        },
        reset: (state) =>{
          state.isLoading = false
          state.isError = false
          state.message = ''
          state.isSuccess = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLanguages.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getLanguages.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.languages = action.payload

            })
            .addCase(getLanguages.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getAllLanguages.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllLanguages.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.settingLanguages = action.payload

            })
            .addCase(getAllLanguages.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getLanguageByCode.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getLanguageByCode.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.codeLanguages = action.payload

            })
            .addCase(getLanguageByCode.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getLanguageById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getLanguageById.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.idLanguages = action.payload

            })
            .addCase(getLanguageById.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(displayNewLanguage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(displayNewLanguage.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.languages = action.payload

            })
            .addCase(displayNewLanguage.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(hideLanguage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(hideLanguage.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.settingLanguages = action.payload

            })
            .addCase(hideLanguage.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updateMasterclassLangue.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateMasterclassLangue.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true

            })
            .addCase(updateMasterclassLangue.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })
            
    }
})

export const {reset, setTranslatedLanguages} = languageSlice.actions

export default languageSlice.reducer