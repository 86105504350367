import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import authService from './authService'

// Get user from localstorage

const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
    user: user ? user : null,
    users: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
    dark_mode: false,
}

// Register new user
export const register = createAsyncThunk(
    'auth/register',
    async (user, thunkAPI) => {
      try {
        return await authService.register(user)
      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
      }
    }
  )


// Login new user
export const login = createAsyncThunk(
    'auth/login',
    async (user, thunkAPI) => {
        
      try {
        return await authService.login(user)
      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

// reset password
export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async (data, thunkAPI) => {
        
      try {
        return await authService.resetPassword(data)
      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

// reset passwordWith token 
export const resetPasswordWithToken = createAsyncThunk(
    'auth/resetPasswordWithToken',
    async (data, thunkAPI) => {
        
      try {
        return await authService.resetPasswordWithToken(data)
      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  // update user data (password or email or username)

export const updateUser = createAsyncThunk(
    'auth/updateUser',
    async (data, thunkAPI) => {
        
      try {
        const token = thunkAPI.getState().auth.user.token;
        return await authService.updateUser(data, token)
      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

// Logout user

export const logout = createAction("auth/logout", () => {
  authService.logout();
  return {};
});

// get users


export const getUsers = createAsyncThunk('extras/getUsers', async (_, thunkAPI) => {
  try{
      const token = thunkAPI.getState().auth.user.token;
      return await authService.getUsers(token)
  } catch(error){
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message)
  }
})


// delete user

export const deleteUser = createAsyncThunk('extras/deleteUser', async (userId, thunkAPI) => {
  try{
      const token = thunkAPI.getState().auth.user.token;
      return await authService.deleteUser(userId, token)
  } catch(error){
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message)
  }
})





export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      setDarkModeRedux: (state, action) =>{
        state.dark_mode = action.payload
      },
      reset: (state) =>{
        state.isLoading = false
        state.isError = false
        state.message = ''
        state.isSuccess = false
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(register.pending, (state) => {
          state.isLoading = true
        })
        .addCase(register.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.user = action.payload       
        })
        .addCase(register.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
          state.user = null
        })
        .addCase(login.pending, (state) => {
          state.isLoading = false
        })
        .addCase(login.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload 
        })
        .addCase(login.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.user = null
        })
        .addCase(updateUser.pending, (state) => {
          state.isLoading = false
        })
        .addCase(updateUser.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload 
        })
        .addCase(updateUser.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.user = null
        })
        .addCase(logout.type, (state) => {
          state.user = null
        })
        .addCase(getUsers.pending, (state) => {
          state.isLoading = false
        })
        .addCase(getUsers.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.users = action.payload 
        })
        .addCase(getUsers.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.users = null
        })
        .addCase(deleteUser.pending, (state) => {
          state.isLoading = false
        })
        .addCase(deleteUser.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            // state.users = action.payload 
        })
        .addCase(deleteUser.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(resetPassword.pending, (state) => {
          state.isLoading = false
        })
        .addCase(resetPassword.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload
        })
        .addCase(resetPassword.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(resetPasswordWithToken.pending, (state) => {
          state.isLoading = false
        })
        .addCase(resetPasswordWithToken.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload
        })
        .addCase(resetPasswordWithToken.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
    },
  })
  
  export const {reset, setDarkModeRedux} = authSlice.actions
  export default authSlice.reducer