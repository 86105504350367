import React, { useState } from "react";
import { FaBars, FaTimes, FaRegFileVideo } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link} from 'react-router-dom'
import './LateralNavBar.css';
import {FiSettings} from 'react-icons/fi'
import {FiUsers} from 'react-icons/fi';
import {AiOutlineEye} from 'react-icons/ai';
import {BsArrowReturnRight, BsInstagram} from 'react-icons/bs';
import {HiOutlineTicket} from 'react-icons/hi2';
import {BiMoviePlay} from 'react-icons/bi';



const LateralNavBar = () => {

const { user} = useSelector((state) => state.auth);

  const [isOpen, setIsOpen] = useState(false);
  const [isSublinkOpen, setIsSublinkOpen] = useState(false);
  const [isSublinkOpen2, setIsSublinkOpen2] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseOver = () => {
    setIsSublinkOpen(true);
  }

  const handleMouseLeave = () => {
    setIsSublinkOpen(false);
  }

  const handleMouseOver2 = () => {
    setIsSublinkOpen2(true);
  }

  const handleMouseLeave2 = () => {
    setIsSublinkOpen2(false);
  }

  
  if (user){
    return (
        <>
          <nav className={`lateral-nav ${isOpen ? "open" : ""}`}>
            <ul className="nav-links">
            <li onClick={handleToggle}>
                {user.role === 'video' ? (
                  <Link to ="videos/home" className="icons-nav"> 
                    <AiOutlineEye /> Masterclasses
                  </Link>
                ) : user.role === 'marketing' ? (
                  <Link to ="marketing/home" className="icons-nav"> 
                    <AiOutlineEye /> Masterclasses
                  </Link>
                ) : null}
            </li>
            
                {user.role === 'admin' ? (
                  <>
                 
                    <li onClick={handleToggle}>
                      <Link to ="videos/home" className="icons-nav"> 
                        <BiMoviePlay /> Video Home
                      </Link>
                    </li>
                    <li onClick={handleToggle}>
                      <Link to ="marketing/home" className="icons-nav"> 
                        <HiOutlineTicket /> Marketing Home
                      </Link>
                    </li>
                    <li onClick={handleToggle}>
                      <Link to ="trailers" className="icons-nav"> 
                        <FaRegFileVideo /> Trailers Home
                      </Link>
                    </li>
                  </>
                ) : null}
            
        
            
            {user.role === 'video' ? (
              <></>
            ) : user.role === 'marketing' ? (
              <>
                
                <li onClick={handleToggle}>
                  <div className="icons-nav" onMouseOver={handleMouseOver2} onMouseLeave={handleMouseLeave2}> 
                      {isSublinkOpen ?  <BsInstagram /> : <BsInstagram/>} Social Media
                    </div>
                      <div className={`sub-link ${isSublinkOpen2 ? 'open-sub-link' : ''}`}  onMouseOver={handleMouseOver2}>
                        <Link to ="/marketing/social-media/copies" className="icons-nav"> 
                          <BsArrowReturnRight className="icon-animation"/> Copies
                        </Link>
                      </div>
                    <div className={`sub-link ${isSublinkOpen2 ? 'open-sub-link' : ''}`} onMouseOver={handleMouseOver2}>
                      <a href ="https://bio.aulart.com/login" className="icons-nav"> 
                        <BsArrowReturnRight className="icon-animation"/> Linktree
                      </a>
                  </div>
              </li>
              </>
            ): user.role === 'admin' ? (
                <>
                  
                  <li onClick={handleToggle}>
                  <div className="icons-nav" onMouseOver={handleMouseOver2} onMouseLeave={handleMouseLeave2}> 
                      {isSublinkOpen ?  <BsInstagram /> : <BsInstagram/>} Social Media
                    </div>
                    <div className={`sub-link ${isSublinkOpen2 ? 'open-sub-link' : ''}`}  onMouseOver={handleMouseOver2}>
                        <Link to ="/marketing/social-media/blogs" className="icons-nav"> 
                          <BsArrowReturnRight className="icon-animation"/> Blog
                        </Link>
                      </div>
                      <div className={`sub-link ${isSublinkOpen2 ? 'open-sub-link' : ''}`}  onMouseOver={handleMouseOver2}>
                        <Link to ="/marketing/social-media/copies" className="icons-nav"> 
                          <BsArrowReturnRight className="icon-animation"/> Captions
                        </Link>
                      </div>
                    <div className={`sub-link ${isSublinkOpen2 ? 'open-sub-link' : ''}`} onMouseOver={handleMouseOver2}>
                      <a href ="https://bio.aulart.com/login" className="icons-nav"> 
                        <BsArrowReturnRight className="icon-animation"/> Linktree
                      </a>
                  </div>
              </li>
                </>
            ) : null}
            <li onClick={handleToggle}>
                <Link to='/users' className="icons-nav">
                  <FiUsers /> Aulart Users
                </Link>
            </li>
            <li onClick={handleToggle}>
                <div className="icons-nav" onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}> 
                    {isSublinkOpen ?  <FiSettings /> : <FiSettings/>} Settings
                  </div>
                  {(user.role === 'video' || user.role === 'admin') && (
                    <div className={`sub-link ${isSublinkOpen ? 'open-sub-link' : ''}`}  onMouseOver={handleMouseOver}>
                      <Link to ="/videos/settings/languages" className="icons-nav"> 
                        <BsArrowReturnRight className="icon-animation"/> Languages
                      </Link>
                    </div>
                  )}
                  <div className={`sub-link ${isSublinkOpen ? 'open-sub-link' : ''}`} onMouseOver={handleMouseOver}>
                    <Link to ="/settings/user" className="icons-nav"> 
                      <BsArrowReturnRight className="icon-animation"/> Profile
                    </Link>
                </div>
            </li>
            </ul>
          </nav>
          <div className={`overlay ${isOpen ? "open" : ""}`} onClick={handleToggle}></div>
          <div className={`toggle-button-wrapper ${isOpen ? "open" : ""}`}>
            <div className="toggle-button" onClick={handleToggle}>
              {isOpen ? <FaTimes /> : <FaBars className="open-icon"/>}
            </div>
          </div>
        </>
      )
  } else {
    return (
        null
    )
  }
};

export default LateralNavBar;
