import BlogsTable from '../../../components/marketing/shared/BlogsTable'



function BlogsHome() {
 

    return (
     <BlogsTable/>
    )
}

export default BlogsHome 