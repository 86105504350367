import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import videosService from './videosService'

const initialState = {
    video: {},
    user_videos: [],
    videos: [],
    chapters: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
    dacast_subtitles: [],
}

// create a new video
export const uploadManyVideoFiles = createAsyncThunk('videos/uploadManyVideoFiles', async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await videosService.uploadManyVideoFiles(data, token);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });

  

export const createVideo = createAsyncThunk('videos/createVideo', async (data, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await videosService.createVideo(data, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})
export const createVideoCopy = createAsyncThunk('videos/createVideoCopy', async (data, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await videosService.createVideoCopy(data, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})

export const createVideoBlog= createAsyncThunk('videos/createVideoBlog', async (data, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await videosService.createVideoBlog(data, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})
// get all videos

export const getVideos = createAsyncThunk('videos/getVideos', async (_, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await videosService.getVideos(token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})

export const getVideoCopies = createAsyncThunk('videos/getVideoCopies', async (_, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await videosService.getVideoCopies(token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})
export const getVideoBlogs = createAsyncThunk('videos/getVideoBlogs', async (_, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await videosService.getVideoBlogs(token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})


// get one video
export const getSingleVideo = createAsyncThunk('videos/getSingleVideo', async (videoId, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await videosService.getSingleVideo(videoId, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})

export const getCounterFromVideo = createAsyncThunk('videos/getCounterFromVideo', async (videoId, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await videosService.getCounterFromVideo(videoId, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})

export const getSingleVideoCopy = createAsyncThunk('videos/getSingleVideoCopy', async (videoId, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await videosService.getSingleVideoCopy(videoId, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})

export const getSingleVideoBlog = createAsyncThunk('videos/getSingleVideoBlog', async (videoId, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await videosService.getSingleVideoBlog(videoId, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})


// delete a full masterclass
export const deleteVideo = createAsyncThunk('videos/deleteVideo', async (videoId, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await videosService.deleteVideo(videoId, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})
// delete single chapter
export const deleteChapter = createAsyncThunk('videos/deleteChapter', async (id, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await videosService.deleteChapter(id, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})



export const getUserVideos = createAsyncThunk('videos/getUserVideos', async (id, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await videosService.getUserVideos(id, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})


// update masterclassa title

export const updateTitle = createAsyncThunk('videos/updateTitle', async (data, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await videosService.updateTitle(data, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})


export const updatePrompt = createAsyncThunk('videos/updatePrompt', async (data, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await videosService.updatePrompt(data, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})

export const updateDacastID = createAsyncThunk('videos/updateDacastID', async (data, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await videosService.updateDacastID(data, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})

export const updateStrToDacast = createAsyncThunk('videos/updateStrToDacast', async (data, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await videosService.updateStrToDacast(data, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})

export const reUpdateSingleStrToDacast = createAsyncThunk('videos/reUpdateSingleStrToDacast', async (data, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await videosService.reUpdateSingleStrToDacast(data, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})

export const getDacastSubtitles = createAsyncThunk('videos/getDacastSubtitles', async (data, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await videosService.getDacastSubtitles(data, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})


export const syncChapterFromDacast = createAsyncThunk('videos/syncChapter', async (data, thunkAPI) => {
    try{
        
        const token = thunkAPI.getState().auth.user.token;
        return await videosService.syncChapter(data, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})


export const videosSlice = createSlice ({
    name: 'videos',
    initialState,
    reducers: {        
        reset: (state) =>{
          state.isLoading = false
          state.isError = false
          state.message = ''
          state.isSuccess = false
        },
        setSubtitles: (state, action) =>{
            state.dacast_subtitles = action.payload;
        },
        setNullVideo: (state) =>{
          state.video = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createVideo.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createVideo.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.video = action.payload

            })
            .addCase(createVideo.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getVideos.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getVideos.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.videos = action.payload
                state.message = ''

            })
            .addCase(getVideos.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getSingleVideo.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSingleVideo.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.video = action.payload
                state.message = ''

            })
            .addCase(getSingleVideo.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteVideo.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteVideo.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.videos = state.videos.filter((video) => video.id !== action.payload.id)
                state.message = ''
            })
            .addCase(deleteVideo.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteChapter.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteChapter.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.message = ''
            })
            .addCase(deleteChapter.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getUserVideos.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getUserVideos.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user_videos = action.payload
                state.message = ''
            })
            .addCase(getUserVideos.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updateTitle.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateTitle.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                // state.video = action.payload
                state.message = ''
            })
            .addCase(updateTitle.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updateStrToDacast.pending, (state) => {
                state.isLoading = false
            })
            .addCase(updateStrToDacast.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                // state.video = action.payload
                state.message = ''
            })
            .addCase(updateStrToDacast.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(uploadManyVideoFiles.pending, (state) => {
                state.isLoading = true
            })
            .addCase(uploadManyVideoFiles.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                // state.video = action.payload
                state.message = ''
            })
            .addCase(uploadManyVideoFiles.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })
            
            .addCase(getDacastSubtitles.fulfilled, (state, action) => {
                
                state.dacast_subtitles = action.payload;
                
            });
            
    }
})

export const {setChapters, reset, setNullVideo, setSubtitles} = videosSlice.actions

export default videosSlice.reducer