import  { useEffect, useState, useRef } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import {getLanguageByCode, setTranslatedLanguages} from '../../../features/video_team/languagesfeat/languageSlice';
import { toast } from 'react-toastify';
import {AiOutlineCloudDownload, AiOutlineCloseCircle} from 'react-icons/ai';
import {getChapterTranslations} from "../../../features/video_team/translations/translationsSlice";
import {resumeAllOriginalChapters} from '../../../features/marketing_team/resumesSlice'
import Downloader from "../../../components/shared/Loaders/Downloader";
import { useNavigate } from 'react-router-dom';


function GenerateResumesButton({video_chapters}) {


    const dispatch = useDispatch();
    const [languageData, setLanguageData] = useState([]);
    const [displaySelect, setDisplaySelect] = useState(false);
    const [selectedOption, setSelectedOption] = useState(false);
    const [displayPopUp, setDisplayPopUp] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Generating summaries...'); // Inicializa el estado
    let navigate = useNavigate();

    const { video } = useSelector((state) => state.videos);

    useEffect(() => {
        if (video) {

          dispatch(getChapterTranslations(video.id))
            .unwrap()
            .then((results) => {
              if (results.length > 0) {
                const newLanguageData = results.map((result) => {
                  return {
                    id: result.id_language,
                    code: result.language_code,
                    title: result.language_title,
                  };
                });
                const filteredData = newLanguageData.filter((data) => data.id !== null)
                const uniqueArray = filteredData.reduce((accumulator, obj) => {
                  const found = accumulator.find(item => item.code === obj.code);
                  if (!found) {
                    accumulator.push(obj);
                  }
                  return accumulator;
                }, []);
                const obj_original_language = {
                  id:  video.id_language,
                  code: video.original_language_code,
                  title: video.video_language,
                }
                uniqueArray.push(obj_original_language)
                setLanguageData(uniqueArray)
                dispatch(setTranslatedLanguages(newLanguageData));
              }

              
            });
            
            
        }
      }, [video]);

  
     // FORM TO CREEATE RESUME
     const [formData, setFormData] = useState({

          instructor: video.instructor?video.instructor:'',
          title: video.title_r?video.title_r:'',
          prompt: video.prompt_r?video.prompt_r:'',
          keywords: video.keywords?video.keywords:''

      },video)

      const { instructor, title, prompt, keywords} = formData;

      const onChange = (e) => {
          setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
          }))
      }

      const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true);
        if(video_chapters){
          const filenames = video_chapters.map((chapter) => chapter.filename)
          const ids = video_chapters.map((chapter) => chapter.id)

          const data = {
            instructor,
            title,
            prompt,
            keywords,
            filenames,
            ids,
            id_language: video.id_language,
            video_chapters,
            video_id: video.id,
          }

          // toast.info('Resuming file')
          setDisplayPopUp(false)
          dispatch(resumeAllOriginalChapters(data))
          .unwrap()
              .then((response) => {
                  toast.success('File resumed')
                  setLoading(false);
                  navigate(0);
              })
              .catch((error) => {
                  console.log(error)
                  toast.error(error)
                  setLoading(false);
              })


        }
    }


    const formRef = useRef(null);


    // get users selected language
    const handleChange = (e) => {
        e.preventDefault();
        setSelectedOption(e.target.value);    
    };

    if (isLoading){
      return (
          <Downloader text={loadingMessage} />
        )
    }

    return (
        <div className="create-resume-button-container">
            <button style={{marginTop: '20px', width: '100%'}} className="btn btn-reverse" onClick={() => setDisplayPopUp(true)}>RESUME ALL FILES</button>
            {/* <button style={{marginTop: '20px'}} className="btn btn-reverse" onClick={() => setDisplaySelect(!displaySelect)}>{!displaySelect ? 'RESUME CHAPTERS' : 'HIDE'}</button>
            {displaySelect && (
                <>
                    <select style={{marginTop: '20px'}} placeholder="Select Language" onChange={handleChange} value={selectedOption}>
                        <option>Select a language</option>
                        {languageData.map(language => (
                            <option key={language.id} value={language.id}>{language.title}</option>
                        ))}
                    </select>
                    {selectedOption && (
                        <a className='btn' rel="noreferrer" target="_blank">CREATE RESUME</a>
                    )}
                </>
            )} */}
            {displayPopUp && (
              <>
              
              <form className="resume-all-form" onSubmit={onSubmit} ref={formRef}>
              <AiOutlineCloseCircle onClick={() => setDisplayPopUp(false)} size={23} className='close-form'/>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control'
                  id='title'
                  name='title'
                  value={title}
                  onChange={onChange}
                  placeholder='Enter masterclass title'
                  required
                />
              </div>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control'
                  id='instructor'
                  name='instructor'
                  value={instructor}
                  onChange={onChange}
                  placeholder='Enter instructor name'
                  required
                />
              </div>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control'
                  id='keywords'
                  name='keywords'
                  value={keywords}
                  onChange={onChange}
                  placeholder='Add Keywords'
                  required
                />
              </div>
              <div className='form-group'>
                <textarea
                  type='text'
                  className='form-control'
                  id='prompt'
                  name='prompt'
                  value={prompt}
                  onChange={onChange}
                  placeholder='Add your own prompt'
                />
              </div>                           
              <div className='form-group center-element'>
                <button  className='btn btn-block center-element'>Create</button>
              </div>
        </form>
        </>
            )}
        </div>
    )
}

export default GenerateResumesButton