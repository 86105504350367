import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { updateUser, reset } from '../../features/auth/authSlice';






function SettingsUser() {

  const dispatch = useDispatch();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [password2, setPassword2] = useState();
  const [email, setEmail] = useState();

  const onChange = (e) => {
    const { name, value } = e.target;
    if(name === 'username'){
        setUsername(value)
    } else if (name === 'email'){
        setEmail(value)
    } else if (name === 'password'){
        setPassword(value)
    } else if (name === 'password2'){
        setPassword2(value)
    }
  }
  

  const onSubmit = (e) => {
    e.preventDefault();

    if (password !== '' && password2 !== '' && (password !== password2)) {
        toast.error('Passwords do not match')
    } 
    const data = {
        email,
        username,
        password,
    }

    dispatch(updateUser(data))
        .unwrap()
        .then(() => {
          toast.success(`Succesfully updated`)
        })
        .catch((error) => {
          toast.error(error)
        })
        .then(() => {
            dispatch(reset());
        });

    setUsername('');
    setPassword('');
    setPassword2('');
    setEmail('');

  }

 
      return (
        <div className="user-settings">
        <h1>User Settings</h1>
          <section className='forms-settings'>
            <div>
            <h2>Change username</h2>
            <form onSubmit={onSubmit}>
              <div className='form-group'>
                <input
                  type='username'
                  className='form-control'
                  id='username'
                  name='username'
                  value={username}
                  onChange={onChange}
                  placeholder='Enter new username'
                  required
                />
              </div>             
              <div className='form-group '>
                <button className='btn btn-block '>submit</button>
              </div>
            </form>
            </div>
            <div>
            <h2>Change email</h2>
            <form onSubmit={onSubmit}>
              <div className='form-group'>
                <input
                  type='email'
                  className='form-control'
                  id='email'
                  name='email'
                  value={email}
                  onChange={onChange}
                  placeholder='Enter new email'
                  required
                />
              </div>             
              <div className='form-group'>
                <button className='btn btn-block'>submit</button>
              </div>
            </form>
            </div>
            <div>
            <h2>Change password</h2>
            <form onSubmit={onSubmit}>
                <div className='form-group'>
                <input
                type='password'
                className='form-control'
                id='password'
                name='password'
                value={password}
                onChange={onChange}
                placeholder='Enter password'
                required
                />
            </div>
          <div className='form-group'>
            <input
              type='password'
              className='form-control'
              id='password2'
              name='password2'
              value={password2}
              onChange={onChange}
              placeholder='Confirm password'
              required
            />
          </div>            
              <div className='form-group '>
                <button className='btn btn-block '>submit</button>
              </div>
            </form>
            </div>
          </section>
        </div>
          
          
       )

} 
    


export default SettingsUser