import  { useEffect, useState, useRef } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { useParams } from 'react-router-dom';
import {getLandingContent, setWordpressProductId, updateLandingContent, sendDataToWordpress} from '../../features/marketing_team/resumesSlice'
import {AiOutlineCloseCircle} from 'react-icons/ai';
import { toast } from 'react-toastify';


function LandingContent() {
    const { landing_content } = useSelector((state) => state.resume);
    const { video } = useSelector((state) => state.videos);

    const dispatch = useDispatch();
    const { id } = useParams();

    const [isEditing, setIsEditing] = useState(false);
    const [editedField, setEditedField] = useState('');
    const [editedFieldContent, setEditedFieldContent] = useState('');
    const [displayInput, setDisplayInput] = useState(false);
    const [editedSubtitle, setEditedSubtitle] = useState("");
    const [productId, setProductId] = useState("");



    useEffect(() => {
        console.log(video);
        dispatch(getLandingContent(id ? id : video.id));
        setEditedSubtitle(landing_content.subtitle_filename); 
    }, [])

    const onChange = (e) => {
        setProductId(e.target.value)
    }

    const onChangeContent = (e) => {
        setEditedFieldContent(e.target.value);
    }


    const setNewId = () => {
        const data = {
            id_video: video.id ? video.id : landing_content.id_video,
            id_product: productId,
        }
        dispatch(setWordpressProductId(data))
            .unwrap()
            .then(() => {
                toast.success('Id set correctly')
                setDisplayInput(false)
                dispatch(getLandingContent(id ? id : video.id));
            })
            .catch((error) => {
                toast.error(error)
            });
    }

    
    const sendJsonData = () => {
        const data = {
            hero_subtitle: landing_content.subtitle_filename,
            trailer_title: landing_content.trailer_title_filename,
            product_id: landing_content.product_id,
            dacast_id: video.dacast_id,
            bullet_points: [
              {
                title: landing_content.bulletpoint_one_title,
                subtitle: landing_content.bulletpoint_one_subtitle
              },
              {
                title: landing_content.bulletpoint_two_title,
                subtitle: landing_content.bulletpoint_two_subtitle,
              },
              {
                title: landing_content.bulletpoint_three_title,
                subtitle: landing_content.bulletpoint_three_subtitle
              }
            ]
          }
       
        dispatch(sendDataToWordpress(data))
        .unwrap()
            .then((response) => {
                toast.success('Well done! Data is uploaded')
                console.log(response)
            })
            .catch((error) => {
                toast.error(error)
            });
        
          
    }


    const onSubmit = (e) => {
        e.preventDefault();
        const data = {
            editedField,
            editedFieldContent,
            id_video: video.id ? video.id : landing_content.id_video,
        }

        dispatch(updateLandingContent(data))
        .unwrap()
        .then(() => {
            toast.success('Content saved')
            setIsEditing(false)
            dispatch(getLandingContent(id ? id : video.id));
        })
        .catch((error) => {
            toast.error(error)
        });
    }

    if(!video.id){
        return (
            <>
                <p>Video not found, go back and come back</p>
            </>
        )
    }

    return (
      <div className="landing-content-container">
        
        <div className="landing-content-header">
            <div>
            </div>
            <div className="content-landing-header-right">
                <button className="btn btn-reverse" style={{width: '100%'}} onClick={sendJsonData}>SEND DATA</button>
                <button onClick={() => setDisplayInput(!displayInput)} className="btn btn-reverse" style={{width: '100%'}}>ADD ID</button>
                {video.dacast_id && (
                    <>
                    <p>Dacast playlist id available:<br/><small>{video.dacast_id}</small></p>
                    </>
                )}
                {displayInput && (
                    <>
                        <input onChange={onChange} type="text" className="input-form-center" style={{maxWidth: '100%'}} placeholder='add product id'></input> 
                        <button onClick={setNewId} type="submit" className="btn btn-reverse btn-title" style={{width: '100%'}}>SAVE</button>
                    </>
                )}
            </div>
        </div>
        <h3 style={{marginTop: '70px'}} className='h3-landing-content'>Hero subtitle</h3>
        <div className="titles-container container-hover">
        <p onClick={() => {
            setIsEditing(true);
            setEditedFieldContent(landing_content.subtitle_filename);
            setEditedField('subtitle_filename');
            }}>
                {landing_content.subtitle_filename}
        </p>
        </div>
        <h3 className='h3-landing-content'>Trailer title</h3>
        <div className="titles-container container-hover">
            <p onClick={() => {
                setIsEditing(true);
                setEditedFieldContent(landing_content.trailer_title_filename);
                setEditedField('trailer_title_filename');
                }}
            >{landing_content.trailer_title_filename}</p>
        </div>
        <h3 className='h3-landing-content'>Bulletpoints</h3>
        <div className="bulletpoints-container">
            <div className="bulletpoint container-hover">
                <h3 onClick={() => {
                        setIsEditing(true);
                        setEditedFieldContent(landing_content.bulletpoint_one_title);
                        setEditedField('bulletpoint_one_title');
                        }} className="bulletpoint-title"
                    >{landing_content.bulletpoint_one_title}
                </h3>
                <p className="bulletpoint-content"
                    onClick={() => {
                    setIsEditing(true);
                    setEditedFieldContent(landing_content.bulletpoint_one_subtitle);
                    setEditedField('bulletpoint_one_subtitle');
                    }}
                >{landing_content.bulletpoint_one_subtitle}</p>
            </div>
            <div className="bulletpoint container-hover">
                <h3 className="bulletpoint-title"
                    onClick={() => {
                    setIsEditing(true);
                    setEditedFieldContent(landing_content.bulletpoint_two_title);
                    setEditedField('bulletpoint_two_title');
                    }}
                >{landing_content.bulletpoint_two_title}</h3>
                <p className="bulletpoint-content"
                    onClick={() => {
                    setIsEditing(true);
                    setEditedFieldContent(landing_content.bulletpoint_two_subtitle);
                    setEditedField('bulletpoint_two_subtitle');
                    }}
                >{landing_content.bulletpoint_two_subtitle}</p>
            </div>
            <div className="bulletpoint container-hover">
                <h3 className="bulletpoint-title"
                    onClick={() => {
                    setIsEditing(true);
                    setEditedFieldContent(landing_content.bulletpoint_three_title);
                    setEditedField('bulletpoint_three_title');
                    }}
                >{landing_content.bulletpoint_three_title}</h3>
                <p className="bulletpoint-content"
                    onClick={() => {
                    setIsEditing(true);
                    setEditedFieldContent(landing_content.bulletpoint_three_subtitle);
                    setEditedField('bulletpoint_three_subtitle');
                    }}
                >{landing_content.bulletpoint_three_subtitle}</p>
            </div>
        </div>
        <h3 className='h3-landing-content'>Global summary</h3>
        <div className="global-summary-container container-hover"
            onClick={() => {
            setIsEditing(true);
            setEditedFieldContent(landing_content.global_summary_filename);
            setEditedField('global_summary_filename');
            }}>
            <p className="global-summary">{landing_content.global_summary_filename}</p>
        </div>
        {isEditing && (
            <form className="resume-all-form-content" onSubmit={onSubmit}>
            <AiOutlineCloseCircle onClick={() => setIsEditing(false)} size={23} className='close-form'/>
            <div className='form-group'>
    
            <div className='form-group'>
            <textarea
                type='text'
                className='form-control textarea'
                id='update'
                name='update'
                value={editedFieldContent}
                onChange={onChangeContent}
                placeholder='Change content'
                required
            />
            </div>
            </div>                           
            <div className='form-group center-element'>
            <button  className='btn btn-block center-element'>Change</button>
            </div>
    </form>
        )}
       
      </div>
    )
}

export default LandingContent


