import { useEffect, useRef, useState } from "react";
import {useSelector, useDispatch} from 'react-redux'
import AceEditor, {highlight} from "react-ace";
import { useLocation} from "react-router-dom";
import { displayOriginalFile } from "../../../features/video_team/files/fileSlice";
import { toast } from 'react-toastify';
import {updateResume, generateNewResume, reset, updateResumeWithNewContent} from "../../../features/marketing_team/resumesSlice";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools"
import Spinner from '../../shared/Loaders/Spinner'




function ResumeEditor() {

  const dispatch = useDispatch();
  const location = useLocation();
  const editorRef = useRef();
  const editorRef2 = useRef()

  // const [scrollPosition, setScrollPosition] = useState(-1);
  const [file, setFile] = useState('');
  const [newResumeContent, setNewResumeContent] = useState('');
  const [createNewResume, setCreateNewResume] = useState(false);



  const { srtFile, srtOriginalFile, isLoading} = useSelector((state) => state.files);
  const { video } = useSelector((state) => state.videos);
  const { new_resume} = useSelector((state) => state.resume);
  const filename = location.state.filename;
  const id = location.state.id;

     // FORM TO CREEATE RESUME
  const [formData, setFormData] = useState({
    prompt: '',
    keywords: '',
  })


  const {prompt, keywords} = formData;

  const onChange = (e) => {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }))
  }


  useEffect(() => {
    if(new_resume){
      const newSrt = new_resume.replace(/\n/g, "")
      setNewResumeContent(newSrt)
    }
  }, [new_resume ])





  // se contetn of srt file

  useEffect(() => {
    const newSrt = srtFile.replace(/\n/g, "")
    setFile(newSrt)
  }, [srtFile])

  

  // useEffect(() => {
  //   if (scrollPosition >= 0 && editorRef.current) {
  //     editorRef.current.editor.renderer.scrollToY(scrollPosition)
  //   }
  // }, [editorRef.current]);


  
// save the changes on the srt file
  const handleSave = () => {
    const newFile = new File([editorRef.current.editor.getValue()], filename, { type: "text/plain" });
    dispatch(updateResume({ file: newFile, filename }))
    .unwrap()
    .then(() => {
      toast.success('File Changed Successfully')
      // dispatch(reset())
    })
    .catch((error) => {
      toast.error(error)
    })
    .then(() => {
      // dispatch(reset());
    });
  }



  const newResume = () => {
    const content = newResumeContent !== '' ? newResumeContent : editorRef.current.editor.getValue()
    const data = {
      content,
      filename,
      id_language: video.id_language,
      id_chapter: id,
      prompt,
      keywords,
    }

  
    toast.info('Generating new resume')
    setCreateNewResume(false)
    setFormData({ prompt: '', keywords: '' })
    dispatch(generateNewResume(data))
    .unwrap()
      .then((response)=> {
        toast.success('Resume generated')
        setNewResumeContent(response.content)
      })
      .catch((error) => {
        toast.error('Cant generated resume. Please try again.')
      })
  }

  const saveNewResume = () => {
    const content = editorRef2.current.editor.getValue()
    const data = {
      content,
      filename,
    }
    dispatch(updateResumeWithNewContent(data))
    .unwrap()
      .then((response)=> {
        toast.success('Resume generated')
        setFile(newResumeContent)
        setNewResumeContent('')        
      })
      .catch((error) => {
        toast.error('Cant save resume. Please try again.')
      })
  }


  // on unmount dispatch reset
  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);






  if(isLoading){
    return (
      <Spinner/>
    )
  }

  if(JSON.stringify(srtFile) === '{}'){
    return (
      <h1>No video found please go back and try again</h1>
      )
  } else {
    return (
      <>
       
      <div className="editor-container editor-resume">
        <div className="editor-block">
        <button className='btn btn-primary margin-top' onClick={() => setCreateNewResume(!createNewResume)}>Generate New Resume</button>
        {createNewResume && (
          <>
          <div className='form-group'>
            <textarea
            type='text'
            className='form-control'
            style={{width: '30%'}}
            id='prompt'
            name='prompt'
            value={prompt}
            onChange={onChange}
            placeholder='Enter a new prompt'
          />
        </div>
          <div className='form-group'>
            <input
            type='text'
            className='form-control'
            style={{width: '30%'}}
            id='keywords'
            name='keywords'
            value={keywords}
            onChange={onChange}
            placeholder='Enter your keywords'
          />
        </div>

        <button className='btn btn-reverse' onClick={newResume}>Create</button>
        </>
        )}
        <h2 style={{margin: '30px 0px'}}>ORIGINAL</h2>
        <AceEditor
          mode="text"
          theme="github"
          value={file}
          onChange={(value) => setFile(value)}
          ref={editorRef}      
          wrapEnabled={true}
          setOptions={{
            showLineNumbers: false,   // Habilita el ajuste de línea
            indentedSoftWrap: false,
            behavioursEnabled: false,
            showPrintMargin: false,
        }}
          name="ace-editor"
          width="100%"
          height="500px"
          editorProps={{$blockScrolling: Infinity}}
        />
        <button className='btn btn-primary margin-top' onClick={handleSave}>Save Changes</button>
      </div>
      
        {newResumeContent !== '' && (
          <div className="editor-block">
          <button className='btn btn-primary margin-top' onClick={saveNewResume}> SAVE NEW RESUME</button>
          <h2 style={{margin: '30px 0px'}}>New Resume</h2>
            <AceEditor
              mode="text"
              theme="github"
              value={newResumeContent}
              ref={editorRef2} 
              wrapEnabled={true}
              setOptions={{
                showLineNumbers: false,   // Habilita el ajuste de línea
                indentedSoftWrap: false,
                behavioursEnabled: false,
                showPrintMargin: false,
            }}
              name="ace-editor"
              width="100%"
              height="500px"
              editorProps={{$blockScrolling: Infinity}}          />
          </div>
        )}
      </div>
      </>
    )
  }


}

export default ResumeEditor;