import { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'
import { toast } from 'react-toastify';
import { useParams, Link } from 'react-router-dom';
import {getVideoBlogs, reset} from '../../../features/video_team/videosUpload/videosSlice';
import Spinner from '../../shared/Loaders/Spinner';
import DataTable from 'react-data-table-component'





function BlogsTable() {

    const dispatch = useDispatch();

    const { id } = useParams();

    const [videoData, setVideoData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredVideoData, setFilteredVideoData] = useState([]);
    

    const { isLoading } = useSelector((state) => state.videos);


    useEffect(() => {
    
   

        dispatch(getVideoBlogs())
        .unwrap()
        .then((videos) => {
            setVideoData(videos)
            setFilteredVideoData(videos)
            dispatch(reset());
        })
        .catch((error) => {
            toast.error(error)
        })
        .then(() => {
            dispatch(reset());
        });
      
    }, [dispatch, id]);


    // define datatables columns

    const columns = [
        {
            name: 'BLOG VIDEO',
            selector: row => row.masterclass,
        },
        {
            name: 'ORIGINAL LANGUAGE',
            selector: row => row.video_language,
        },
        {
            name: 'AUDIO',
            selector: row => (<>
            {row.path && (
                <>mp3</>
            )}
            </>),
        },
        {
            name: 'SRT',
            selector: row => (<>
            {row.filename && (
                <Link
                  className="btn btn-reverse"
                    to={row.filename}
                    download
                >
                  Download
                </Link>
            )}
            </>),
        },
        {
            name: 'CREATED BY',
            selector: row => row.username,
        },     
        {
          name: 'VIEW',
          cell: (row) => (
            <>
              {row.language_codes ? (
                <Link
                  className="btn btn-reverse"
                    to={`/marketing/social-media/blog/${row.id}`}
                    state={{ chapterCount: row.chapter_count, resumesCount: row.resumes_count }}
                >
                  Open
                </Link>
              ) : (
                <Link
                  className="btn btn-reverse"
                    to={`/marketing/social-media/blog/${row.id}`}
                    state={{ chapterCount: row.chapter_count, resumesCount: row.resumes_count }}
                >
                  Open
                </Link>
              )}
            </>
          ),
        },
        
    ];



    // search for videos

  const handleChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filteredData = videoData.filter((video) => {
      return video.masterclass.toLowerCase().includes(searchTerm) || video.video_language.toLowerCase().includes(searchTerm) || video.username.toLowerCase().includes(searchTerm);
    });
    setFilteredVideoData(filteredData);
    setSearchTerm(searchTerm);
};



    if(isLoading){
        return (
            <>
                <Spinner/>
            </>
        )
    }

    return (
     
      <div>
      <Link to="/marketing/social-media/blogs/create/" style={{margin:'auto', width:'fit-content'}} className="btn btn-reverse btn-title">Create new blog caption</Link> 
      <input type="text" className="search-box" value={searchTerm} placeholder="Search" onChange={handleChange} />

      <DataTable
            columns={columns}
            data={filteredVideoData}
            searchable
            pagination
        />
          <div>
      </div>
    </div>
       
    )
}

export default BlogsTable