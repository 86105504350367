import axios from 'axios'
import { HOST_URL } from '../../../config';


const API_URL = `${HOST_URL}video/videos/`
const API_URL_TRANSCRIPTIONS = `${HOST_URL}transcriptions/`


// Post create video
const createVideo = async (data, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.post(API_URL +'create', data, config)
  
    return response.data
  }

  const createVideoCopy = async (data, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.post(API_URL +'create_video_copy', data, config)
  
    return response.data
  }

  const createVideoBlog = async (data, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.post(API_URL +'create_video_blog', data, config)
  
    return response.data
  }



  const uploadManyVideoFiles = async (data, token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        timeout: 2700000
      }

      const formData = new FormData();
      formData.append('id_video', data.id_video);
      formData.append('language_code', data.language_code);
      formData.append('prompt', data.prompt);
      formData.append('transcriber', data.transcriber);
      formData.append('output', data.output);
      formData.append('is_blog', data.is_blog?true:false);
      formData.append('is_global', data.is_global?true:false);
      
      if(data.data_sign){
        formData.append('data_sign', JSON.stringify(data.data_sign));
      }else{
        data.chaptersArray.forEach((chapter) => {
          formData.append('video_file', chapter.video_file);
        });
      }
      
      


    const response = await axios.post(API_URL_TRANSCRIPTIONS + 'cloud_video', formData, config);
    return response.data;
};






// Get all videos
const getVideos = async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.get(API_URL +'view', config)
  
    return response.data
  }

  const getVideoCopies = async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.get(API_URL +'view_copies', config)
  
    return response.data
  }

  const getVideoBlogs = async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.get(API_URL +'view_blogs', config)
  
    return response.data
  }


// Get ONE video
const getSingleVideo = async (videoId, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.get(API_URL +'view/' + videoId, config)
  
    return response.data
  }


  const getCounterFromVideo= async (videoId, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.get(API_URL +'counter_summary/' + videoId, config)
  
    return response.data
  }


  const getSingleVideoCopy = async (videoId, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.get(API_URL +'view_copy/' + videoId, config)
  
    return response.data
  }


  const getSingleVideoBlog = async (videoId, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.get(API_URL +'view_blogs/' + videoId, config)
  
    return response.data
  }
// Get user videos

const getUserVideos = async (id, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.get(API_URL +'view/user/' + id, config)
  
    return response.data
  }


// DELETE ONE video and all its corresponding files and translations
const deleteVideo = async (videoId, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.delete(API_URL +'delete/' + videoId, config)
  
    return response.data
  }
// DELETE ONE chapter and all its corresponding files and translations
const deleteChapter = async (id, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await axios.delete(API_URL +'chapter/' + id, config)
  
    return response.data
  }

// Dupdate masterclass title
const updateTitle = async (data, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const data_update = {
      title: data.title
    }

    const response = await axios.put(API_URL +'update/' + data.id, data_update, config)
  
    return response.data
  }
  const updatePrompt = async (data, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const data_update = {
      prompt: data.prompt
    }

    const response = await axios.put(API_URL +'updateprompt/' + data.id, data_update, config)
  
    return response.data
  }
  

  const updateDacastID = async (data, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const data_update = {
      dacast_id: data.dacast_id
    }

    const response = await axios.put(API_URL +'update_dacast_id/' + data.id, data_update, config)
  
    return response.data
  }


  const updateStrToDacast = async (data, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const data_update = {
      dacast_id: data.dacast_id
    }

    const response = await axios.put(API_URL +'update_str_to_dacast/'+ data.id , data_update, config)
  
    return response.data
  }


  const reUpdateSingleStrToDacast = async (data, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const data_update = {
      dacast_id: data.dacast_id
    }

    const response = await axios.put(API_URL +'reupdate_single_str_to_dacast/'+ data.id , data_update, config)
  
    return response.data
  }

  

  const getDacastSubtitles = async (data, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const data_update = {
      dacast_id: data.dacast_id
    }

    const response = await axios.post(API_URL +'get_dacast_subtitles' , data_update, config)
  
    return response.data
  }

  const syncChapter = async (data, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
   
    const response = await axios.get(API_URL +'sync_chapter/'+ data.video_id , config)
  
    return response.data
  }

  

const videosService = {
    createVideo,
    getVideos,
    getVideoCopies,
    getSingleVideo,
    getCounterFromVideo,
    getSingleVideoCopy,
    deleteVideo,
    getUserVideos,
    deleteChapter,
    updateTitle,
    updatePrompt,
    updateDacastID,
    updateStrToDacast,
    reUpdateSingleStrToDacast,
    getDacastSubtitles,
    syncChapter,
    uploadManyVideoFiles,
    createVideoCopy,
    getVideoBlogs,
    createVideoBlog,
    getSingleVideoBlog
  }
  
  export default videosService