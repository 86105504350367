import { useDispatch } from 'react-redux';
import {deleteFile, reset} from "../features/video_team/files/fileSlice";
import {deleteVideo, reset as resetVideo, deleteChapter} from "../features/video_team/videosUpload/videosSlice";
import {deleteTranslation, reset as resetTranslation} from "../features/video_team/translations/translationsSlice";
import {getChapterTranslations} from "../features/video_team/translations/translationsSlice";
import { getSingleVideo} from "../features/video_team/videosUpload/videosSlice";
import {getChaptersByVideoId} from '../features/video_team/chapters/chaptersSlice';
import { useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';


const useDelete = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDelete = (videoId, deleteType, singleVideoId = null, chapter_id = null) => {
    let confirmMsg = '';
    let deleteFunc = null;
    let deleteArg = null;
    let id = null;

    const data = {
        id: chapter_id
    }

    switch (deleteType) {
      case 'video':
        confirmMsg = 'Deleting this masterclass will delete all its corresponding files and translations?';
        deleteFunc = deleteVideo;
        deleteArg = videoId;
        break;
      case 'file':
        confirmMsg = 'Are you sure you want to delete?';
        deleteFunc = deleteFile;
        deleteArg = data;
        id = videoId;
        break;
      case 'translation':
        confirmMsg = 'Are you sure you want to delete this file?';
        deleteFunc = deleteTranslation;
        deleteArg = videoId;
        id = singleVideoId;
        break;
      case 'chapter':
        confirmMsg = 'Deleting this chapter will delete all of its related files and translation';
        deleteFunc = deleteChapter;
        deleteArg = chapter_id;
        id = videoId;
        break;
      default:
        throw new Error(`Invalid delete type: ${deleteType}`);
    }

    const result = window.confirm(confirmMsg);
    if (result) {
            dispatch(deleteFunc(deleteArg))
                .then(() => {
                if (deleteType === 'file' || deleteType === 'chapter') {
                  console.log('hey')
                    dispatch(getChaptersByVideoId(id))       
                } else if (deleteType === 'translation'){
                    dispatch(getSingleVideo(id))
                    dispatch(getChapterTranslations(id))
                } else {
                    navigate('/videos/home')
                }
                    toast.success('Deleted successfully');
                })
                .catch((error) => {
                    toast.error(error);
                })
                .then(() => {
                  if (deleteType === 'file') {
                    dispatch(reset());
                  } else if (deleteType === 'video'){
                    dispatch(resetVideo());
                  } else if (deleteType === 'translation'){
                    dispatch(resetTranslation());
                  }
                    
                });
    }
  };

  return { handleDelete };
};

export default useDelete;
