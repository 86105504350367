import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {uploadDacastVideos, getChaptersFromPlaylistDacast, reset} from '../../../features/video_team/audioFeatures/audioSlice';
import {Navigate,useNavigate} from 'react-router-dom'
import { toast } from 'react-toastify';
import { getSingleVideo, setChapters} from "../../../features/video_team/videosUpload/videosSlice";
import { setLoadMessage } from "../../../features/video_team/audioFeatures/audioSlice";
import CustomToast from "../../../components/shared/CustomToast";
import Downdloader from '../../shared/Loaders/Downloader'





function DacastVideoUploader({videoId, language_code, prompt, output, transcriber, dacast_id, single=false}) {

    const [id, setId] = useState(null);
    const [fileError, setFileError] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Inicializando...'); // Inicializa el estado
    const { isLoading, isError, message } = useSelector((state) => state.transcriptions);
    const dispatch = useDispatch();

    useEffect(() => {
        setId(videoId);  
    },[videoId])


   
    

    const handleUpload = async ()=> {
        let allFilesValid = true;
        let confirm = true;

        if(transcriber === 'whisper'){
            confirm = false;
            confirm = window.confirm('Please make sure the prompt is in the same language than the audio files');
        }


        if(!dacast_id){
            setFileError(true);
            return false
        }

        if(confirm){
            
            const chapterArray = [];
            const chapter = {
                dacast_id: dacast_id,
            };
            chapterArray.push(chapter);

            if(!allFilesValid){
                setFileError(!allFilesValid);
                return false
            } else {

                const data = {
                    prompt: prompt,
                    transcriber: transcriber,
                    id_video: id,
                    output: output,
                    chaptersArray: chapterArray,
                    language_code: language_code,
                    
                };

              
                //get playlist chapters
                let playlists = false;
                let counter = 1;

                if(single){
                    setLoadingMessage('Inizializing transcription...');
                    const datasend = {
                        prompt: prompt,
                        transcriber: transcriber,
                        id_video: id,
                        output: output,
                        chaptersArray: chapterArray,
                        language_code: language_code,
                        video_dacast_id : dacast_id,
                        single_chapter: true
                    }

                    try {
                        const response = await dispatch(uploadDacastVideos(datasend)).unwrap();
                        console.log(uploadDacastVideos);
                      
                    } catch (error) {
                        toast.error(error);
                    }
                    
                }else{
                    setLoadingMessage('Getting dacast chapters ...');
                    await dispatch(getChaptersFromPlaylistDacast(data))
                        .unwrap()
                        .then((response) => {
                            playlists = response;
                        })
                        .catch((error) => {
                            toast.error(error)
                        
                        });


                    if(!playlists){
                        toast.error('playlist not found')
                        return false
                    }   
                

                    setLoadingMessage('Inizializing transcriptions...');
                  
                    for(const video_dacast_id of playlists){

                        const datasend = {
                            prompt: prompt,
                            transcriber: transcriber,
                            id_video: id,
                            output: output,
                            chaptersArray: chapterArray,
                            language_code: language_code,
                            video_dacast_id : video_dacast_id,
                            single_chapter: false,
                        }

                        try {
                            const response = await dispatch(uploadDacastVideos(datasend)).unwrap();
                            if(response.success){
                                let message = `Created ${counter}/${playlists.length} ( ${video_dacast_id.title})`;
                            
                                counter ++;
                                if(counter !== playlists.length){
                                    message = message+'\nCreating next ...';
                                }

                                setLoadingMessage(message);

                            }
                        } catch (error) {
                            toast.error(error);
                        }
                    }
                }

                if(single){
                    window.location.href =(`/videos/video/${id}`)
                }
                else{
                    if(counter === playlists.length+1){
                        dispatch(getSingleVideo(id))
                        dispatch(reset())
                        toast.success(
                            <CustomToast
                                message="Your files are ready. Click to view!"
                                linkTo={`/videos/video/${id}`}
                            />,
                            { 
                                autoClose: 7000,
                                onClose: () => Navigate(`/videos/video/${id}`)
                            }
                        )
                        window.location.href =(`/videos/video/${id}`)
                        
                    }
                }


                
                 
            }  
            
            setFileError(false)

        }
    };


    if(isLoading){
        return (
            <Downdloader text={loadingMessage} />
        )
    }

    
    return (
        <div className='uploader-container'>

           
            <button className='btn' onClick={handleUpload} disabled={fileError}>Create Audio</button>
            {fileError && (
                <span style={{color: 'red', marginTop: '10px'}}>Dacast id is empty</span>
            )}
            {isLoading && <div>Uploading...</div>}
            {isError && <div>Error: {message}</div>}
        </div>
    );
}

export default DacastVideoUploader