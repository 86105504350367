import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uploadFile, uploadSrtFileToChapter, createSingleChapter, reset}  from '../../../features/video_team/files/fileSlice'
import {useNavigate} from 'react-router-dom'
import { toast } from 'react-toastify';
import { getSingleVideo} from "../../../features/video_team/videosUpload/videosSlice";
import {setChapters, getChaptersByVideoId} from '../../../features/video_team/chapters/chaptersSlice';



function FileUploader({videoId, id_chapter, upload}) {

    const [files, setFiles] = useState([]);
    const [file, setFile] = useState([]);
    const [id, setId] = useState(null);
    const [idChapter, setIdChapter] = useState(null);
    const { isLoading, isError, message } = useSelector((state) => state.files);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if(videoId){
            setId(videoId);
        }
        if(id_chapter){
            setIdChapter(id_chapter);
        }
        
    },[videoId, idChapter])
     
    const handleFileChange = (event) => {
        if(id && upload === 'files'){
            const newFiles = event.target.files;
            setFiles((prevFiles) => {
            const updatedFiles = prevFiles ? [...prevFiles] : [];
            for (let i = 0; i < newFiles.length; i++) {
                updatedFiles.push(newFiles[i]);
            }
            return updatedFiles;
            });
        } else if(idChapter && upload === 'file'){
            setFile(event.target.files[0])
        } else if (id && upload === 'chapter'){
            setFile(event.target.files[0])
        }
      };
      

    const handleRemoveFile = (indexToRemove) => {
        setFiles(prevFiles => {
            const newFiles = [...prevFiles];
            newFiles.splice(indexToRemove, 1);
            return newFiles;
        });
    };
    

    const handleUpload = ()=> {
        if (files && upload === 'files') {
            const chapterArray = [];
            for (let i = 0; i < files.length; i++) {
              const chapter = {
                title: files[i].name.replace(/['"]/g, ""),
                srtFile: new File([files[i]], files[i].name.replace(/['"]/g, ""), { type: "application/x-subrip" }),
              };
              chapterArray.push(chapter);
            }

        
            const data = {
              id_video: id,
              chaptersArray: chapterArray,
            };

            dispatch(uploadFile(data))
                .unwrap()
                .then(() => {
                    // dispatch(reset())
                    toast.success('File Uploaded Successfully')
                    navigate(`/videos/video/${id}`)
                    dispatch(getSingleVideo(id))
                    .unwrap()
                    .then((video_restult) => {
                        dispatch(getChaptersByVideoId(id))
                    })
                })
                .catch((error) => {
                    toast.error(error)
                })
                .then(() => {
                    dispatch(reset());
                });
        } else if (file && upload === 'file') {

            const srtFile = new File([file], file.name.replace(/['"]/g, ""), { type: "application/x-subrip" })

    
        const data = {
            id_chapter: idChapter,
            id_video: id,
            srtFile: srtFile,
        };


        dispatch(uploadSrtFileToChapter(data))
            .unwrap()
            .then(() => {
                // dispatch(reset())
                toast.success('File Uploaded Successfully')
                dispatch(getSingleVideo(id))
                    .unwrap()
                    .then((video_restult) => {
                        dispatch(getChaptersByVideoId(id))
                    })
                
            })
            .catch((error) => {
                toast.error(error)
            })
            .then(() => {
                dispatch(reset());
            });
        } else if (file && upload === 'chapter') {

            const srtFile = new File([file], file.name.replace(/['"]/g, ""), { type: "application/x-subrip" })
            const data = {
                id_video: id,
                srtFile: srtFile,
            };

            dispatch(createSingleChapter(data))
                .unwrap()
                .then(() => {
                    // dispatch(reset())
                    toast.success('Files Uploaded Successfully')
                    dispatch(getSingleVideo(id))
                        .unwrap()
                        .then((video_restult) => {
                            dispatch(getChaptersByVideoId(id))
                        })
                    
                })
                .catch((error) => {
                    toast.error(error)
                })
                .then(() => {
                    dispatch(reset());
                });
        }
};

    const inputText = files.length ? 'Add more files' : 'Add files';

    
    return (
        <div className='uploader-container'>
                <input type="file" multiple onChange={handleFileChange} />
           
            <div>
            {files &&
                Array.from(files).map((file, index) => (
                <div className='file-uploader-actions' key={index}>
                    <p>{file.name}</p>
                    <button className='btn' onClick={() => handleRemoveFile(index)}>Remove</button>
                </div>
                ))}
            </div>
            <button className='btn' onClick={handleUpload}>SAVE Files</button>
            {isLoading && <div>Uploading...</div>}
            {isError && <div>Error: {message}</div>}
        </div>
    );
}

export default FileUploader