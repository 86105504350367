import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import extraService from './extraService'

const initialState = {
    variables: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

// create a new post

// get User posts

export const getVariables = createAsyncThunk('extras/getVariables', async (_, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await extraService.getVariables(token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})





export const extraSlice = createSlice ({
    name: 'extras',
    initialState,
    reducers: {
        reset: (state) =>{
          state.isLoading = false
          state.isError = false
          state.message = ''
          state.isSuccess = false
        },
      },
    extraReducers: (builder) => {
        builder
            .addCase(getVariables.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getVariables.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.variables = action.payload

            })
            .addCase(getVariables.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })
            
    }
})

export const {reset} = extraSlice.actions

export default extraSlice.reducer