import axios from 'axios'
import { HOST_URL } from '../../../config';


const API_URL = `${HOST_URL}video/languages/`



// Get languages 
const getLanguages = async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.get(API_URL, config)
  
    return response.data
  }


// send all languages to fornend, so users can show which ones to display in settings
const getAllLanguages = async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.get(API_URL + "all", config)
  
    return response.data
  }

// send languages by code

const getLanguageByCode = async (data, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const codesParam = encodeURIComponent(JSON.stringify(data));
    const response = await axios.get(API_URL + "code/" + codesParam, config)
  
    return response.data
  }

// add a new language 
const displayNewLanguage = async (id, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const data = {
      id: id,
    }
  
    const response = await axios.put(API_URL, data, config)
  
    return response.data
  }


// hide language 
const hideLanguage = async (id, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const data = {
      id: id,
    }
  
    const response = await axios.put(API_URL + '/hide', data, config)
  
    return response.data
  }


// upadate masterclass original language 
const updateMasterclassLangue = async (data, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.put(API_URL + '/change', data, config)
  
    return response.data
  }


const languageService = {
    getLanguages,
    getAllLanguages,
    displayNewLanguage,
    hideLanguage,
    getLanguageByCode,
    updateMasterclassLangue,
  }
  
  export default languageService