import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import extraReducer from '../features/video_team/extras/extraSlice'
import languagesReducer from '../features/video_team/languagesfeat/languageSlice'
import videosReducer from '../features/video_team/videosUpload/videosSlice'
import fileReducer from '../features/video_team/files/fileSlice';
import driveReducer from '../features/video_team/drive/driveSlice';
import translationsReducer from '../features/video_team/translations/translationsSlice'
import audioReducer from '../features/video_team/audioFeatures/audioSlice';
import chaptersReducer from '../features/video_team/chapters/chaptersSlice';
import linktreReducer from '../features/marketing_team/linktreeSlice';
import resumesReducer from '../features/marketing_team/resumesSlice';
import trailerReducer from '../features/trailer/trailerSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    extras: extraReducer,
    languages: languagesReducer,
    videos: videosReducer,
    files: fileReducer,
    drive: driveReducer,
    translations: translationsReducer, 
    transcriptions: audioReducer,
    chapters: chaptersReducer,
    linktree: linktreReducer,
    resume: resumesReducer,
    trailer: trailerReducer,
  },
});
