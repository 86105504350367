import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaUser } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { register, reset } from '../../features/auth/authSlice';

function Register() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);

    const [formData, setFormData] = useState({
        email: '',
        username: '',
        role: '',
        password: '',
        password2: '',
    });

    const { email, username, role, password, password2 } = formData;

    // execute the following actions when any of the state changes
    useEffect(() =>{

        // if theres an error display message from the error
        if(isError){
            toast.error(message)
        }

        // if registration isSuccesfull navigate to home page
        if(isSuccess || user){
            navigate('/')
        }

        dispatch(reset());

    }, [isError, isSuccess, user, message, navigate, dispatch])



    const onChange = (e) => {
        setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }))
      };

      const onSubmit = (e) => {
        e.preventDefault();
        if (password !== password2) {
            toast.error('Passwords do not match')
        } else {
         const userData = {
            email,
            username,
            role,
            password,
         }
         dispatch(register(userData))
        .unwrap()
        .then((user) => {
          toast.success(`Registered new user - ${user.username}`)
          if(user.role === 'video' || user.role === 'admin'){
            navigate('/videos/home')
          } else if (user.role === 'marketing' || user.role === 'admin'){
            navigate('/marketing/home')
          }
        })
        .catch((error) => {
          toast.error(error)
        })
        .then(() => {
            dispatch(reset());
        });
      }
    
      
      }


    return (
        <>
      <section className='heading'>
        <h1>
          <FaUser /> Register
        </h1>
      </section>

      <section className='form'>
        <form onSubmit={onSubmit}>
          <div className='form-group'>
            <input
              type='email'
              className='form-control'
              id='email'
              name='email'
              value={email}
              onChange={onChange}
              placeholder='Enter your email'
              required
            />
          </div>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              id='username'
              name='username'
              value={username}
              onChange={onChange}
              placeholder='Enter your username'
              required
            />
          </div>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              id='role'
              name='role'
              value={role}
              onChange={onChange}
              placeholder='Add role'
              required
            />
          </div>
          <div className='form-group'>
            <input
              type='password'
              className='form-control'
              id='password'
              name='password'
              value={password}
              onChange={onChange}
              placeholder='Enter password'
              required
            />
          </div>
          <div className='form-group'>
            <input
              type='password'
              className='form-control'
              id='password2'
              name='password2'
              value={password2}
              onChange={onChange}
              placeholder='Confirm password'
              required
            />
          </div>
          <div className='form-group'>
            <button className='btn btn-block'>Submit</button>
          </div>
        </form>
      </section>
    </>
       
    )
}

export default Register