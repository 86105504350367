import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import driveService from './driveService';

const initialState = {
  url: {},
  access_tokens: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

// accessDrive
export const accessDrive = createAsyncThunk('drive/accessDrive', async (userId, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await driveService.accessDrive(userId, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
// download file
export const downloadAudio = createAsyncThunk('drive/downloadAudio', async (fileId, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await driveService.downloadAudio(fileId, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});




export const driveSlice = createSlice({
  name: 'drive',
  initialState,
  reducers: {
    setAccessTokens: (state, action) => {
      state.access_tokens = action.payload;
    },
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.message = '';
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(accessDrive.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(accessDrive.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (action.payload.tokens) {
          state.access_tokens = action.payload;
        } else if (action.payload.url) {
          state.url = action.payload.url;
        }
      })
      .addCase(accessDrive.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.access_tokens = null;
        state.url = null;
      })
      .addCase(downloadAudio.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(downloadAudio.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        
      })
      .addCase(downloadAudio.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.access_tokens = null;
        state.url = null;
      })
    
  },
});

export const {setAccessTokens, reset} = driveSlice.actions
export default driveSlice.reducer;
