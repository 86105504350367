import { useEffect, useRef, useState } from "react";
import {useSelector, useDispatch} from 'react-redux'
import AceEditor, {highlight} from "react-ace";
import { useLocation} from "react-router-dom";
import { displayOriginalFile } from "../../../features/video_team/files/fileSlice";
import { toast } from 'react-toastify';
import { updateFile, reset } from "../../../features/video_team/files/fileSlice";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools"
import Spinner from '../../shared/Loaders/Spinner'
import AudioPlayer from "./AudioPlayer";
import {setAudioTime} from "../../../features/video_team/audioFeatures/audioSlice";




function FileEditorV2() {

  const dispatch = useDispatch();
  const location = useLocation();
  const editorRef = useRef();
  const editorRef2 = useRef(null)

  const [scrollPosition, setScrollPosition] = useState(-1);
  const [file, setFile] = useState('');
  const [currentTime, setCurrentTime] = useState('00:00:00');
  const [timeToSave, setTimeToSave] = useState(0);
  const [highlightedLines, setHighlightedLines] = useState([]);
  const [autoScroll, setAutoScroll] = useState(true);

  const [stringToHighlight, setStringToHighlight] = useState('');


  const { srtFile, srtOriginalFile, isLoading} = useSelector((state) => state.files);
  const filename = location.state.filename;
  const link = location.state.link;
  const originalFileName = location.state.originalFileName;


  

  useEffect(() => {
    if(originalFileName){
      dispatch(displayOriginalFile(originalFileName))
    }
  }, [originalFileName, dispatch])


  // check if in the srt file there is a string that equals the current timeof the audio player, save the index of that line
  useEffect(() => {
    if (file && file.includes(currentTime) && !originalFileName && autoScroll) {
      const lines = file.split("\n");
      const indexes = [];
      lines.forEach((line, index) => {
        if (line.includes(currentTime)) {
          indexes.push(index);
        }
      });
      const secondIndex = indexes.length >= 2 ? indexes[1] : indexes[0];
      setStringToHighlight(lines[secondIndex +1]);
    }
     
  }, [currentTime, file, autoScroll]);



 



  // auto scroll to the highlighted line and center item

  useEffect(() => {
    if (stringToHighlight) {
      if (editorRef && editorRef.current) {
        const editor = editorRef.current.editor;
        const allLines = editor.getSession().getDocument().getAllLines();
        const lineNumber = allLines.findIndex((line) => line.includes(stringToHighlight));
        
        if (lineNumber > 14) {
          const lineHeight = editor.renderer.lineHeight;
          const editorHeight = editor.renderer.$size.scrollerHeight;
          const visibleLines = Math.floor(editorHeight / lineHeight);
          const middleLine = Math.floor(visibleLines / 2);
          const yPosition = lineHeight * (lineNumber - middleLine);
          editor.renderer.scrollToY(yPosition);
        }
      }
      
      
      const lines = file.split("\n");
      const highlightedLines = [];
      lines.forEach((line, index) => {
        if (line.includes(stringToHighlight)) {
          const startIndex = line.indexOf(stringToHighlight);
          const endIndex = startIndex + stringToHighlight.length;
          const annotation = {
            row: index,
            column: startIndex,
            endRow: index,
            endColumn: endIndex,
            type: "text",
            text: stringToHighlight,
            className: "highlighted_ace"
          };
          highlightedLines.push(annotation);
        }
      });
      setHighlightedLines(highlightedLines);
    } 
  }, [stringToHighlight, file]);
  
  

// if no auto scroll set everything to false
  useEffect(() => {
  if(!autoScroll){
    setCurrentTime(null);
    setHighlightedLines(null)
    setStringToHighlight(null)
  }
  }, [autoScroll])


  // se contetn of srt file

  useEffect(() => {
    setFile(srtFile)
  }, [srtFile])

  

  useEffect(() => {
    if (scrollPosition >= 0 && editorRef.current) {
      editorRef.current.editor.renderer.scrollToY(scrollPosition)
    }
  }, [editorRef.current]);


  
// save the changes on the srt file
  const handleSave = () => {
    dispatch(setAudioTime(timeToSave));
    const newFile = new File([editorRef.current.editor.getValue()], filename, { type: "application/x-subrip" });
    dispatch(updateFile({ file: newFile, filename }))
    .unwrap()
    .then(() => {
      toast.success('File Changed Successfully')
      // dispatch(reset())
    })
    .catch((error) => {
      toast.error(error)
    })
    .then(() => {
      // dispatch(reset());
    });
  }


  // creat a parallel scrolling between original and translated file
  const handleScroll = (editor) => {
    const position = editor.renderer.getScrollTop();
    setScrollPosition(position);
      if (originalFileName && editorRef2.current) {
        editorRef2.current.editor.renderer.scrollToY(scrollPosition);
      }
  }


  // get current audio time from child component and the time to save when saving file

  function handleTimeUpdate(time, exactTime,) {
      setCurrentTime(time);
      setTimeToSave(exactTime)
  }

  // set save time to 0 on unount of component


  useEffect(() => {
    // do nothing
    // console.log('hey')

    return () => {
      dispatch(setAudioTime(timeToSave));
    };
  }, []);


  if(isLoading){
    return (
      <Spinner/>
    )
  }

  if(JSON.stringify(srtFile) === '{}'){
    return (
      <h1>No video found please go back and try again</h1>
      )
  } else {
    return (
      <>
        <h1>Original {filename ? filename.replace(/\.srt$/, '') : null}</h1>
        <div className="audio-player-container">
        {!originalFileName && (
          <button className="btn btn-reverse" onClick={() => setAutoScroll(!autoScroll)}>
            {autoScroll ? 'STOP AUTO SCROLL' : 'ACTIVATE AUTO SCROLL'}
          </button>
        )}
          {link && (
                  <AudioPlayer currentTimeSaved={currentTime} handleTimeUpdate={handleTimeUpdate} link={link}/>
          )}
        </div>

      <div className="editor-container">
        <div className="editor-block">
            {originalFileName ? (
              <h2>Translation</h2>
            ) : (
              <div className="editor-header">
                <h3>Original</h3>
              </div>
            )}
        <AceEditor
          mode="text"
          theme="github"
          value={file}
          onChange={(value) => setFile(value)}
          onScroll={handleScroll}
          ref={editorRef}       
          name="ace-editor"
          wrapEnabled={true}
          setOptions={{
            showLineNumbers: false,   // Habilita el ajuste de línea
            indentedSoftWrap: false,
            behavioursEnabled: false,
            showPrintMargin: false,
        }}
          width="100%"
          height="500px"
          editorProps={{$blockScrolling: Infinity}}
          annotations={highlightedLines}
        />
      <button className='btn btn-primary margin-top' onClick={handleSave}>Save Changes</button>
      </div>
      {(originalFileName && JSON.stringify(srtOriginalFile) !== '{}' )&& (
        <div className="editor-block">
        <h2>Original</h2>
          <AceEditor
            mode="text"
            theme="github"
            value={srtOriginalFile}
            // scrollTop={scrollPosition}
            ref={editorRef2} 
            name="ace-editor"
            width="100%"
             wrapEnabled={true}
              setOptions={{
                showLineNumbers: false,   // Habilita el ajuste de línea
                indentedSoftWrap: false,
                behavioursEnabled: false,
                showPrintMargin: false,
            }}
            height="500px"
            editorProps={{$blockScrolling: Infinity}}
            readOnly={true}
          />
        </div>
      )}
      </div>
      </>
    )
  }


}

export default FileEditorV2;