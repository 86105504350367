import  { useEffect, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import {getLanguageByCode, setTranslatedLanguages} from '../../../features/video_team/languagesfeat/languageSlice';
import { toast } from 'react-toastify';
import {AiOutlineCloudDownload} from 'react-icons/ai';
import {getChapterTranslations} from "../../../features/video_team/translations/translationsSlice";
import { HOST_URL } from '../../../config';







function DownloadFilesButton({ codes}) {

    const API_URL = `${HOST_URL}video/file/download/`


    const dispatch = useDispatch();
    const [languageData, setLanguageData] = useState([]);
    const [displaySelect, setDisplaySelect] = useState(false);
    const [selectedOption, setSelectedOption] = useState(false);


    const { video } = useSelector((state) => state.videos);

    useEffect(() => {
        if (video) {
          dispatch(getChapterTranslations(video.id))
            .unwrap()
            .then((results) => {
              if (results.length > 0) {
                const newLanguageData = results.map((result) => {
                  return {
                    id: result.id_language,
                    code: result.language_code,
                    title: result.language_title,
                  };
                });
                const filteredData = newLanguageData.filter((data) => data.id !== null)
                const uniqueArray = filteredData.reduce((accumulator, obj) => {
                  const found = accumulator.find(item => item.code === obj.code);
                  if (!found) {
                    accumulator.push(obj);
                  }
                  return accumulator;
                }, []);
                const obj_original_language = {
                  id:  video.id_language,
                  code: video.original_language_code,
                  title: video.video_language,
                }
                uniqueArray.push(obj_original_language)
                setLanguageData(uniqueArray)
                dispatch(setTranslatedLanguages(newLanguageData));
              }

              
            });
        }
      }, [video]);
  
      

    // get users selected language
    const handleChange = (e) => {
        e.preventDefault();
        setSelectedOption(e.target.value);
        
    };

  return (
    <>
    <button style={{marginTop: '20px'}} className="btn btn-reverse" onClick={() => setDisplaySelect(!displaySelect)}>{!displaySelect ? 'DOWNLOAD FILES' : 'HIDE'}</button>
    {displaySelect && (
        <>
            <select style={{marginTop: '20px'}} placeholder="Select Language" onChange={handleChange} value={selectedOption}>
                <option>Select a language</option>
                {languageData.map(language => (
                    <option key={language.id} value={language.id}>{language.title}</option>
                ))}
            </select>
            {selectedOption && (
                <a className='btn' rel="noreferrer" href={`${API_URL}${selectedOption}/${video.id}`} ><AiOutlineCloudDownload style={{marginRight: '15px'}} size={20}/> DOWNLOAD</a>
            )}
        </>
    )}
    </>
  );
}

export default DownloadFilesButton;