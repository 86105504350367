import axios from 'axios'
import { HOST_URL } from '../../config';



const API_URL = `${HOST_URL}marketing/resumes/`


// get all data
const getResumeByChapterId = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL+id, config)

  return response.data

}


// generate new resume
const generateNewResume = async (content, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL +'new', content, config)
  return response.data

}


// generate new resume
const resumeAllOriginalChapters = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL +'all', data, config)
  return response.data

}


// create link

const createResume = async (data, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await axios.post(API_URL, data, config);
    return response.data;
};


// update ResumeWithNewContent

const updateResumeWithNewContent = async (data, token) => {
  const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
console.log(data)
  const response = await axios.post(API_URL + 'update/new', data, config);
  return response.data;
};



// delete single  data
const deleteResume = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + id, config)

  return response.data
}


const updateResume = async (file, filename, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const formData = new FormData();
  formData.append('srtFile', file);
  const response = await axios.put(API_URL + "/" + filename, formData, config);
  return response.data;
};



// generate new resume
const generateLandingContent = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL +'resume_all/' + data.id_video, data, config)
  return response.data

}

// generate new resume
const getLandingContent = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL +'resume_all/' +id, config)
  return response.data

}


// set wordpress id
const setWordpressProductId = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL +'/resumes/product_id/', data, config)
  return response.data

}
// update landing content
const updateLandingContent = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.put(API_URL +'/resumes/update/', data, config)
  return response.data

}

// update landing content
const sendDataToWordpress = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL +'/resumes/create_product/', data, config)
  return response.data

}


// update landing content
const sendChaptersToWordpress = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL +'/resumes/create_chapters/', data, config)
  return response.data

}




const resumesService = {
    createResume,
    getResumeByChapterId,
    deleteResume,
    updateResume,
    generateNewResume,
    updateResumeWithNewContent,
    resumeAllOriginalChapters,
    generateLandingContent,
    getLandingContent,
    setWordpressProductId,
    updateLandingContent,
    sendDataToWordpress,
    sendChaptersToWordpress,
  }
  
  export default resumesService