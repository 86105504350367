import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import translationsService from './translationsService'

const initialState = {
    srtFile: {},
    translations: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

// create a new video


export const createTranslation = createAsyncThunk('translations/createTranslation', async (data, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await translationsService.createTranslation(data, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})
export const createMultipleTranslation = createAsyncThunk('translations/createMultipleTranslation', async (data, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await translationsService.createMultipleTranslation(data, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})

export const deleteTranslation = createAsyncThunk('videos/deleteTranslation', async (id, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await translationsService.deleteTranslation(id, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})

export const getChapterTranslations = createAsyncThunk('videos/getChapterTranslations', async (id, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await translationsService.getChapterTranslations(id, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})




export const translationsSlice = createSlice ({
    name: 'translations',
    initialState,
    reducers: {
        reset: (state) =>{
          state.isLoading = false
          state.isError = false
          state.message = ''
          state.isSuccess = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createTranslation.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createTranslation.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.srtFile = action.payload

            })
            .addCase(createTranslation.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(createMultipleTranslation.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createMultipleTranslation.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.message = action.payload

            })
            .addCase(createMultipleTranslation.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getChapterTranslations.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getChapterTranslations.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.translations = action.payload

            })
            .addCase(getChapterTranslations.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteTranslation.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteTranslation.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.srtFile = null;
            })
            .addCase(deleteTranslation.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })          
    }
})

export const {reset} = translationsSlice.actions

export default translationsSlice.reducer