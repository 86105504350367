import axios from 'axios'
import { HOST_URL } from '../../../config';


const API_URL = `${HOST_URL}chapters/`


// GET CHAPTERS BY VIDEO ID
const getChaptersByVideoId = async (id, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.get(API_URL +'/' + id, config)
  
    return response.data
  }


const chaptersService = {
    getChaptersByVideoId
  }
  
  export default chaptersService