import { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'
import { toast } from 'react-toastify';
import { Link, useParams, useNavigate, useLocation} from 'react-router-dom';
import Spinner from '../shared/Loaders/Spinner';
import {getSingleVideo, reset} from '../../features/video_team/videosUpload/videosSlice';
import {getChaptersByVideoId} from '../../features/video_team/chapters/chaptersSlice';





function CreateTrailer() {

    const { id } = useParams();
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { video_chapters} = useSelector((state) => state.chapters);
  const { video } = useSelector((state) => state.videos);


  // get all data from this on masterclass
  useEffect(() => {
    if(id){
        dispatch(getSingleVideo(id))
        .unwrap()
        .then(() => {
        //   do smth
        })
        .catch((error) => {
          toast.error(error)
          navigate('/trailer')
          // dispatch(reset());
      });
    }
   

  }, [dispatch, id, navigate])

  console.log("video", video)

  

    return (
     
       <h2 className='title'>Create Trailer for: {video.masterclass}</h2>
    )
}

export default CreateTrailer