import {Navigate, Outlet} from 'react-router-dom'
import {useRoleVideo} from '../../../hooks/useRoleVideo'
import Spinner from '../Loaders/Spinner'

  
const VideoTeamRoutes = () => {

    const {access, loggedIn, checkingStatus} = useRoleVideo();

    if(checkingStatus){
        return <Spinner/>
    }

    if(!loggedIn){
        return <Navigate to='/login'/>
    }

    return access ? <Outlet/> :  <Navigate to='/marketing/home'/>
}

export default VideoTeamRoutes