import { Link} from 'react-router-dom'
// import BIRDS from './vanta.birds.min.js'
import { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from 'react-redux'

function Home() {
  // const [vantaEffect, setVantaEffect] = useState(null);
  const { dark_mode} = useSelector((state) => state.auth);

  const myRef = useRef(null);
  // const colors = {
  //   black: 0x29292b,  // Converted to hexadecimal
  //   white: 0xffffff  // Converted to hexadecimal
  // }

  // useEffect(() => {
  //   // Initialize Vanta.js effect when the component mounts
  //   setVantaEffect(BIRDS({
  //     el: myRef.current,
  //     backgroundColor: 0x000000,  // Black background
  //     backgroundAlpha: 0.0,
  //     quantity: 2.1,
  //     color1: dark_mode ? colors.white : colors.black,
  //     color2: dark_mode ? colors.white : colors.black,
  //   }));

  //   return () => {
  //     // Destroy Vanta.js effect when the component unmounts
  //     if (vantaEffect) vantaEffect.destroy();
  //   }
  // }, []);

  // useEffect(() => {
  //   // If Vanta.js effect is initialized and dark_mode changes, re-initialize the effect
  //   if (vantaEffect) {
  //     vantaEffect.destroy();
  //     setVantaEffect(BIRDS({
  //       el: myRef.current,
  //       backgroundColor: 0x000000,
  //       backgroundAlpha: 0.0,
  //       quantity: 2.1,
  //       color1: dark_mode ? colors.white : colors.black,
  //       color2: dark_mode ? colors.white : colors.black,
  //     }));
  //   }
  // }, [dark_mode]);

  return (
    <>
      <div ref={myRef} className="absolute-home"></div>
      <div className="home-buttons">
        <Link to="/videos/home" className="btn2 ">VIDEO</Link>
        <Link to="/marketing/home" className="btn2 ">MARKETING</Link>
      </div>
    </>
  );
}

export default Home;
