import  { useEffect, useState } from 'react';
import {useDispatch} from 'react-redux'
import {transcribeAudios, reset} from '../../../features/video_team/audioFeatures/audioSlice';
import { getSingleVideo} from "../../../features/video_team/videosUpload/videosSlice";
import CustomToast from "../../../components/shared/CustomToast";

import { toast } from 'react-toastify';


function TranscribedAudioButton({transcribe_array, id_video}) {

    const dispatch = useDispatch();
    const [transcribe, setTranscribe] = useState(false);
    const [promptValue, setPrompt] = useState('');

    const onChangePrompt = (event) => {
        setPrompt(event.target.value)
    }

    const transcribeFiles = () => {
        if(transcribe_array && transcribe_array.length > 0 && promptValue !== ''){
            const data = {
                transcribe_array,
                promptValue,
                id_video,
            }
            setTranscribe(false)
            toast.info('Transcripting files')
            dispatch(transcribeAudios(data))   
            .unwrap()
            .then((response) => {
                dispatch(getSingleVideo(id_video))
                dispatch(reset())
                // Show the custom toast with the link
                toast.success(
                    <CustomToast
                        message="Your files are ready. Click to view!"
                        linkTo={`/videos/video/${id_video}`}
                    />,
                    { autoClose: 7000 }
                )

            })
            .catch((error) => {
                toast.error(error)
                dispatch(reset())
            })
            .then(() => {
                dispatch(reset());
            });     
        }
       
    }

    if(transcribe_array && transcribe_array.length > 0){
        return (
            <>
            <button style={{marginTop: '20px'}} onClick={(() => setTranscribe(!transcribe))} className="btn ">{transcribe ? 'CLOSE' : 'TRANSCRIBE'}</button>
                {transcribe && (
                    <>
                    <div className="prompt-container">
                        <textarea
                        type='text'
                        className='input-form textarea2'
                        id='text'
                        name='text'
                        value={promptValue}
                        onChange={onChangePrompt}
                        placeholder='Enter prompt'
                        required
                        />
                    </div>
                    <button onClick={(() => transcribeFiles())} className="btn btn-reverse">START</button>
                    </>
                )}
            </> 
            
        )
    }

}

export default TranscribedAudioButton