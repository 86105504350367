import { useState } from "react"
import { useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { Link, useParams, useNavigate, useLocation} from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from "../../components/shared/Loaders/Spinner";
import { getSingleVideo, getCounterFromVideo, reset } from "../../features/video_team/videosUpload/videosSlice";
import { getChaptersByVideoId } from "../../features/video_team/chapters/chaptersSlice";
import '../videoTeam/SingleVideo.css';
import SingleChapterMarketing from "../../components/marketing/shared/SingleChapterMarketing";
import GenerateResumesButton from "../../components/marketing/shared/GenerateResumesButton";
import GenerateLandingContentButton from "../../components/marketing/shared/GenerateLandingContentButton";
import SendChaptersToWordpress from "../../components/marketing/shared/SendChaptersToWordpress"; 
import {getLandingContent, setWordpressProductId} from '../../features/marketing_team/resumesSlice'






function SingleMasterclass() {
  const { id } = useParams();
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();


  const { isLoading, isSuccess, isError, message } = useSelector((state) => state.videos);
  const { video_chapters} = useSelector((state) => state.chapters);
  
  const [chapterCount, setChapterCount] = useState(location.state?.chapterCount || null);
  const [resumesCount, setResumesCount] = useState(location.state?.resumesCount || null);

  const [video, setVideo] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [resumes_available, setResumesAvailable] = useState(false);
  const [displayInput, setDisplayInput] = useState(false);
  const [productId, setProductId] = useState("");



  // check if count of resums = count of chapters,if so you can generate landing, otherwise nto avilable

  useEffect(() => {

    if(chapterCount && resumesCount){
      if(chapterCount === resumesCount){
        setResumesAvailable(true);
      }
    }

  },[chapterCount, resumesCount])

  

  // get all data from this on masterclass
  useEffect(() => {
    if(id){
        dispatch(getSingleVideo(id))
        .unwrap()
        .then((video_restult) => {
          setVideo(video_restult);
        })
        .catch((error) => {
          toast.error(error)
          navigate('/marketing/home')
          // dispatch(reset());
      });
    }
  }, [dispatch, id, navigate])


  // get all data from this on masterclass
  useEffect(() => {
    if(id){
      dispatch(getChaptersByVideoId(id))
      .unwrap()
      .then((chapter_restult) => {
        
      })
      .catch((error) => {
        toast.error(error)
        navigate('/marketing/home')
        // dispatch(reset());
    });
    }
  }, [dispatch, id, navigate])

  useEffect(() => {
    if(id){
      dispatch(getCounterFromVideo(id))
      .unwrap()
      .then((videos) => {
        console.log(videos);
        setChapterCount(videos.chapter_count);
        setResumesCount(videos.resumes_count);
      })
      .catch((error) => {
        toast.error(error)
      });
    }
  }, [id])

  useEffect(() => {
    const arrayChapters = [];
    if(video_chapters){
      for(let i = 0; i < video_chapters.length; i++){
        const object = {
          id: video_chapters[i].id,
          filename: video_chapters[i].filename,
        }

        arrayChapters.push(object);
      }
      setFileData(arrayChapters);
    }
  }, [video_chapters])



  // if theres an error display message from the error
  useEffect(() =>{
    if(isError){
        toast.error(message)
    }
    dispatch(reset());

}, [isError, isSuccess, dispatch, message])



useEffect(() => {
  // Store the current location in localStorage
  localStorage.setItem('lastVisitedPage', location.pathname);
}, [location]);



// if there are chapters that are not resumed count them

// useEffect(() => {
//   if(video_chapters){
//     const countChaptersTranscribed = video_chapters.filter((chapt) => chapt.transcribed === 1);
//     const countChaptersNotTranscribed = video_chapters.filter((chapt) => chapt.transcribed === 0);
//     setNotTranscribed(countChaptersNotTranscribed.length)
//     setTranscribed(countChaptersTranscribed.length)
//     let to_transcribe = [];
//     for(let i = 0; i < countChaptersNotTranscribed.length; i++){
//       const data = {}
//       data.id = countChaptersNotTranscribed[i].id
//       data.audio_name = countChaptersNotTranscribed[i].audio_name
//       to_transcribe.push(data);
//     }

//     setTranscribeArray(to_transcribe)
//   }
// }, [video_chapters])


const onChange = (e) => {
  setProductId(e.target.value)
}

const setNewId = () => {
  const data = {
      id_video: video.id,
      id_product: productId,
  }
  dispatch(setWordpressProductId(data))
      .unwrap()
      .then(() => {
          toast.success('Id set correctly')
          setDisplayInput(false)
          dispatch(getLandingContent(id ? id : video.id));
      })
      .catch((error) => {
          toast.error(error)
      });
}





  if(isLoading || !id ) {
    return (
      <Spinner/>
    )
  }
  

  if(location.state && location.state.error){
    return (
      <div className="error">{location.state.error}</div>
    )
  }

  
  
  if (video) {
        return (
        <div className="single-video-container">
            <div className="single-video-title">
            <h1 className="masterclass-title">{video.masterclass}</h1>
            </div>
            <div className="single-video-header">
            <div className="single-video-header-left">
                <p className="username">Author: {video.username}</p>
                {video_chapters && (
                <>
                    <p className="chapter-count">Chapters: {video_chapters.length}</p>
                    <p className="chapter-count">Resumed: {resumesCount}</p>
                    <p className="chapter-count">Not resumed: </p>
                </>
                )}
            </div>
            <div className="single-video-header-right"> 
            <button onClick={() => setDisplayInput(!displayInput)} className="btn btn-reverse" style={{width: '100%'}}>ADD ID</button>
              {displayInput && (
                  <>
                      <input onChange={onChange} type="text" className="input-form-center" style={{maxWidth: '100%', marginTop: '20px'}} placeholder='add product id'></input> 
                      <button onClick={setNewId} type="submit" className="btn btn-reverse btn-title" style={{width: '100%'}}>SAVE</button>
                  </>
              )}
              <GenerateResumesButton video_chapters={video_chapters}/>
              <GenerateLandingContentButton resumes_available={resumes_available}/>
              <SendChaptersToWordpress video_chapters={video_chapters}/>

            </div>
            </div>
            <SingleChapterMarketing/>
        </div>
        );
    }
} 

export default SingleMasterclass