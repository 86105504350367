import axios from 'axios'
import { HOST_URL } from '../../../config';

const API_URL = `${HOST_URL}transcriptions/`

// Upload MANY AUDIO files
const uploadManyAudioFiles = async (data, token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        timeout: 2700000
      }

      const formData = new FormData();
      formData.append('id_video', data.id_video);
      formData.append('language_code', data.language_code);
      formData.append('prompt', data.prompt);
      formData.append('transcriber', data.transcriber);
      formData.append('output', data.output);
      data.chaptersArray.forEach((chapter) => {
        
        formData.append('audio_file', chapter.audio_file);
      });


    const response = await axios.post(API_URL + 'cloud', formData, config);
    return response.data;
};


const uploadDacastVideos = async (data, token) => {
  const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: 2700000
    }
    

    const formData = new FormData();
    formData.append('is_dacast', true);
    formData.append('id_video', data.id_video);
    formData.append('language_code', data.language_code);
    formData.append('prompt', data.prompt);
    formData.append('transcriber', data.transcriber);
    formData.append('output', data.output);
    formData.append('regenrated', data.regenrated?1:0);
    formData.append('chapter_id', data.chapter_id?data.chapter_id:0);
    formData.append('video_dacast_id', data.video_dacast_id.id);
    formData.append('video_dacast_title', data.video_dacast_id.title);
    data.chaptersArray.forEach((chapter) => {
      formData.append('dacast_id', chapter.dacast_id); 
    });
    formData.append('single_chapter', data.single_chapter?1:0);

  const response = await axios.post(API_URL + 'dacastcloud', 
  formData, 
  config);
  return response.data;
};

const getChaptersFromPlaylistDacast = async (data, token) => {
  const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: 2700000
    }

    const formData = new FormData();
    formData.append('is_dacast', true);
    formData.append('id_video', data.id_video);
    formData.append('language_code', data.language_code);
    formData.append('prompt', data.prompt);
    formData.append('transcriber', data.transcriber);
    formData.append('output', data.output);
    data.chaptersArray.forEach((chapter) => {
      formData.append('dacast_id', chapter.dacast_id);
    });


  const response = await axios.post(API_URL + 'chaptersfromplaylistdacast', 
  formData, 
  config);
  return response.data;
};

const transcribeAudios = async (data, token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        timeout: 2700000
      }


    const response = await axios.post(API_URL + 'transcribe', data, config);
    return response.data;
};




const audioService = {
    uploadManyAudioFiles,
    transcribeAudios,
    uploadDacastVideos,
    getChaptersFromPlaylistDacast,
};

export default audioService;