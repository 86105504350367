import axios from 'axios'
import { HOST_URL } from '../../../config';

const API_URL = `${HOST_URL}video/extras`



// Get ticket notes
const getVariables = async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.get(API_URL + '/picker', config)
  
    return response.data
  }


const extraService = {
    getVariables,
  }
  
  export default extraService