import axios from 'axios'
import { HOST_URL } from '../../../config';


const API_URL = `${HOST_URL}auth`




// access google drive
const accessDrive = async (userId, token) => {
  const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await axios.get(API_URL + "/" + userId);
    return response.data;
  };



const driveService = {
  accessDrive,
};

export default driveService;