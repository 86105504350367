import SelectLanguages from "../../components/video/shared/SelectLanguages"
import BlogVideoFileUploader from '../../components/video/files/BlogVideoFileUploader'
import { useState } from "react"
import { useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { createVideoBlog } from "../../features/video_team/videosUpload/videosSlice";
import { toast } from "react-toastify";
import Downloader from "../../components/shared/Loaders/Downloader";

function CreateBlogs() {

  const dispatch = useDispatch();
  const markdown = false;

  const [title, setTitle] = useState('');
  const [language, setLanguage] = useState('');
  const [language_code, setLanguageCode] = useState('');
  const [native_language, setNative_language] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [videoId, setVideoId] = useState(false);
  const [srtFile, setSrtFile] = useState(false);
  const [videoFile, setVideoFile] = useState(false);
  const [dacastFile, setDacastFile] = useState(false);
  const [transcriber, setTranscriber] = useState('whisper');
  const [promptValue, setPrompt] = useState(
`I Want You To Act As A Content Writer Very Proficient SEO Writer Writes Fluently English (US). 
${markdown?'':
'Use HTML language, write content for paste in Wordpress Article.'}
${markdown?'First Create Two Tables. First Table Should be the Outline of the Article and the Second Should be the Article.':''}
${markdown?'Bold the Heading of the Second Table using Markdown language.':''}
${markdown?'':
'Add a table of content at the first of page, add links in tables with anchors to Article sections in table'}
Write an outline of the article separately before writing it, at least 15 headings and subheadings (including H1, H2, H3, and H4 headings) 
Then, start writing based on that outline step by step. 
Write a 2000-word 100% Unique, SEO-optimized, Human-Written article in English (US) with at least 15 headings and subheadings (including H1, H2, H3, and H4 headings) that covers the topic provided in the Prompt. 
Write The article In Your Own Words Rather Than Copying And Pasting From Other Sources. 
Consider perplexity and burstiness when creating content, ensuring high levels of both without losing specificity or context. Use fully detailed paragraphs that engage the reader. 
Write In A Conversational Style As Written By A Human (Use An Informal Tone, Utilize Personal Pronouns, Keep It Simple, Engage The Reader, Use The Active Voice, Keep It Brief, Use Rhetorical Questions, and Incorporate Analogies And Metaphors).  
End with a conclusion paragraph and 5 unique FAQs After The Conclusion. 
this is important to Bold the Title and all headings of the article, and use appropriate headings for H tags. 
Now Write An ${markdown?'Article':'HTML'} On This based on this following text:`);
  const [radioButtonValue, setRadioButtonValue] = useState('srt');

  const { user } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.transcriptions);
  const [dacastID, setDacastID] = useState('');


  const onChange = (e) => {
      setTitle(e.target.value)
  }
  const onChange2 = (e) => {
      setNative_language(e.target.value)
  }

  // callback options to retrieve data from child components (url and language id)

  const handleLanguage = (option, option2) => {
    setLanguage(option);
    setLanguageCode(option2)
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      setVideoFile(true); 
      setSubmitting(true);
  };


  useEffect(() => {
 
      if (submitting) {
          const videoData = {
              title,
              native_language,
              id_language: language,
              user_id: user._id,
          };

       
          dispatch(createVideoBlog(videoData))
              .unwrap()
              .then((video) => {
                  setVideoId(video.data.id)
                  setSubmitting(false)
              })
              .catch((error) => {
                  toast.error(error)
              });
      }
  }, [submitting, title, language, user, native_language, dispatch]);


  const onChangePrompt = (event) => {
    setPrompt(event.target.value)
  }



  if (isLoading && !((dacastFile && dacastID))){
    return (
        <Downloader text='Transcribing video files...'/>
    )
  }


  if(videoId){
    return (
        <div className="upload-file-container">        
            {(videoFile) && (
                <>
                   
                    
                    <>
                        <div className="prompt-container">
                            <h2>Add a prompt for blog:</h2>
                            <textarea
                            type='text'
                            style={{width:'100%',height:'300px'}}
                            className='input-form textarea'
                            id='text'
                            name='text'
                            value={promptValue}
                            onChange={onChangePrompt}
                            placeholder='Write a prompt'
                            required
                            />
                        </div>
                                               
                    </>
            
                  
                    {(videoFile) && (
                        <BlogVideoFileUploader output={radioButtonValue} prompt={promptValue} transcriber={transcriber} videoId={videoId} language_code={language_code} />
                    )}
                </>
            )}

           
            
        </div>
    )
  }

  return (
    <div>
        <h1>Create new blog</h1>
        <section className='form'>
            <form onSubmit={handleSubmit}>
                <div className='form-group'>
                    <input
                        type='text'
                        className='form-control'
                        id='title'
                        name='title'
                        value={title}
                        onChange={onChange}
                        placeholder='Enter video copy name'
                        required
                    />
                </div>
                <div className='form-group'>
                    <input
                        type='text'
                        className='form-control'
                        id='native_language'
                        name='native_language'
                        value={native_language}
                        onChange={onChange2}
                        placeholder='Enter language'
                        required
                    />
                </div>
                <div className='form-group'>
                    <SelectLanguages onHandleLanguage={handleLanguage} submitting={submitting} us={true} selectText={'Choose file original language'} />
                </div>
                <div className='form-group'>
                    <button className='btn btn-block'>TRANSCRIPT</button>
                </div>
            </form>
        </section>
  </div>
  );
}

export default CreateBlogs;