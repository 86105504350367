import { Link} from 'react-router-dom'




function GenerateChapters() {
 

    return (
      <div className="">
        Generate Chapters
      </div>
    )
}

export default GenerateChapters