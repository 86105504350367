import MarketingTable from '../../components/marketing/shared/MarketingTable'


function MarketingHome() {
 

    return (
      <MarketingTable/>
    )
}

export default MarketingHome