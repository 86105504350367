import { Link } from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useState, useEffect} from 'react';
import './NotFound.css';


function NotFound() {
  const {user} = useSelector ((state) => state.auth);
  const [redirect, setRedirect] = useState('');

  useEffect(() => {
    if(!user){
      setRedirect('/')
    } else if(user.role === 'video'){
      setRedirect('videos/home')
    } else if(user.role === 'marketing'){
      setRedirect('marketing/home')
    }
  }, [user])
  return (
    <div className="not-found-container">
      <div className="not-found-text">
        <h1>You got lost?</h1>
        <p>Follow this path to get back home...</p>
      </div>
      <div className="btn btn-reverse">
        <Link to={redirect} className="not-found-button-link">Go Home</Link>
      </div>
    </div>
  );
}

export default NotFound;
