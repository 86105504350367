import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import fileService from './fileService';

const initialState = {
  fileUpload: {},
  srtFile: {},
  srtOriginalFile: {},
  filename: '',
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};


// fileslogic
export const uploadFile = createAsyncThunk('videos/uploadFile', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await fileService.uploadFile(data, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


export const getSignedUrl = createAsyncThunk('videos/getSignedUrl', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await fileService.getSignedUrl(data, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});



export const createSingleChapter = createAsyncThunk('videos/createSingleChapter', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await fileService.createSingleChapter(data, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const uploadSrtFileToChapter = createAsyncThunk('videos/uploadSrtFileToChapter', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await fileService.uploadSrtFileToChapter(data, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const displayFile = createAsyncThunk('videos/displayFile', async (fileName, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await fileService.displayFile(fileName, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const displayFileUrl = createAsyncThunk('videos/displayFileUrl', async (fileName, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await fileService.displayFileUrl(fileName, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});



export const displayOriginalFile = createAsyncThunk('videos/displayOriginalFile', async (originalFileName, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await fileService.displayOriginalFile(originalFileName, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateFile = createAsyncThunk('videos/updateFile', async (args, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await fileService.updateFile(args.file, args.filename, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteFile = createAsyncThunk('videos/deleteFile', async (id, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await fileService.deleteFile(id, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const getFilenameById = createAsyncThunk('videos/getFilenameById', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await fileService.getFilenameById(data, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});




export const fileSlice = createSlice({
  name: 'fileUpload',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.message = '';
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.fileUpload = action.payload;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createSingleChapter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createSingleChapter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.fileUpload = action.payload;
      })
      .addCase(createSingleChapter.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(uploadSrtFileToChapter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadSrtFileToChapter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.fileUpload = action.payload;
      })
      .addCase(uploadSrtFileToChapter.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(displayFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(displayFile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.srtFile = action.payload;
      })
      .addCase(displayFile.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload;
      })
      .addCase(displayOriginalFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(displayOriginalFile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.srtOriginalFile = action.payload;
      })
      .addCase(displayOriginalFile.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload;
      })
      .addCase(updateFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateFile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.srtFile = action.payload;
      })
      .addCase(updateFile.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload;
    })
      .addCase(deleteFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.srtFile = null;
      })
      .addCase(deleteFile.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload;
    })

    

    .addCase(displayFileUrl.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(displayFileUrl.fulfilled, (state, action) => {
    state.isLoading = false;
    state.isSuccess = true;
    })
    .addCase(displayFileUrl.rejected, (state, action) => {
    state.isLoading = false;
    state.isSuccess = false;
    state.isError = true;
    state.message = action.payload;
    })

      .addCase(getFilenameById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFilenameById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.filename = action.payload;
      })
      .addCase(getFilenameById.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload;
    })
  },
});

export const {reset} = fileSlice.actions
export default fileSlice.reducer;
