
import {RiTranslate} from 'react-icons/ri'
import './Downloader.css'

function Downloader({text}) {
    return (
        <div className="downloader-container">
            <RiTranslate size={114} className='downloader-icon'/>
            <p style={{textAlign: 'center'}} className='downloader-text'>{(text ? text : 'Generating')}</p>
        </div>
    )
}

export default Downloader