import axios from 'axios'
import { HOST_URL } from '../../../config';


const API_URL = `${HOST_URL}video/file`




// Upload SRT file
const uploadFile = async (data, token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }

      const formData = new FormData();
      formData.append('id_video', data.id_video);
      data.chaptersArray.forEach((chapter) => {
        
        formData.append('srtFile', chapter.srtFile);
      });


    const response = await axios.post(API_URL + '/upload', formData, config);
    return response.data;
};



const getSignedUrl = async (data, token) => {
  const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params:data
    }

 

  const response = await axios.get(API_URL + '/gcs/signedUrl', config);
  return response.data;
};


const createSingleChapter = async (data, token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }

      const formData = new FormData();
      formData.append('id_video', data.id_video);
      formData.append('srtFile', data.srtFile);


    const response = await axios.post(API_URL + '/upload/single/' + data.id_video, formData, config);
    return response.data;
};

const uploadSrtFileToChapter = async (data, token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const formData = new FormData();
      formData.append('id_video', data.id_video);
      formData.append('srtFile', data.srtFile);
      


    const response = await axios.post(API_URL + '/upload/' + data.id_chapter, formData, config);
    return response.data;
};

const displayFile = async (fileName, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  // console.log(fileName)
  const response = await axios.get(API_URL + "/" + fileName, config);
    return response.data;

};

const displayFileUrl = async (fileName, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params:{
      url:fileName
    }
  }
  
  const response = await axios.get(API_URL + "/filepath/", config);
    return response.data;

};

// this function will only be called when two files are need it
const displayOriginalFile = async (originalFileName, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.get(API_URL + "/original/" + originalFileName, config);
    return response.data;

};

const updateFile = async (file, filename, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const formData = new FormData();
  formData.append('srtFile', file);
  const response = await axios.put(API_URL + "/" + filename, formData, config);
  return response.data;
};



const deleteFile = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  
  const response = await axios.put(API_URL + "/delete/file", id, config);
  console.log('this')
  return response.data;
};


const getFilenameById = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  
  const response = await axios.get(API_URL + "/filename/" + data.id_chapter +'/' + data.id_language, config);
  console.log(response.data)
  return response.data;
};




const fileService = {
  uploadFile,
  uploadSrtFileToChapter,
  displayFile,
  updateFile,
  displayOriginalFile,
  deleteFile,
  createSingleChapter,
  getFilenameById,
  displayFileUrl,
  getSignedUrl
};

export default fileService;