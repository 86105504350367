import VideosTable from '../../components/video/shared/VideosTable'



function VideoHome() {
 

    return (
      <VideosTable/>
    )
}

export default VideoHome