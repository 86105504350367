import { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate, useLocation} from 'react-router-dom';
import { useSelector } from 'react-redux';

function AudioPlayer({ handleTimeUpdate, link}) {
  const [currentTimeSavedAudio, setCurrentTimeSavedAudio] = useState(0);
  const { audioPlayerTime} = useSelector((state) => state.transcriptions);
  const audioRef = useRef();


  // pass currentime of audio to parent via function

function handleTimeUpdate2() {
    const currentTime = audioRef.current.currentTime;
    setCurrentTimeSavedAudio(currentTime)
    const formattedTime = new Date(currentTime * 1000).toISOString().substr(11, 12);
    handleTimeUpdate(formattedTime.split('.')[0], currentTime);
} 


// everytime time changes update time on handleTimeupdate function

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('timeupdate', handleTimeUpdate2);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', handleTimeUpdate2);
      }
    };
  }, [audioRef]);


  useEffect(() => {
    if (audioRef.current && audioPlayerTime) {
      audioRef.current.currentTime = audioPlayerTime;
    }
  }, []);


  return (
    <div className="audio-player-container">
      {link && (
        <audio ref={audioRef} controls>
          <source src={link} type="audio/mpeg"></source>
        </audio>
      )}
    </div>
  );
}

export default AudioPlayer