import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import resumesService from './resumesService'



const initialState = {
    resume: [],
    fileName: '',
    new_resume: '',
    landing_content: [],
    dacast_id: null,
    resumes_count: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}


// get all data

export const getResumeByChapterId = createAsyncThunk('linktree/getResumeByChapterId', async (id, thunkAPI) => {
  try{
      const token = thunkAPI.getState().auth.user.token;
      return await resumesService.getResumeByChapterId(id, token)
  } catch(error){
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message)
  }
})

// create new resume

export const createResume = createAsyncThunk('resumes/createResume', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await resumesService.createResume(data, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// generate new lresume

export const generateNewResume = createAsyncThunk('resumes/generateNewResume', async (content, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await resumesService.generateNewResume(content, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// generate new resume

export const getLandingContent = createAsyncThunk('resumes/getLandingContent', async (id, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await resumesService.getLandingContent(id, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// set ptoduct id

export const setWordpressProductId = createAsyncThunk('resumes/setWordpressProductId', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await resumesService.setWordpressProductId(data, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


// resume all original chapters

export const resumeAllOriginalChapters = createAsyncThunk('resumes/resumeAllOriginalChapters', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await resumesService.resumeAllOriginalChapters(data, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// create new link

// export const updateLinkTree = createAsyncThunk('linktree/updateLinkTree', async (linkData, thunkAPI) => {
//   try {
//     const token = thunkAPI.getState().auth.user.token;
//     return await linkTreeService.updateLinkTree(linkData, token);
//   } catch (error) {
//     const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//     return thunkAPI.rejectWithValue(message);
//   }
// });


// delete a linkdata

export const deleteResume = createAsyncThunk('linktree/deleteResume', async (id, thunkAPI) => {
  try{
      const token = thunkAPI.getState().auth.user.token;
      return await resumesService.deleteResume(id, token)
  } catch(error){
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message)
  }
})


// resume slice

export const updateResume = createAsyncThunk('videos/updateResume', async (args, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await resumesService.updateResume(args.file, args.filename, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// update Resume With NewC ontent
export const updateResumeWithNewContent = createAsyncThunk('videos/updateResumeWithNewContent', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await resumesService.updateResumeWithNewContent(data, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


// update Resume With NewC ontent
export const getResumesCount = createAsyncThunk('videos/getResumesCount', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await resumesService.getResumesCount(data, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});



export const generateLandingContent = createAsyncThunk('resumes/generateLandingContent', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await resumesService.generateLandingContent(data, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


export const updateLandingContent = createAsyncThunk('resumes/updateLandingContent', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await resumesService.updateLandingContent(data, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const sendDataToWordpress = createAsyncThunk('resumes/sendDataToWordpress', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await resumesService.sendDataToWordpress(data, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


export const sendChaptersToWordpress = createAsyncThunk('resumes/sendChaptersToWordpress', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await resumesService.sendChaptersToWordpress(data, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const resumesSlice = createSlice({
    name: 'resume',
    initialState,
    reducers: {
      reset: (state) =>{
        state.isLoading = false
        state.isError = false
        state.message = ''
        state.isSuccess = false
        state.new_resume = ''
      },
    },
    extraReducers: (builder) => {
      builder 
        .addCase(createResume.pending, (state) => {
          state.isLoading = false
        })
        .addCase(createResume.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.resume = action.payload
        })
        .addCase(createResume.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(getResumeByChapterId.pending, (state) => {
          state.isLoading = false
        })
        .addCase(getResumeByChapterId.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.fileName = action.payload[0].Filename
        })
        .addCase(getResumeByChapterId.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(deleteResume.pending, (state) => {
          state.isLoading = false
        })
        .addCase(deleteResume.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.resume = null
        })
        .addCase(deleteResume.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(updateResume.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(updateResume.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.resume = action.payload;
        })
        .addCase(updateResume.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })
        .addCase(updateResumeWithNewContent.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(updateResumeWithNewContent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.resume = action.payload;
        })
        .addCase(updateResumeWithNewContent.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })
        .addCase(generateNewResume.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(generateNewResume.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.new_resume = action.payload.content;
        })
        .addCase(generateNewResume.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        })
        .addCase(resumeAllOriginalChapters.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(resumeAllOriginalChapters.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        })
        .addCase(resumeAllOriginalChapters.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        })
        .addCase(getLandingContent.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getLandingContent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.landing_content = action.payload
        })
        .addCase(getLandingContent.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        })
        .addCase(setWordpressProductId.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(setWordpressProductId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        })
        .addCase(setWordpressProductId.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        })
        .addCase(updateLandingContent.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(updateLandingContent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        })
        .addCase(updateLandingContent.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        })
        .addCase(sendDataToWordpress.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(sendDataToWordpress.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.dacast_id = action.payload
        })
        .addCase(sendDataToWordpress.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        })
        .addCase(sendChaptersToWordpress.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(sendChaptersToWordpress.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.dacast_id = action.payload
        })
        .addCase(sendChaptersToWordpress.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        })
    },
  })
  

  export const {reset} = resumesSlice.actions
  export default resumesSlice.reducer