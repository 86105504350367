import { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'
import { toast } from 'react-toastify';
import { useParams, Link } from 'react-router-dom';
import {getUserVideos, getVideos, reset} from '../../../features/video_team/videosUpload/videosSlice';
import Spinner from '../../shared/Loaders/Spinner';
import DataTable from 'react-data-table-component'





function UserVideos({trailer}) {

    const dispatch = useDispatch();

    const { id } = useParams();

    const [videoData, setVideoData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredVideoData, setFilteredVideoData] = useState([]);
    

    const { isLoading } = useSelector((state) => state.videos);

 

    useEffect(() => {
      if(id){
        dispatch(getUserVideos(id))
        .unwrap()
        .then((videos) => {
          setVideoData(videos)
          setFilteredVideoData(videos)
          dispatch(reset());
        })
        .catch((error) => {
          toast.error(error)
        })
        .then(() => {
          dispatch(reset());
        });
      } else {
        dispatch(getVideos())
        .unwrap()
        .then((videos) => {
            setVideoData(videos)
            setFilteredVideoData(videos)
            dispatch(reset());
        })
        .catch((error) => {
            toast.error(error)
        })
        .then(() => {
            dispatch(reset());
        });
      }
    }, [dispatch, id]);


    // define datatables columns

    const columns = [
        {
            name: 'MASTERCLASS',
            selector: row => row.masterclass,
        },
        {
            name: 'ORIGINAL LANGUAGE',
            selector: row => row.video_language,
        },
        {
            name: 'NATIVE LANGUAGE',
            selector: row => row.native_language,
        },
        {
            name: 'CREATED BY',
            selector: row => row.username,
        },
        {
            name: 'CHAPTERS',
            selector: row => row.chapter_count,
        },
        {
            name: 'TRANSLATIONS',
            selector: row => {
          if (row.language_codes) {
            const translations = row.language_codes.split(',');
                return (
                <>
                    {translations.map((translation, index) => (                        
                        <img
                            className='image-table'
                            style={{marginRight: '4px'}}
                            key={index}
                            src={`https://flagcdn.com/${translation.split('-')[0] === 'en' ? 'gb' : translation.split('-')[0]}.svg`}
                            height="12"
                            alt={translation.translation_language_title}
                        />              
                    ))}
                </>
                );
            } else {
                return "-";
            }
            }
        },
        
        {
          name: 'VIEW',
          cell: (row) => (
            <>
              {row.language_codes ? (
                <Link
                  state={{ translation_codes: row.language_codes.split(',') }}
                  className="btn btn-reverse"
                  to={`/videos/video/${row.id}`}
                >
                  Open
                </Link>
              ) : (
                <Link
                  state={{ translation_codes: [] }}
                  className="btn btn-reverse"
                  to={`/videos/video/${row.id}`}
                >
                  Open
                </Link>
              )}
            </>
          ),
        },
        
    ];



    // search for videos

  const handleChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filteredData = videoData.filter((video) => {
      return video.masterclass.toLowerCase().includes(searchTerm) || video.video_language.toLowerCase().includes(searchTerm) || video.username.toLowerCase().includes(searchTerm);
    });
    setFilteredVideoData(filteredData);
    setSearchTerm(searchTerm);
};



    if(isLoading){
        return (
            <>
                <Spinner/>
            </>
        )
    }

    return (
     
      <div>
        {!trailer && <Link to="/videos/create" style={{margin:'auto', width:'fit-content'}} className="btn btn-reverse btn-title">Create new masterclass</Link> }
      <input type="text" className="search-box" value={searchTerm} placeholder="Search" onChange={handleChange} />
      <DataTable
            columns={columns}
            data={filteredVideoData}
            searchable
            pagination
        />
          <div>
      </div>
    </div>
       
    )
}

export default UserVideos