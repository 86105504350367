import {useLocation} from 'react-router-dom';

function AccessDenied() {

    const location = useLocation();
    const state = location.state; // access the state passed in the Navigate component
    const data = state.data;


    return (
        <h1>Access Denied, this belongs to the {data} </h1>
    )
}

export default AccessDenied