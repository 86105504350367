import { useState } from "react"
import { useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { useNavigate, useLocation} from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from "../../components/shared/Loaders/Spinner";
import SelectLanguages from "../../components/video/shared/SelectLanguages";
import {createTranslation, reset, createMultipleTranslation} from "../../features/video_team/translations/translationsSlice";
import Downloader from "../../components/shared/Loaders/Downloader";
import { getSingleVideo} from "../../features/video_team/videosUpload/videosSlice";
import useOpenSrt from "../../hooks/useOpenSrt";






function CreateTranslationV2() {

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // custom hook to open srt files
    const { openSrt } = useOpenSrt();

    const {translation_type, fileData, id_chapter, filename, masterclass, original_language_code, language_codes, audio } = location.state;


    

    const [languageId, setLanguageId] = useState('');
    const [translation_language_code, setTranslationLanguageCode] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const [originalFileName, setOriginalFileName] = useState('');
    const [codesArray, setCodesArray] = useState('');

    const { isLoading, isError } = useSelector((state) => state.translations);
    const { video, chapters} = useSelector((state) => state.videos);
    

    // if any of the data transferred to this component is not present, go back to the previous component and display an error message
    useEffect(() => {
        if(translation_type === 'single' && (!id_chapter || !filename || !masterclass || !original_language_code)){
            setLocationError(true)
        }
         else if (translation_type === 'many' && (!fileData || !masterclass || ! original_language_code))  {
            setLocationError(true)
        }
        

        setOriginalFileName(filename)
      }, [id_chapter, filename, masterclass, original_language_code]);


    //   create array of codes to pass it to translate
    useEffect(() => {
        let codes_array = [];
        codes_array.push(original_language_code);
        if (language_codes && language_codes.length > 0){ 
            language_codes.forEach((language) => {
                codes_array.push(language.code)
            })
        }
        setCodesArray(codes_array);
    }, [original_language_code, language_codes])

    // function to get values of languages selected for translation
    const handleLanguage = (option, option2) => {
        setLanguageId(option);
        setTranslationLanguageCode(option2);
    };

    // when form is ready, change state for form to be submitted

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);
    };


    

    // funciton to hanlde submit, and start translation
    useEffect(() => {
        // if submitting is true create a new translation
        if (submitting && translation_type === 'single') {
            const translationData = {
                source_language: original_language_code,
                target_language: translation_language_code,
                filename: filename,
                id_chapter: id_chapter,
                id_language: languageId,
            };

            console.log('originalFileName',originalFileName);
  
            dispatch(createTranslation(translationData))
                .unwrap()
                .then((translation) => {
                    if(translation.success){
                        navigate('/videos/video/'+video.id)
                    }else{
                        console.log('translated: ',translation,originalFileName);
                        openSrt(translation,audio, originalFileName)
                    }
                   
                    dispatch(getSingleVideo(video.id))
                    
                })
                .catch((error) => {
                    toast.error(error)
                })
                .then(() => {
                    dispatch(reset());
                });
        } else if (submitting && translation_type === 'many') {
            const translationData = {
                source_language: original_language_code,
                target_language: translation_language_code,
                fileData: fileData,
                id_language: languageId,
            };
            console.log("translationData", translationData)
            dispatch(createMultipleTranslation(translationData))
                .unwrap()
                .then((translations) => {
                    toast.dismiss() 
                    dispatch(getSingleVideo(video.id))
                    navigate(`/videos/video/${video.id}`)
                    toast.success('Files succesfully translated');
                    
                })
                .catch((error) => {
                    toast.dismiss()
                    toast.error(error)
                })
                .then(() => {
                    dispatch(reset());
                });
        }
    }, [submitting, dispatch, filename, video.id, languageId, original_language_code, translation_language_code, navigate, originalFileName, translation_type, fileData]);

    if(locationError || isError){
        return(
            <h1>An error happened, please go back and try again</h1>
        )
    }

    if(isLoading && translation_type === 'single'){
        return(
            <Spinner/>
        )
    }

    if (isLoading && translation_type === 'many') {
        return (
            <>
            <Downloader/>
            </>
          )
      }

      return (
        <div className="translations-container">
        
            <h1>Translations for {masterclass}</h1>
            <form onSubmit={handleSubmit}>
            <div className='form-group'>
                <SelectLanguages onHandleLanguage={handleLanguage} submitting={submitting} selectText={'Choose Language'} language_codes={codesArray} translation_type={translation_type} />
            </div>
            <div className='form-group'>
                <button className='btn btn-block'>Translate</button>
            </div>
            </form>
        </div>
     


      )
    }
    
    export default CreateTranslationV2