import axios from 'axios'
import { HOST_URL } from '../../../config';


const API_URL = `${HOST_URL}translations`




// Upload SRT file
const createTranslation = async (data, token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    
  const response = await axios.post(API_URL + '/translate', data, config);
  return response.data;
};

const createMultipleTranslation = async (data, token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    
  const response = await axios.post(API_URL + '/translate/all', data, config);
  return response.data;
};


// DELETE ONE translation file
const deleteTranslation = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL +'/delete/' + id, config)

  return response.data
}
// DELETE ONE translation file
const getChapterTranslations = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + '/' + id, config)

  return response.data
}



const fileService = {
    createTranslation,
    deleteTranslation,
    getChapterTranslations,
    createMultipleTranslation,
};

export default fileService;