import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import chaptersService from './chaptersService'

const initialState = {
    video_chapters: [],
}


// get chapters by video id

export const getChaptersByVideoId = createAsyncThunk('videos/getChaptersByVideoId', async (id, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await chaptersService.getChaptersByVideoId(id, token)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})

export const chaptersSlice = createSlice ({
    name: 'chapters',
    initialState,
    reducers: {
        setChapters: (state, action) => {
            const sortedChapters = action.payload.sort((a, b) => {
                const chapterA = parseInt(a.chapter_title.split('.')[0]);
                const chapterB = parseInt(b.chapter_title.split('.')[0]);
                return chapterA - chapterB;
            });
            console.log("sortedChapters", sortedChapters)
            state.video_chapters = sortedChapters;
        },     
        reset: (state) =>{
          state.isLoading = false
          state.isError = false
          state.message = ''
          state.isSuccess = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getChaptersByVideoId.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getChaptersByVideoId.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.video_chapters = action.payload
                state.message = ''
            })
            .addCase(getChaptersByVideoId.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            });
            
    }
})

export const {setChapters} = chaptersSlice.actions

export default chaptersSlice.reducer