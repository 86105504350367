import axios from 'axios'
import { HOST_URL } from '../../config';


const API_URL = `${HOST_URL}marketing/linktree/`


// get all data
const getLinkTreeData = async () => {


  const response = await axios.get(API_URL)

  return response.data

}


// create link

const createLink = async (linkData, token) => {
  const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    
    const formData = new FormData();
    formData.append('section', linkData.section);
    formData.append('language', linkData.language);
    formData.append('text', linkData.text);
    formData.append('link', linkData.link);
    formData.append('utm', linkData.utm);
    formData.append('image', linkData.linkImage);
    formData.append('position', linkData.position);

  const response = await axios.post(API_URL, formData, config);
  return response.data;
};


// update link

const updateLinkTree = async (linkData, token) => {
  const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }


  const response = await axios.put(API_URL, linkData, config);
  return response.data;
};



// delete single  data
const deleteLinkTreeData = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + id, config)

  return response.data
}








const linkTreeService = {
    getLinkTreeData,
    deleteLinkTreeData,
    createLink,
    updateLinkTree
  }
  
  export default linkTreeService