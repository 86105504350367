import {useDispatch} from 'react-redux';
import { displayFile } from "../features/video_team/files/fileSlice";
import { useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';

// the objective of this hook is to open the selected srt file
// note that originalFileName parameter is optional and will only be used to display the original srtfile and its translation

const useOpenSrt = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openSrt = (filename, link, originalFileName = null) => {
    if (filename === null) {
      alert('No files yet');
    } else {
      console.log('after',filename,link);
      dispatch(displayFile(filename))
        .unwrap()
        .then(() => {
          console.log('dispatch',filename,link);
          navigate('/videos/file-editor', { state: { filename, link, originalFileName } });
        })
        .catch(toast.error);
    }
  };

  return { openSrt };
};

export default useOpenSrt;


