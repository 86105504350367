import { Link } from 'react-router-dom'
import { IoIosSkipForward} from 'react-icons/io'



function SkipButton({navigateTo}) {


return (
    <Link to={navigateTo} className='btn btn-reverse btn-back btn-skip' >
       SKIP <IoIosSkipForward style={{marginLeft: '15px'}}/>
    </Link>
    )
}

export default SkipButton