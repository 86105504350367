import SelectLanguages from "../../components/video/shared/SelectLanguages"
import FileUploader from '../../components/video/files/FileUploader'
import VideoFileUploader from '../../components/video/files/VideoFileUploader'
import DacastVideoUploader from '../../components/video/files/DacastVideoUploader'
import { useState } from "react"
import { useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { createVideoCopy } from "../../features/video_team/videosUpload/videosSlice";
import { toast } from "react-toastify";
import SkipButton from "../../components/shared/buttons/SkipButton";
import Downloader from "../../components/shared/Loaders/Downloader";

function CreateCopies() {

  const dispatch = useDispatch();

  const [title, setTitle] = useState('');
  const [language, setLanguage] = useState('');
  const [language_code, setLanguageCode] = useState('');
  const [native_language, setNative_language] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [videoId, setVideoId] = useState(false);
  const [srtFile, setSrtFile] = useState(false);
  const [videoFile, setVideoFile] = useState(false);
  const [dacastFile, setDacastFile] = useState(false);
  const [transcriber, setTranscriber] = useState('whisper');
  const [promptValue, setPrompt] = useState('');
  const [radioButtonValue, setRadioButtonValue] = useState('srt');

  const { user } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.transcriptions);
  const [dacastID, setDacastID] = useState('');


  const onChange = (e) => {
      setTitle(e.target.value)
  }
  const onChange2 = (e) => {
      setNative_language(e.target.value)
  }

  // callback options to retrieve data from child components (url and language id)

  const handleLanguage = (option, option2) => {
    setLanguage(option);
    setLanguageCode(option2)
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      setVideoFile(true); 
      setSubmitting(true);
  };


  useEffect(() => {
 
      if (submitting) {
          const videoData = {
              title,
              native_language,
              id_language: language,
              user_id: user._id,
          };

        console.log(videoData);
          dispatch(createVideoCopy(videoData))
              .unwrap()
              .then((video) => {
                  setVideoId(video.data.id)
                  setSubmitting(false)
              })
              .catch((error) => {
                  toast.error(error)
              });
      }
  }, [submitting, title, language, user, native_language, dispatch]);

  const handleChangeSelect = (event) => {
    setTranscriber(event.target.value)
  }
  const handleRadioButtonChange = (event) => {
    setRadioButtonValue(event.target.value);
  };
  const onChangePrompt = (event) => {
    setPrompt(event.target.value)
  }



  if (isLoading && !((dacastFile && dacastID))){
    return (
        <Downloader text='Transcribing video files...'/>
    )
  }


  if(videoId){
    return (
        <div className="upload-file-container">        
            {(videoFile) && (
                <>
                   
                    
                    <>
                        <div className="prompt-container">
                            <h2>Add a prompt:</h2>
                            <textarea
                            type='text'
                            style={{width:'100%'}}
                            className='input-form textarea'
                            id='text'
                            name='text'
                            value={promptValue}
                            onChange={onChangePrompt}
                            placeholder='Enter prompt ( replace the standard )'
                            required
                            />
                        </div>
                                               
                    </>
            
                  
                    {(videoFile) && (
                        <VideoFileUploader output={radioButtonValue} prompt={promptValue} transcriber={transcriber} videoId={videoId} language_code={language_code} />
                    )}
                </>
            )}

           
            
        </div>
    )
  }

  return (
    <div>
        <h1>Create new caption</h1>
        <section className='form'>
            <form onSubmit={handleSubmit}>
                <div className='form-group'>
                    <input
                        type='text'
                        className='form-control'
                        id='title'
                        name='title'
                        value={title}
                        onChange={onChange}
                        placeholder='Enter video copy name'
                        required
                    />
                </div>
                <div className='form-group'>
                    <input
                        type='text'
                        className='form-control'
                        id='native_language'
                        name='native_language'
                        value={native_language}
                        onChange={onChange2}
                        placeholder='Enter language'
                        required
                    />
                </div>
                <div className='form-group'>
                    <SelectLanguages onHandleLanguage={handleLanguage} submitting={submitting} us={true} selectText={'Choose file original language'} />
                </div>
                <div className='form-group'>
                    <button className='btn btn-block'>TRANSCRIPT</button>
                </div>
            </form>
        </section>
  </div>
  );
}

export default CreateCopies;