import {BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom'
import { useState, useEffect } from 'react';
import Home from './pages/Home';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import CreateVideos from './pages/videoTeam/CreateVideos';
import CreateCopies from './pages/videoTeam/CreateCopies';
import CreateBlogs from './pages/videoTeam/CreateBlogs';
import Users from './pages/users/Users';
import Header from './components/shared/NavBars/Header';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PrivateRoutes from './components/shared/AccessRoutes/PrivateRoutes'
import MarketingTeamRoutes from './components/shared/AccessRoutes/MarketingTeamRoutes'
import VideoTeamRoutes from './components/shared/AccessRoutes/VideoTeamRoutes'
import VideoHome from './pages/videoTeam/VideoHome';
import MarketingHome from './pages/marketingTeam/MarketingHome';

import AccessDenied from './components/shared/AccessRoutes/AccessDenied'
import BackButton from './components/shared/buttons/BackButton';
import SingleVideo from './pages/videoTeam/SingleVideo';
import SingleCopy from './pages/videoTeam/SingleCopy';
import SingleBlog from './pages/videoTeam/SingleBlog';

import DriveLogIn from './components/video/googleDrive/DriveLogIn';
import SettingsLanguages from './pages/settings/SettingsLanguages';
import SettingsUser from './pages/settings/SettingsUser';
import LateralNavBar from './components/shared/NavBars/LateralNavBar';
import CreateTranslationV2 from './pages/videoTeam/CreateTranslationV2';
import NotFound from './pages/NotFound';
import UserVideos from './pages/users/UserVideos'
import ForgotPassword from './pages/auth/ForgotPassword';
import FileEditorV2 from './components/video/files/FileEditorV2';

import LinkInBio from './pages/linkinbio/LinkInBio';

import LandingContent from './pages/marketingTeam/LandingContent';
import Linktree from './pages/marketingTeam/social-media/Linktree';
import CopiesHome from './pages/marketingTeam/social-media/CopiesHome';
import BlogsHome from './pages/marketingTeam/social-media/BlogsHome';

import GenerateChapters from './pages/marketingTeam/GenerateChapters';
import SingleMasterclass from './pages/marketingTeam/SingleMasterclass';
import ResumeEditor from './components/marketing/shared/ResumeEditor';

import TrailerHome from './pages/trailer/TrailerHome';
import CreateTrailer from './components/trailer/CreateTrailer';
import ScriptEditor from './components/trailer/ScriptEditor';

import { useSelector } from "react-redux";






function App() {
  const { user} = useSelector((state) => state.auth);

  const location = useLocation();

  const shouldRenderHeaderAndNavBar = !location.pathname.includes('/linkinbio');
  const shouldRenderBackButton = location.pathname !== '/';







  return (
   <>
        <div className={shouldRenderHeaderAndNavBar ? 'container' : ''}>
        {shouldRenderHeaderAndNavBar && (
          <>
            <Header />
            <LateralNavBar />
          </>
        )}
        {shouldRenderBackButton && (
          
            <BackButton/>
      
        )}
          <Routes>
            <Route path='/' element={<PrivateRoutes />}>
              {user && <Route path='/' element={user.role === 'video' ? <VideoHome/> : user.role === 'marketing' ? <MarketingHome/> : user.role === 'admin' ? <Home/> : <Login/>} />}
            </Route>
            <Route path='/videos/create' element={<VideoTeamRoutes />}>
              <Route path='/videos/create' element={<CreateVideos />} />
            </Route>
           
            <Route path='/videos/file-editor' element={<VideoTeamRoutes />}>
              <Route path='/videos/file-editor' element={<FileEditorV2 />} />
            </Route>
            <Route path='/videos/video/:id' element={<VideoTeamRoutes />}>
              <Route path='/videos/video/:id' element={<SingleVideo />} />
            </Route>
            <Route path='/videos/translate' element={<VideoTeamRoutes />}>
              <Route path='/videos/translate' element={<CreateTranslationV2 />} />
            </Route>
            <Route path='/users' element={<PrivateRoutes />}>
              <Route path='/users' element={<Users />} />
            </Route>
            <Route path='/trailers' element={<PrivateRoutes />}>
              <Route path='/trailers' element={<TrailerHome />} />
            </Route>
            <Route path='/trailers/create/:id' element={<PrivateRoutes />}>
              <Route path='/trailers/create/:id' element={<CreateTrailer />} />
            </Route>
            <Route path='/trailers/editor' element={<PrivateRoutes />}>
              <Route path='/trailers/editor' element={<ScriptEditor />} />
            </Route>
            <Route path='/videos/settings/languages' element={<VideoTeamRoutes />}>
              <Route path='/videos/settings/languages' element={<SettingsLanguages />} />
            </Route>
            <Route path='/settings/user' element={<PrivateRoutes />}>
              <Route path='/settings/user' element={<SettingsUser />} />
            </Route>
            <Route path='/videos/user/:id' element={<VideoTeamRoutes />}>
              <Route path='/videos/user/:id' element={<UserVideos />} />
            </Route>
            <Route path='/access-denied' element={<PrivateRoutes />}>
              <Route path='/access-denied' element={<AccessDenied />} />
            </Route>
            <Route path='/videos/home' element={<VideoTeamRoutes />}>
              <Route path='/videos/home' element={<VideoHome />} />
            </Route>
            {/* marketing */}
            <Route path='/marketing/home' element={<MarketingTeamRoutes />}>
              <Route path='/marketing/home' element={<MarketingHome />} />
            </Route>
            <Route path='/marketing/social-media/copy/:id' element={<MarketingTeamRoutes />}>
              <Route path='/marketing/social-media/copy/:id' element={<SingleCopy />} />
            </Route>
            <Route path='/marketing/social-media/copies/create' element={<MarketingTeamRoutes />}>
              <Route path='/marketing/social-media/copies/create' element={<CreateCopies />} />
            </Route>
            <Route path='/marketing/social-media/copies' element={<MarketingTeamRoutes />}>
              <Route path='/marketing/social-media/copies' element={<CopiesHome/>} />
            </Route>

            <Route path='/marketing/social-media/blog/:id' element={<MarketingTeamRoutes />}>
              <Route path='/marketing/social-media/blog/:id' element={<SingleBlog />} />
            </Route>
            <Route path='/marketing/social-media/blogs/create' element={<MarketingTeamRoutes />}>
              <Route path='/marketing/social-media/blogs/create' element={<CreateBlogs/>} />
            </Route>
            <Route path='/marketing/social-media/blogs' element={<MarketingTeamRoutes />}>
              <Route path='/marketing/social-media/blogs' element={<BlogsHome/>} />
            </Route>

            <Route path='/marketing/social-media/linktree' element={<MarketingTeamRoutes />}>
              <Route path='/marketing/social-media/linktree' element={<Linktree />} />
            </Route>
            <Route path='/marketing/masterclass/chapters' element={<MarketingTeamRoutes />}>
              <Route path='/marketing/masterclass/chapters' element={<GenerateChapters />} />
            </Route>
            <Route path='/marketing/masterclass/:id' element={<MarketingTeamRoutes />}>
              <Route path='/marketing/masterclass/:id' element={<SingleMasterclass />} />
            </Route>
            <Route path='/marketing/masterclass/landing/:id' element={<MarketingTeamRoutes />}>
              <Route path='/marketing/masterclass/landing/:id' element={<LandingContent />} />
            </Route>
            <Route path='/marketing/resume-editor' element={<MarketingTeamRoutes />}>
              <Route path='/marketing/resume-editor' element={<ResumeEditor />} />
            </Route>
            
            <Route path='/login' element={<Login />} />
            <Route path='/reset-password/:token' element={<ForgotPassword />} />
            <Route path="*" element={<NotFound />} />
            <Route path='/register' element={<PrivateRoutes />}>
              <Route path='/register' element={<Register />} />
            </Route>
           
          </Routes>
        </div>
      <ToastContainer />
   </>
  );
}

export default App;
