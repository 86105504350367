import { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'
import { toast } from 'react-toastify';
import { useParams, Link } from 'react-router-dom';
import {getUserVideos, getVideos, reset} from '../../features/video_team/videosUpload/videosSlice';
import {createScript} from '../../features/trailer/trailerSlice';
import Spinner from '../shared/Loaders/Spinner';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';






function TrailersTable() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const { id } = useParams();

    const [videoData, setVideoData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredVideoData, setFilteredVideoData] = useState([]);
    

    const { isLoading } = useSelector((state) => state.videos);

 

    useEffect(() => {
      if(id){
        dispatch(getUserVideos(id))
        .unwrap()
        .then((videos) => {
          setVideoData(videos)
          setFilteredVideoData(videos)
          dispatch(reset());
        })
        .catch((error) => {
          toast.error(error)
        })
        .then(() => {
          dispatch(reset());
        });
      } else {
        dispatch(getVideos())
        .unwrap()
        .then((videos) => {
            setVideoData(videos)
            setFilteredVideoData(videos)
            dispatch(reset());
        })
        .catch((error) => {
            toast.error(error)
        })
        .then(() => {
            dispatch(reset());
        });
      }
    }, [dispatch, id]);
    


    // define datatables columns

    const columns = [
        {
            name: 'MASTERCLASS',
            selector: row => row.masterclass,
        },
        {
            name: 'ORIGINAL LANGUAGE',
            selector: row => row.video_language,
        },
        {
            name: 'NATIVE LANGUAGE',
            selector: row => row.native_language,
        },
        {
            name: 'CREATED BY',
            selector: row => row.username,
        },
        {
            name: 'CHAPTERS',
            selector: row => row.chapter_count,
        },
        {
          name: 'VIEW',
          cell: (row) => {
            // Determine if the link should be clickable
            const isClickable = row.chapter_count !== 0;
            const hasScript = row.hasScript !== 0;
        
            // Handle click event
            const handleClick = (e) => {
              if (!isClickable && !hasScript) {
                e.preventDefault(); // Prevent the link action if not clickable
              }else if (isClickable && !hasScript) {
                const data= {
                  id: row.id,
                  masterclass: row.masterclass
                }
                toast.info('Generating Script')
                dispatch(createScript(data))
                  .unwrap()
                  .then(() => {
                    // Redirect to the desired page/component on success
                    toast.success('Script Created')
                    navigate('/trailers/editor', { state: { masterclassId: row.id, masterclass: row.masterclass } });
                  })
                  .catch((error) => {
                    // Handle error here if needed
                    toast.error(error.message || "Error creating script");
                  });
              } else if (hasScript){
                navigate('/trailers/editor', { state: { masterclassId: row.id, masterclass: row.masterclass } });
              }
            };
        
            return (
              <>
                <button
                style={{minWidth: '175px'}}
                  state={{ translation_codes: [] }}
                  className={`${isClickable ? 'btn btn-reverse' : 'btn btn-reverse blocked-link'}`}
                  to={isClickable ? `/trailers/create/${row.id}` : ''}
                  onClick={handleClick}
                >
                  {(!isClickable && !hasScript) ? 'No Resumes' : (isClickable && !hasScript) ? 'Create Trailer' : 'View Script'}
                </button>
              </>
            );
          },
        }        
        
    ];



    // search for videos

  const handleChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filteredData = videoData.filter((video) => {
      return video.masterclass.toLowerCase().includes(searchTerm) || video.video_language.toLowerCase().includes(searchTerm) || video.username.toLowerCase().includes(searchTerm);
    });
    setFilteredVideoData(filteredData);
    setSearchTerm(searchTerm);
};



    if(isLoading){
        return (
            <>
                <Spinner/>
            </>
        )
    }

    return (
     
      <div>
      <input type="text" className="search-box" value={searchTerm} placeholder="Search" onChange={handleChange} />
      <DataTable
            columns={columns}
            data={filteredVideoData}
            searchable
            pagination
        />
          <div>
      </div>
    </div>
       
    )
}

export default TrailersTable